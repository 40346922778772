.mini-archive {
    $this: &;

    display: flex;
    flex-flow: row wrap;
    background: $lighter;
    box-shadow: 0 toRem(2) toRem(4) rgba($darker, 0.1);
    border: toRem(1) solid $dark--4;
    font-size: 0.875rem;
    margin: 0 toRem(-1);
    &__title {
        flex: 1 1 100%;
        padding: 0.625rem 1rem;
        margin: 0;
        color: var(--journal-color);
    }
    &__item {
        flex: 1 1 50%;
        max-width: 50%;
        padding: 1rem;
        text-align: center;
        border-top: toRem(1) solid $border-color;
        @include media('>desktop') {
            #{$this} &:nth-of-type(odd) {
                border-right: toRem(1) solid $border-color;
            }
        }
        @include media('<phone') {
            #{$this} &:nth-of-type(odd) {
                border-right: toRem(1) solid $border-color;
            }
        }
        @include media('<desktop', '>phone') {
            flex: 1 1 33.3%;
            max-width: 33.3%;
            border-right: toRem(1) solid $border-color;
            &:nth-of-type(3n) {
                border-right: none;
            }
            &:first-of-type {
                border-right: toRem(1) solid $border-color;
            }
        }
    }
    &__cover {
        margin-bottom: 0.5rem;
    }
    &__description {
        font-weight: bold;
        line-height: 1.34;
        margin-bottom: 0.5rem;
    }
    &__date {
        color: $dark--5;
        font-size: 0.75rem;
    }
    &__actions {
        border-top: toRem(1) solid $border-color;
        padding: 1.5rem 1rem;
        display: flex;
        flex: 1;
        justify-content: center;
        .btn {
            flex: 1;
            max-width: 21rem;
        }
    }
}
