.favoriteShortlist {
    .section__separator {
        border-bottom: toRem(1) solid $dim;

        .tab__pane & > div {
            margin-bottom: 0.5rem;
        }

        .sortby__dropdown {
            .favoritePublicationsOptions {
                height: auto;
                border: none;
                border-left: toRem(1) solid $dim;
                min-width: 8.75rem;
                padding: 0.5rem 1.25rem;
            }
        }
    }
}
