.device-pairing {
    &__form {
        .btn {
            padding: 0.5rem 3.875rem;
        }
    }

    .profileMain {
        padding-bottom: 0;
    }
}
