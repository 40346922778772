.do {
    $this: &;

    &__body {
        margin-top: 2rem;

        @include media('<tablet') {
            margin-top: 0;
        }

        .content-navigation {
            padding-top: 1rem;
            border-top: toRem(1) solid $border-color;
            margin-bottom: 2rem;

            @include media('<tablet') {
                margin-bottom: 2.5rem;
            }
        }

        .share__inline-links {
            li + li {
                margin-left: 0.5rem;
            }
        }

        .search__header {
            display: flex;
            justify-content: space-between;
            border-bottom: toRem(1) solid $border-color;
            .search__sort {
                font-size: 0.75rem;
                &__label {
                    margin-right: 0.5rem;
                }
            }
            a {
                color: $secondary;
                font-size: 0.75rem;
            }
        }

        .issue-item {
            padding: 1rem 0;
            color: $text-color;

            h5 {
                margin-bottom: 0;
                color: $darker;
            }

            &__meta {
                font-weight: bolder;
                text-transform: uppercase;
                margin-bottom: 0.625rem;
            }

            &__footer {
                padding-top: 0.25rem;
            }

            &__authors {
                margin-bottom: 0;
            }
        }
    }

    &__title {
        margin: 1rem 0;
    }

    &__toolbar {
        color: $text--secondary;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 0.75rem;
        margin-bottom: 1rem;

        &__date {
            font-weight: bold;
            text-transform: uppercase;
        }

        &__share {
            font-size: 0.938rem;

            a {
                margin-bottom: 0;
                margin-right: 1rem;

                &:last-child {
                    margin: 0;
                }
            }

            .at-icon-wrapper {
                margin: 0;
            }

            .addthis_button_facebook {
                &:hover {
                    i {
                        background-color: $icon-facebook-color;
                        color: $white;
                    }
                }
            }
        }
    }

    &__header {
        background-color: $white;
        padding: 1rem;
        margin-bottom: 1rem;
        font-size: 0.75rem;

        &__title {
            margin-bottom: 0.5rem;
            color: inherit;
        }

        [class^='read'] {
            color: $text--secondary;
        }
    }

    &__content {
        font-size: 0.875rem;

        a {
            color: $do-link-color;
        }

        &__wrapper {
            display: flex;
            align-items: flex-start;

            @include media('<tablet') {
                flex-wrap: wrap;
            }
        }
        #{$this}__content {
            &__link {
                color: $primary;
                display: block;
                margin-top: 0.5rem;
                @include font-size(12);
                text-transform: uppercase;
                font-weight: bold;

                @include media('<tablet') {
                    margin-top: 1rem;
                }
            }
        }
    }

    &__featured__img {
        max-width: 16rem;
        width: 100%;
        margin-right: 1.875rem;

        @include media('<tablet') {
            max-width: 100%;
            margin: 0 0 2rem;
        }
    }
}
