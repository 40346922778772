.issue-item {
    $this: &;

    font-size: 0.75rem;
    padding: 2rem 0 1.125rem;
    border-bottom: toRem(1) solid $border-color;

    &--journal {
        display: grid;
        grid-template-areas: 'cover title' 'cover meta' 'cover body' 'cover links';

        grid-template-columns: 1fr 3.9fr;

        #{$this}__title {
            grid-area: title;
            display: block; // this s an <a> element. IE11 wont position inline elements properly (that are in fact grid items)
        }

        #{$this}__cover {
            grid-area: cover;
            display: block;
        }

        #{$this}__meta {
            grid-area: meta;
        }

        #{$this}__body {
            grid-area: body;
        }

        #{$this}__links {
            grid-area: links;
        }

        @include media('<tablet') {
            display: flex;
            flex-flow: row wrap;

            #{$this}__title {
                flex: 1 0 0;
            }

            #{$this}__links {
                flex: 1;
            }

            #{$this}__aux {
                flex: 1 1 100%;

                & + #{$this}__meta {
                    margin-top: 0;
                }
            }

            #{$this}__meta {
                margin-top: 1rem;
                flex: 1 1 100%;

                & + #{$this}__meta {
                    margin-top: 0;
                }
            }

            #{$this}__body {
                flex: 1 1 100%;
            }
        }

        .loa {
            flex: 1 0 100%;
        }
    }

    h2,
    h3 {
        font-size: 1.13rem;
        margin-top: 0;
        font-weight: 700;
        margin-bottom: 0.75rem;
        color: inherit;
    }

    h5 {
        margin-bottom: 0.75rem;
        color: inherit;
    }

    h4 {
        line-height: 1.2;
        color: inherit;
    }

    a.issue-item__title {
        &:hover,
        &:focus {
            color: $supplementary;
        }
    }

    &__title {
        // xslt inconsistent markup support of what is just above
        a {
            &:hover {
                color: $supplementary;
            }
        }
        h2 {
            margin-bottom: 0.75rem;
            color: inherit;
            font-weight: 700;
            font-size: 1.125rem;
        }
    }

    &__header {
        padding-bottom: 0.5rem;
        display: flex;
        color: $dark--5;
        text-transform: uppercase;
        align-items: center;
        flex-flow: row wrap;

        & > span + span {
            border-left: toRem(1) solid $border-color;
            padding-left: 1em;
            margin-left: 1em;
        }
    }

    &__cover {
        grid-row: 1 / 10;
        padding-right: 1.5rem;
        min-width: 7.75rem;

        @include media('<tablet') {
            max-width: 30%;
            align-self: flex-start;
            padding-right: 1rem;
            margin-bottom: 0.5rem;
        }

        img {
            display: block;
            width: 100%;
        }

        &:hover,
        &:focus {
            img {
                box-shadow: 0 0 0 toRem(3) rgba($darker, 0.15);
            }
        }
    }

    &--illustrated {
        #{$this}__body {
            display: flex;

            @include media('<phone') {
                flex-flow: column;
            }
        }

        &#{$this}--collection {
            // FE is unable to create required markup - adding grid here
            #{$this}__body {
                display: grid;

                grid-template-areas: 'header header' 'authors authors' 'meta meta' 'cover desc' 'footer footer';

                @include media('<desktop') {
                    grid-template-columns: 30% auto;
                }
            }

            #{$this}__title {
                grid-area: header;
            }

            #{$this}__authors {
                grid-area: authors;
            }

            #{$this}__meta {
                grid-area: meta;
            }

            #{$this}__abstract {
                grid-area: desc;
            }

            #{$this}__image {
                @include media('<desktop') {
                    min-width: calc(100% - 1rem);
                }

                grid-area: cover;
            }

            #{$this}__footer {
                grid-area: desc;
            }

            #{$this}__links {
                grid-area: footer;
            }
        }
    }

    &__tags {
        margin-bottom: 1rem;
        line-height: 1;
        margin-top: -0.4em;

        a {
            display: inline-block;
            margin-bottom: 1rem;
            text-decoration: underline;

            & + a {
                margin-right: 1.5em;

                @include media('>=phone') {
                    margin-left: 0.75em;
                    padding-left: 0.75em;
                    margin-right: 0;
                    border-left: toRem(1) solid $border-color;
                }
            }
        }
    }

    &--facets {
        #{$this}__image {
            flex: 0;
            background: center / cover no-repeat url('../../../images/article-placeholder.png');
            min-width: 10rem;

            &:after {
                padding-top: 120%;
            }

            @include media('<tablet', '>phone') {
                margin-right: 1.5rem;
            }
        }

        #{$this}__body {
            flex-flow: row nowrap;

            @include media('<phone') {
                flex-flow: column;
            }
        }
    }

    &__abstract {
        background: $dark--4;

        #{$this}--do & {
            background: transparent;
        }

        border-radius: toRem(4);
        padding: 0.5rem 0;
        flex: 1;
        //line-height: 1.83; <<- truncate js fails on such a big line height... really
        line-height: 1.73;

        &>.hlFld-Abstract,
        // theoretically shouldn't occur with axel
        &>div {
            display: block;
            padding: 0 1rem;

            &:not(.js--truncated) {
                display: -webkit-box;
                -webkit-line-clamp: 8;
                -webkit-box-orient: vertical;
                max-height: calc(8em * 1.8);
                overflow: hidden;

                #{$this}--journal & {
                    -webkit-line-clamp: 2;
                    max-height: calc(2em * 1.8);
                }

                #{$this}--collection & {
                    -webkit-line-clamp: 6;
                    max-height: calc(6em * 1.8);
                }
            }
        }

        .left-side-image {
            display: inline-block;

            img {
                vertical-align: middle;
                height: auto;
            }
        }
    }

    &--do {
        #{$this}__abstract {
            &>.hlFld-Abstract,
            // theoretically shouldn't occur with axel
            &>div {
                padding: 0;
            }
        }
    }

    &__image {
        margin-right: 1.5rem;
        position: relative;
        background: center / cover no-repeat;
        min-width: 16.875rem;
        align-self: flex-start;
        border: none;

        &:hover {
            border: none;
            box-shadow: inset 0 0 0 toRem(1) $darker;
        }

        &:focus {
            box-shadow: 0 0 toRem(4) toRem(1) rgba($supplementary, 0.5);
        }

        @include media('<phone') {
            // changed to 'phone' against the designs bc literatum give smaller images in the context
            width: 100%;
            margin: 0 auto 1rem;
            max-width: toRem(480);
        }

        &:after {
            content: '';
            display: block;
            position: relative;
            width: 100%;
            padding-top: 75%;
        }
    }

    &--collection {
        #{$this}__image {
            min-width: 9.875rem;

            &:after {
                padding-top: 129%;
            }
        }

        @include media('<tablet') {
            #{$this}__body {
                flex-flow: row;
            }

            #{$this}__image {
                min-width: 38%;
                width: auto;
                margin-right: 0.75rem;
            }
        }
    }

    .loa,
    &__authors {
        margin-bottom: 0.5rem;

        &:not(.js--truncated) {
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            max-height: calc(2em * 1.8);
            overflow: hidden;
        }
    }

    &__links {
        margin-top: 1.375rem;
        display: flex;
        justify-content: flex-end;

        @include media('<tablet') {
            margin-top: 1rem;
        }

        a {
            display: block;
            padding: 0.5em 0.75em 0.58em;
            color: $lighter;
            border-radius: toRem(4);
            transition: 0.15s box-shadow, 0.15s filter;

            &:hover {
                filter: brightness(1.2);
            }

            &:hover,
            &:focus {
                box-shadow: 0 0 0 toRem(3) rgba($darker, 0.15);
            }

            @include media('<tablet') {
                padding: 0.75em 0.75em;
            }
        }

        li {
            & + li {
                margin-left: 0.5rem;
            }
        }

        i {
            font-size: 1.25em;
            line-height: 1.2;
            vertical-align: middle;
        }

        .icon-eye {
            &:before {
                transform: scale(1.1);
            }
        }

        i + span {
            margin-left: 0.5em;
            vertical-align: middle;
        }
    }

    &__type {
        text-transform: uppercase;
        color: $dark--5;
        margin: 0 0.5em;

        &:only-child {
            margin: 0;
        }
    }

    &__meta {
        margin-bottom: 0.875rem;
        color: $dark--5;

        a {
            margin-right: 0.4em;
        }

        span + span:before {
            content: '•';
            font-size: 1.825rem; // has to be rem because of IE11 here
            margin: 0 0.2em;
            line-height: 0;
            vertical-align: sub;
        }
    }

    &__aux {
        font-weight: bold;
        margin-bottom: 0.875rem;
        color: $dark--5;
    }

    &__footer {
        padding-top: 0.313rem;
    }

    &__access {
        display: inline-block;

        &--free {
            color: $access--free;
        }

        &--open {
            color: $access--open;
        }

        &--no,
        &--restricted {
            color: $access--restricted;
        }

        i:before {
            padding-top: toRem(1);
        }
    }
}

.content-type {
    background: $dark--5;

    &--pdf {
        background: $pdf;
    }

    &--full-text {
        background: $supplementary;
    }

    &--view {
        background: $supplementary;
    }

    &--no-access,
    &--restricted {
        background: $access;
    }
}
