.top-banner {
    $this: &;
    color: $lighter;

    //overflow: hidden; // required for the correct work of search-overlay because of an occasional leaderboard presence

    & ~ main {
        padding-top: 3rem;
        &.content--loi {
            padding-top: 0;
        }
    }

    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;

    @include media('<tablet') {
        & ~ main {
            padding-top: 2rem;
        }
    }

    &__leaderboard {
        text-align: center;
        @include media('<tablet') {
            .lock-screen & {
                display: none;
            }
        }
        position: relative;
        z-index: map-get($z-index, 'top-banner-leaderboard');
    }

    &--landing {
        //background: url("../images/mountains.jpg") center / cover no-repeat;
        background: $dark;
        min-height: 100vh;
        @include media('>=desktop') {
            height: 100vh; // flex columns IE11
        }
        max-width: 100%;
        position: relative;
        #pb-editor & {
            height: auto;
        }
    }

    a,
    button,
    [type='submit'],
    [type='button'],
    .btn,
    .button {
        &:focus {
            box-shadow: 0 0 toRem(1) toRem(2) rgba($lighter, 0.3);
        }
    }

    /* =============== */
    /* FACETS Styling */
    /* =============== */

    &--facets-generic {
        margin-bottom: 0;
        position: relative;
        background: $darker var(--header-image) center / cover no-repeat;
        #{$this}__page-title {
            text-align: center;
            padding-bottom: 3rem;
            font-size: 0.875rem;
            margin: 0 auto;
            max-width: toRem(730);

            @include media('<tablet') {
                padding: 1.5rem;
            }

            a {
                text-decoration: none;
            }
            p a {
                text-decoration: underline;
            }
            h1,
            h2 {
                font-weight: normal;
                padding: 0.6em 0 0;
                font-size: 2.875rem;
                line-height: 1.2;
                @include media('<tablet') {
                    font-size: 1.375rem;
                }
            }
        }
    }

    &--facets-subjects {
        #{$this}__page-title {
            padding-bottom: 6rem;
        }
        & ~ .content {
            overflow: visible;
        }
    }

    &--collections {
        background-image: var(--header-image);
        .btn {
            &--inverse {
                border-color: $lighter;
                color: $lighter;
                text-decoration: none;
            }
        }
    }

    &__facets-info {
        $facets-info: &;
        padding: 0.875rem;
        font-size: 0.75rem;
        z-index: map-get($z-index, 'facets-info');
        background: linear-gradient(90deg, #710013 0%, rgba(124, 1, 21, 0) 80.71%) left top / 10% 100% no-repeat,
            linear-gradient(90deg, rgba(124, 1, 21, 0) 0%, #710013 80.71%) right top / 10% 100% no-repeat,
            $facets url('../../../images/facet-pattern.png');

        &__item {
            flex: 1;
            display: flex;
            flex-flow: row wrap;
            align-items: center;
            justify-content: space-evenly;
            text-align: center;

            @include media('<tablet') {
                padding: 0 0.5rem;
                margin: 0 0 1rem;
                &:last-child {
                    margin-bottom: 0;
                }
            }

            & + #{$facets-info}__item {
                @include media('>tablet') {
                    padding-left: 1.5rem;
                    margin-left: 1.5rem;
                    border-left: toRem(1) solid rgba($lighter, 0.2);
                }
            }
            p {
                margin: 0;
            }
        }

        &__logos {
            flex: 0 1 auto;
            & > a + a,
            & > img + img {
                margin-left: 1.5rem;
            }
        }

        &__description {
            text-align: center;
            min-width: 100%;
        }

        &__actions {
            display: flex;
            flex: 1 0 auto;
            margin-bottom: 0.5rem;
            .btn {
                flex: 1;
                & + .btn {
                    margin-left: 0.5rem;
                }
            }
            @include media('<tablet') {
                flex: 1;
            }
            @include media('>=tablet', '<desktop') {
                flex-flow: column;
                .btn {
                    & + .btn {
                        margin-left: 0;
                        margin-top: 0.5rem;
                    }
                }
            }
        }

        .btn {
            background: $lighter;
            color: $facets;
            &:hover {
                color: $lighter;
                background: transparent;
                border: toRem(1) solid $lighter;
            }
            &--inverse {
                color: $lighter;
                background: transparent;
                border-color: $lighter;
                &:hover {
                    color: $facets;
                    background: $lighter;
                }
            }
            @include media('<tablet') {
                padding: 0.75rem;
            }
        }
        .container {
            &:after,
            &:before {
                content: none;
            }
            display: flex;
            flex-flow: row wrap;
            @include media('<desktop') {
                width: auto;
            }
            @include media('<tablet') {
                padding: 0;
                flex-flow: column;
                align-items: center;
            }
        }
    }

    &--facets-landing {
        //background: url("../images/satellite-shot.jpg") center / cover no-repeat;
        background: $darker;
        .slide-item {
            margin: auto;
            text-align: center;

            .btn {
                text-decoration: none;
                &--inverse {
                    border-color: $lighter;
                    color: $lighter;
                }
            }
        }
        & ~ main {
            padding: 0;
        }
    }

    /* ================== */
    /* FACETS Styling END */
    /* ================== */

    &--generic {
        & ~ main {
            padding-top: 2rem;
            @include media('<tablet') {
                padding-top: 1rem;
                &.profile-page {
                    padding-top: 0;
                }
            }
        }
        background: url('../../../images/pattern-bk.png'),
            linear-gradient(to bottom, rgba($darker, 0.7) 0%, rgba($darker, 0) 100%),
            var(--header-image) center 45% / cover no-repeat;

        .header {
            border-bottom: toRem(1) solid rgba($lighter, 0.2);

            &__logo {
                img {
                    max-width: 9.875rem;
                    @include media('<desktop') {
                        max-width: 6.25rem;
                    }
                }
            }
        }

        #{$this}__page-title {
            h1,
            h2 {
                @include font-size(40);
                @include media('<desktop') {
                    @include font-size(24);
                    padding: 2rem;
                }
                @include media('<tablet') {
                    width: 100%;
                    padding: 1.5rem 0;
                }
            }

            @include media('<tablet') {
                // padding: 1.5rem 0;
                width: 100%;
            }
        }
    }

    &--journal-page {
        //& ~ main { // breadcrumbs are now implemented width margin top negative
        //    padding-top: 0; // we have breadcrumbs on these pages
        //}
        position: relative;
        z-index: map-get($z-index, 'journal-page');
        background: url('../../../images/pattern-bk.png'),
            linear-gradient(to bottom, rgba($darker, 0.9) 0%, rgba($darker, 0) 100%),
            var(--journal-image) center 45% / cover no-repeat;

        &:after {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background: var(--journal-color);
            opacity: 0.4;
        }

        .casi-site & {
            background: url('../../../images/pattern-bk.png'),
                linear-gradient(to bottom, rgba($darker, 0.7) 0%, rgba($darker, 0) 100%),
                linear-gradient(to bottom, rgba($casi, 0.4) 0%, rgba($casi, 0.4) 100%);
        }
        .cjcmh-site & {
            background: url('../../../images/pattern-bk.png'),
                linear-gradient(to bottom, rgba($darker, 0.7) 0%, rgba($darker, 0) 100%),
                linear-gradient(to bottom, rgba($cjcmh, 0.4) 0%, rgba($cjcmh, 0.4) 100%);
        }
        .doc-site & {
            background: url('../../../images/pattern-bk.png'),
                linear-gradient(to bottom, rgba($darker, 0.7) 0%, rgba($darker, 0) 100%),
                linear-gradient(to bottom, rgba($cjdpr, 0.4) 0%, rgba($cjdpr, 0.4) 100%);
        }
        .nuclear-site & {
            background: url('../../../images/pattern-bk.png'),
                linear-gradient(to bottom, rgba($darker, 0.7) 0%, rgba($darker, 0) 100%),
                linear-gradient(to bottom, rgba($cnr, 0.4) 0%, rgba($cnr, 0.4) 100%);
        }
        .ciphi-site & {
            background: url('../../../images/pattern-bk.png'),
                linear-gradient(to bottom, rgba($darker, 0.7) 0%, rgba($darker, 0) 100%),
                linear-gradient(to bottom, rgba($ehr, 0.4) 0%, rgba($ehr, 0.4) 100%);
        }
        .lymphosign-site & {
            background: url('../../../images/pattern-bk.png'),
                linear-gradient(to bottom, rgba($darker, 0.7) 0%, rgba($darker, 0) 100%),
                linear-gradient(to bottom, rgba($lpsn, 0.4) 0%, rgba($lpsn, 0.4) 100%);
        }
        .sfj-site & {
            background: url('../../../images/pattern-bk.png'),
                linear-gradient(to bottom, rgba($darker, 0.7) 0%, rgba($darker, 0) 100%),
                linear-gradient(to bottom, rgba($sfj, 0.4) 0%, rgba($sfj, 0.4) 100%);
        }
        .cif-site & {
            background: url('../../../images/pattern-bk.png'),
                linear-gradient(to bottom, rgba($darker, 0.7) 0%, rgba($darker, 0) 100%),
                linear-gradient(to bottom, rgba($tfc, 0.4) 0%, rgba($tfc, 0.4) 100%);
        }
    }

    &--journal {
        height: 100vh; // explicit width required for IE11 to support flex columns auto height
        min-height: 100vh;
        position: relative;
        background: url('../../../images/pattern-bk.png'),
            linear-gradient(to bottom, rgba($darker, 0.75) 0%, rgba($darker, 0) 22%, rgba($darker, 0) 100%),
            var(--journal-image) center 45% / cover no-repeat;

        &:after {
            content: '';
            opacity: 0.25;
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background: var(--journal-color);
        }

        &:before {
            content: '';
            background: $darker;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            opacity: 0.1;
            height: 100%;
        }

        & ~ main {
            padding-top: 0;
        }
        &.journal-with-slideshow {
            width: 100%;
            @include media('>=phone') {
                height: max-content;
                min-height: 65vh;
            }
            .main-slider {
                position: relative;
                .slide-item {
                    padding-top: 2rem;
                    padding-bottom: 1rem;
                }
            }

            .card-slide {
                &__title {
                    @include media('>phone') {
                        font-size: 1.5rem;
                    }
                }
            }
            .top-banner__go-down {
                font-size: 2.5rem;
                position: relative;
                display: table-row;
            }
            .slideShow__external-navigation {
                margin: toRem(20) 0;
            }
        }
    }

    &--facets {
        &.top-banner--journal-page {
            &:after {
                background: $darker;
                opacity: 0.3;
            }
        }
    }

    &--article {
        background: url('../../../images/pattern-bk.png'),
            linear-gradient(to bottom, rgba($darker, 0.75) 0%, rgba($darker, 0.45) 22%, rgba($darker, 0.45) 100%),
            var(--journal-image) center 45% / cover no-repeat;
    }

    &__bkg-carousel {
        pointer-events: none;
        position: absolute;
        padding: 0;
        margin: 0;
        top: 0;
        left: 0;
        z-index: map-get($z-index, 'bkg-carousel');
        width: 100%;
        height: 100%;
        div {
            background-position: center;
            background-size: cover;
            height: 100%;
        }
    }

    &__bottom-stripe {
        padding: 1rem 0 0;
        background: rgba($darker, 0.3);
        position: relative;
        .container {
            &:after,
            &:before {
                content: none;
            }
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        @include media('<tablet') {
            text-align: center;
            padding: 1.25rem 0;
        }

        #{$this}__go-down {
            position: absolute;
            bottom: 100%;
            left: 50%;
            transform: translateX(-50%);
            &:focus {
                text-decoration: none;
            }
        }
    }

    &__journal-title {
        font-size: 1.5rem;
        padding-bottom: 1rem;
        @include media('<desktop') {
            font-size: 1rem;
        }
    }

    &__page-title {
        z-index: map-get($z-index, 'page-title');
        @include media('<desktop') {
            margin-top: 0;
            margin-bottom: 0;
            width: 100%;
        }
        &--search {
            display: flex;
            justify-content: space-between;
            align-items: center;
            @include media('<desktop') {
                align-items: stretch;
                flex-flow: column;
            }
            h2,
            h1 {
                flex: 1 0 auto;
            }
        }

        h1,
        h2 {
            font-size: 2.5rem;
            padding: 0.8em 0;
            margin: 0;
            color: inherit;
            font-weight: 400;
            @include media('<desktop') {
                font-size: 1.675rem;
            }
            @include media('<tablet') {
                font-size: 1.375rem;
            }
        }
        strong {
            font-weight: 600;
        }
    }

    &__main-buttons {
        margin-right: -0.5rem;
        text-align: right;
        @include media('<tablet') {
            flex: 1;
            margin-right: 0;
        }
        a {
            color: $lighter;
            border-color: $lighter;
            margin: 0 0.5rem 1rem;
            &:hover,
            &:active {
                background-color: $lighter;
                color: $dark;
            }
            @include media('<tablet') {
                display: flex;
                margin: 0;
            }
            & + a {
                @include media('<tablet') {
                    margin-top: 0.5rem;
                }
            }
        }
    }

    &__current-journal {
        z-index: map-get($z-index, 'current-journal');
        flex: 1;
        display: flex;
        flex-flow: row;
        justify-content: stretch;
        padding: 0 1rem;
        @include media('>phone') {
            padding: 0 2rem;
            justify-content: flex-start;
            align-items: center;
        }
    }

    &__go-down {
        z-index: map-get($z-index, 'go-down');
        text-align: center;
        font-size: 3.5rem;
        align-self: center;
        padding-bottom: 1rem;
    }

    .card-slide {
        &__link,
        &__header,
        &__title {
            color: inherit;
        }

        &__link,
        &__header {
            font-size: 0.875em;
        }

        &__title {
            @include media('>phone') {
                font-size: 2em;
            }
            font-weight: normal;
            letter-spacing: 0.006em;
        }
    }

    .slideShow {
        &__external-navigation {
            @include media('>desktop') {
                display: flex;
                align-items: center;
            }
            @include media('<desktop') {
                order: 2;
                position: relative;
            }
        }
        &__external-dots {
            text-align: center;
            display: flex;
            flex-flow: column nowrap;
            align-items: center;
            justify-content: center;

            @include media('<desktop') {
                flex-flow: row;
                padding: 0.5rem 0;
                border-top: toRem(1) solid rgba($lighter, 0.2);
            }

            .owl-dot {
                i {
                    color: $owl-dot-color;
                    font-size: toRem(10);
                }
                span {
                    @include media('<desktop') {
                        margin: toRem(10) toRem(7);
                    }
                }
                &.active span {
                    margin: toRem(5) toRem(1);
                    @include media('<desktop') {
                        margin: toRem(6) toRem(10);
                    }
                }
            }
        }
    }
}

.current-journal {
    $this: &;

    font-size: 1.25vw;
    @include media('<desktop') {
        font-size: 1.65vw;
        padding-top: 1rem;
        //flex: 1;
        //grid-template-columns: calc(6.1375em + 1em) auto;
    }
    @include media('<tablet') {
        width: 100%;
    }
    @include media('>large') {
        font-size: 0.89rem;
    }
    @include media('>tablet') {
        justify-items: start;
    }

    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: 1fr auto auto;
    grid-template-areas: 'blaze title' 'empty info' 'empty button';

    &--client {
        grid-template-columns: auto;
        #{$this}__data {
            grid-column-end: auto;
        }
    }

    &__data {
        font-size: 1.125rem;
        font-weight: 600;
        margin: 0.8vw 0 1.3vw;
        grid-area: info;

        @include media('<desktop', '>phone') {
            margin: 2rem 0 2rem;
        }

        @include media('<phone') {
            grid-column-start: 1;
            grid-column-end: 3;
            font-size: 1.375rem;
            margin: 1em 0;
        }

        span + span {
            &:before {
                content: '•';
                margin: 0 0.5em;
            }
        }
    }

    &__logo-icon {
        height: 6.138em;
        margin-right: 0.7em;
        grid-area: blaze;
        @include media('>phone') {
            grid-row-start: 1;
            grid-row-end: 4;
        }
    }
    &__logo-text {
        font-size: 4.18em;
        padding-top: 0.6em;
        text-shadow: 0 0 toRem(10) rgba($darker, 0.25);
        grid-area: title;

        h1 {
            font-weight: normal;
            color: $white;
            font-size: 1em;
            text-shadow: 0 0 toRem(10) rgba($darker, 0.25);
            grid-area: title;
            margin-bottom: 0;
        }

        p {
            font-size: 0.45em;
            margin: 0.25em 0 0.2em;
            line-height: 1.2;
        }
    }

    img {
        display: block;
    }
    .btn {
        font-size: 0.75rem;
        padding: 1.5em 4.2em;
        grid-area: button;

        @include media('<phone') {
            grid-column-start: 1;
            grid-column-end: 3;
        }
    }
}

.main-slider {
    margin: 0;
    display: flex;
    flex-flow: row nowrap;
    z-index: map-get($z-index, 'main-slider');
    padding: 0 toRem(40);
    min-width: 100%; // may cause issue of the flex vs owl-carousel - has to be checked on a phone

    .slideShow {
        overflow-y: hidden; // owl-carousel fix attempt - seems to be working
    }

    @include media('>xlarge') {
        // this is a special case for minor aesthetical tweak. that breakpoint doesnt have (so far) any implications elsewhere
        font-size: 1.1vw;
    }
    @include media('>jumbo') {
        // this is a special case for minor aesthetical tweak. that breakpoint doesnt have (so far) any implications elsewhere
        font-size: 1.25rem;
    }
    @include media('<desktop') {
        flex-flow: column nowrap;
        padding: 0;
        flex: 1;
        justify-content: space-between;
        &:before {
            // to centralize slider in the middle with space-between
            content: '';
            display: block;
        }
    }

    .slide-item {
        padding-top: 3.5em;
        padding-bottom: 4em;
        max-width: toRem(
            1380
        ); /* an arbitrary value roughly matching an acceptable text line length so the text looks nice and readable */
        .hidden {
            display: none;
        }
    }
}
