.remote_institutional_access {
    .institution_part {
        @include media('<tablet') {
            margin-bottom: 1rem;
        }
    }

    .renew_part {
        .renew_message {
            border-radius: toRem(4);
            background-color: transparent;
        }

        .abstract_button {
            @include media('<tablet') {
                margin-bottom: auto;
                text-align: center;
                float: none;
            }
        }
    }

    .section {
        @include media('<tablet') {
            flex-wrap: wrap;
            margin-bottom: auto;

            &:not(:first-child) {
                margin-top: 1rem;
            }
        }
    }

    .icons_part {
        width: auto;
        margin-right: 0.5rem;
    }

    .add_affiliation {
        padding-top: 1rem;
        @include media('<tablet') {
            float: none;
        }
    }
}
