.custom {
    h4,
    h5,
    h6 {
        font-weight: bold;
        color: inherit;
    }
    font-size: 0.875rem;
    hr {
        color: $dark--9;
        background-color: $dark--9;
        border-width: 0;
        height: toRem(1);
    }
    &__meta {
        b {
            color: $dark--5;
            font-size: 0.75rem;
        }
        & > span {
            & + span {
                &:before {
                    content: ' | ';
                }
            }
        }
    }
    & > section {
        margin: 2.5rem 0;
    }

    a:not(.btn) {
        color: $supplementary;
        &:hover {
            text-decoration: underline;
        }
    }

    &__blocks {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        &:after {
            content: '';
        }
        &:after,
        li {
            flex: 0 0 calc(50% - 0.5rem);
            @include media('<phone') {
                flex: 1 0 100%;
            }
        }

        li {
            padding: 1rem;
            margin-bottom: 1.125rem;
            box-shadow: 0 toRem(2) toRem(2) rgba($darker, 0.15);
            background: $lighter;
            border-radius: toRem(4);
        }
        &--s {
            li {
                box-shadow: none;
                background: transparent;
                border-radius: 0;
            }
        }
        &--i {
            li {
                padding: 0;
                overflow: hidden;
                .custom & > a {
                    text-decoration: none;
                    color: inherit;
                    padding: 2rem 1rem;
                    display: flex;
                    justify-content: space-between;
                    border: toRem(1) solid transparent;
                    i {
                        color: $dark--9;
                    }
                    border-radius: inherit;
                    &:focus,
                    &:hover {
                        text-decoration: underline;
                        border: toRem(1) solid $border-color;
                    }
                }
            }
        }
        &--3 {
            &:after,
            li {
                flex: 0 0 calc(33.3% - 1rem);
                @include media('<desktop') {
                    flex: 0 0 calc(50% - 0.5rem);
                }
                @include media('<phone') {
                    flex: 1 0 100%;
                }
            }
        }
        &--partners {
            justify-content: flex-start;
            @include media('<phone') {
                justify-content: center;
            }
            li {
                padding: 0.5rem;
                margin-right: 1rem;
            }
            &:after,
            li {
                flex: 0 0 toRem(255);
            }
        }
    }
    h7 {
        // h7? custom tags?
        font-weight: bold;
    }
}

.custom-sidebar {
    font-size: 0.875rem;
}

.number {
    color: $dark--5;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1.14;

    &__big {
        font-weight: bold;
        margin-right: 1rem;
        color: $darker;
        font-size: 1.563rem;
    }
    i {
        font-size: 0.75rem;
    }
}

.link {
    &--humble {
        color: $dark--5;
        text-decoration: underline;
    }
    $this: &;
    &--thumbnail {
        a {
            text-decoration: none;
            color: inherit;
            &:hover,
            &:focus {
                color: $supplementary;
                i {
                    color: inherit;
                }
            }
        }
        #{$this}__text {
            display: flex;
            padding: 0 0.3rem 0.3rem;
            line-height: 1.14;
        }
        .icon-open_in_new {
            font-size: 0.75rem;
            padding: 0.15rem 0.25rem;
            color: $dark--9;
        }
    }
}

.portrait {
    border-radius: 51%;
    border: toRem(1) solid $border-color;
    @include media('<tablet') {
        max-width: 25%;
    }
    &--lg {
        @include media('<tablet') {
            max-width: 100%;
        }
    }
}

.oa-feats {
    display: flex;
    flex-flow: row wrap;
    color: $lighter;
    &__box {
        padding: 2rem;
        background: $darker;
        &:only-child {
            height: 100%;
            background: bottom center / 100% auto no-repeat url('../../../images/polygons--upwards.png') $darker;
        }
    }
    p {
        line-height: 1.71;
    }
}

.partner {
    display: flex;
    &__content {
        margin-left: $grid-gutter-width;
        @include media('<phone') {
            margin-left: 0;
        }
    }
    &__logos {
        flex-flow: column;
        flex: 1 0 auto;
        max-width: 40%;
        display: flex;
        @include media('<phone') {
            align-items: center;
            flex: auto;
            max-width: auto;
        }
    }
    @include media('<phone') {
        align-items: center;
        flex-flow: column;
    }
}

.feature-grid__item {
    display: block;
    height: toRem(240);
    position: relative;
    background-color: $secondary;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    margin-bottom: toRem(30);
    transition-duration: 0.25s;
    transition-property: transform;

    &:hover {
        transform: scale(1.05);
    }
}
.fg-item__text {
    position: absolute;
    bottom: 0;
    width: 100%;
    color: $white;
    padding: toRem(20);
    background-color: rgba($secondary, 0.75);

    h5 {
        color: $lighter;
    }
}
.row-item {
    @include media('<phone') {
        display: flex;
        flex-direction: column;
    }

    &__text {
        @include media('<phone') {
            order: 1;
        }
    }
    &__nav {
        @include media('<phone') {
            order: 0;
            margin-bottom: toRem(30);
        }
    }
}

.custom__accordion {
    .pill__acc-title {
        display: flex;
        align-items: center;
    }
    i {
        margin-left: toRem(8);
    }
    .js--target-shown {
        i {
            transform: rotate(180deg);
        }
    }
}

.js--truncated {
    p:last-of-type {
        display: inline;
    }
}
