.sections-navbar {
    /* stylelint-disable unit-disallowed-list*/
    $navbarWidth: 193px;
    /* stylelint-enable unit-disallowed-list*/
    $this: &;

    transform: translateX(calc(-100% - 2rem));

    position: sticky;
    font-size: 0.875rem;
    width: 100%;
    min-width: $navbarWidth;
    top: 1rem;
    max-height: 100vh;
    display: flex;
    flex-flow: column;
    padding-bottom: 1rem;

    &--generic {
        transform: none;
        min-width: 0;
        #{$this}__container {
            min-height: 0;
        }
    }

    &__container {
        background: $lighter;
        border: toRem(1) solid $dark--4;
        color: $dark--5;
        box-shadow: 0 toRem(2) toRem(4) rgba($darker, 0.1);
        padding: 0.625rem 1.12rem;
        display: flex;
        flex-flow: column nowrap;
        min-height: 20rem;
        z-index: map-get($z-index, 'sections-navbar');
        position: relative;

        #{$this}--empty & {
            min-height: 0;
        }
    }
    ul {
        border-top: toRem(1) solid $dark--4;
        border-bottom: toRem(1) solid $dark--4;
        list-style: none;
        flex: 1;
        overflow: auto;
        background: $dark--3;
        transition: background 0.2s ease;

        &:empty {
            display: none;
        }

        &:not(:empty) {
            margin: 0 -1.12rem;
            padding: 0.56rem 0.3rem 0.56rem 1.12rem;
            margin-bottom: 0.625rem;
        }

        &.js--has-scroll-bottom {
            background: linear-gradient(to top, rgba($border-color, 0.5) 0%, rgba($border-color, 0) 15%) $dark--3;
        }
        &.js--has-scroll-top {
            background: linear-gradient(to bottom, rgba($border-color, 0.5) 0%, rgba($border-color, 0) 15%) $dark--3;
        }
        &.js--has-scroll-top.js--has-scroll-bottom {
            background: linear-gradient(to top, rgba($border-color, 0.5) 0%, rgba($border-color, 0) 15%),
                linear-gradient(to bottom, rgba($border-color, 0.5) 0%, rgba($border-color, 0) 15%) $dark--3;
        }

        line-height: 1.2;
        a {
            display: block;
            margin: 0.85rem 0;
            position: relative;

            &:before {
                display: inline-block;
                position: absolute;
                top: 0;
                content: '\e061';
                right: 90%;
                transform: scale(0.45);
                opacity: 0;
                font-family: icomoon !important;
                line-height: 1;
            }
            &:focus {
                background: transparent;
            }
        }
        .active {
            a {
                color: $supplementary;
                &:before {
                    right: 100%;
                    opacity: 1;
                    transition: right 0.3s ease, opacity 0.3s ease;
                }
            }
        }
    }
    &__title,
    [property='name'] {
        color: $darker;
        font-weight: bold;
        font-size: 1rem;
        margin-bottom: 0.625rem;
    }
    .core-self-citation {
        line-height: 1.25;
    }
    &__subtitle,
    .core-enumeration {
        margin: 0.5rem 0 0.625rem;
        line-height: 1.15;
    }

    &__title {
        margin-bottom: 0.5em;
        line-height: 1.25;
    }
    &__nav {
        color: $supplementary;
        display: flex;
        justify-content: space-between;
        margin-bottom: 0.625rem;
        i {
            vertical-align: middle;
        }
    }
    footer,
    &__footer {
        text-align: center;
        img {
            max-height: toRem(60);
            display: block;
            margin: 0 auto;
        }
    }
    @include media('<#{$container-lg + $navbarWidth * 2 + $grid-gutter-width}') {
        &:not(.sections-navbar--generic) {
            display: none;
        }
    }
}
