.submit-call {
    color: $lighter;
    padding: 2.5rem;
    height: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    background: $dark--2 center / cover no-repeat url('../../../images/brand-pattern-grey.png');
    box-shadow: inset 0 0 toRem(150) toRem(50) rgba($darker, 0.9);

    &__title {
        color: inherit;
        font-size: 1.938rem;
        font-weight: normal;
        margin-bottom: 1em;
    }
    a {
        color: inherit;
    }
    .btn {
        padding-top: toRem(14);
        padding-bottom: toRem(14);
    }
}
