button,
[type='submit'],
[type='button'],
.btn,
.button {
    padding: toRem(10) toRem(24);
    border-radius: toRem(4);
    @include media('<tablet') {
        padding: toRem(12) toRem(24);
    }
    &:focus {
        color: $lighter;
    }
}

.btn {
    &--select {
        background: $lighter;
        box-shadow: 0 toRem(2) toRem(4) rgba($darker, 0.1);
        text-align: left;
        text-decoration: none;
        color: inherit;
        text-transform: none;
        justify-content: space-between;
        padding: 1rem;
        &:hover,
        &:focus {
            color: $secondary;
        }
    }
    &--journal {
        background: var(--journal-color);
        //@include journal-branding('background');
    }
    &--auxiliary {
        &,
        .journal-branding &,
        .site-branding & {
            background: $auxiliary;
        }
    }

    &--secondary {
        &,
        .journal-branding &,
        .site-branding & {
            background: $supplementary;
        }
    }

    &--slim {
        padding: toRem(10);
    }

    &--inverse-light {
        color: $lighter;
        border: toRem(1) solid $lighter;
        background: transparent;
        &:hover,
        &:focus {
            background: $lighter;
            color: $secondary;
        }
    }

    .journal-branding &:not(.btn--inverse):focus {
        color: $lighter;
    }

    span + span {
        margin-left: 0.2em;
    }
}

.modal__header {
    .close {
        &:focus {
            color: $black;
        }
    }
}
