.showcase-slideshow {
    .labels {
        img {
            display: block;
        }
        @include media('>phone') {
            padding-right: toRem(45);
        }
        @include media('<tablet') {
            display: none;
        }

        &__list {
            &__item {
                min-height: toRem(130);
                @include media('<desktop') {
                    min-height: 0;
                }
            }
            &__img {
                position: relative;
                overflow: hidden;
                width: calc(20% - 1.25rem);
                margin-right: toRem(20);

                &:after {
                    content: '';
                    display: block;
                    padding-bottom: 100%;
                    background: $dark--6 url('../../../images/logo__symbol-skeleton-opaque.svg') center / auto 60%
                        no-repeat;
                }
                img {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    min-height: 100%;
                    min-width: 100%;
                    object-fit: cover;
                    width: auto;
                    padding: 0;
                }
            }
        }
    }

    .js-showcase-current {
        filter: drop-shadow(0 toRem(2) toRem(2) rgba($theme-text-darker, 0.1));
        &:after {
            border-bottom-width: toRem(65);
            border-top-width: toRem(65);
            border-left-width: toRem(40);
            height: 100%;
            left: 100%;
            @include media('<desktop') {
                content: none;
            }
        }
    }

    .read-more-link a {
        text-decoration: underline;
    }
}

.showbox {
    &__item__content {
        img {
            display: block;
            min-width: 100%;
            margin: auto auto 1rem auto;
        }
    }
}

.recommended-articles {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    margin: 0 -0.5rem;

    @include media('<desktop') {
        flex-flow: column;
    }

    h3 {
        font-size: 0.875rem;
    }
    .loa {
        font-size: 0.75rem;
    }
    &__item {
        display: flex;
        flex-flow: column;
        justify-content: space-between;
        flex: 1 0 calc(50% - 1rem);

        padding: 1rem;
        margin: 0.5rem;
        background: $lighter;
        box-shadow: 0 toRem(2) toRem(2) rgba($darker, 0.15);
        border-radius: toRem(4);
    }
}
