$journals: (
    anc: $anc,
    apnm: $apnm,
    as: $as,
    bcb: $bcb,
    cbc: $bcb,
    cjb: $cjb,
    cgj: $cgj,
    cjas: $cjas,
    cjc: $cjc,
    cjce: $cjce,
    cjes: $cjes,
    cjfas: $cjfas,
    cjfr: $cjfr,
    cjm: $cjm,
    cjp: $cjp,
    cjpp: $cjpp,
    cjps: $cjps,
    cjss: $cjss,
    cjz: $cjz,
    er: $er,
    facets: $facets,
    gen: $gen,
    geomat: $geomat,
    juvs: $juvs,
    tcsme: $tcsme,
    casj: $casj,
    cjcmh: $cjcmh,
    cjdpr: $cjdpr,
    anr: $anr,
    cnr: $cnr,
    ehr: $ehr,
    lpsn: $lpsn,
    sfj: $sfj,
    tfc: $tfc,
    dsa: $dsa,
);

[class*='fill-'] {
    // fallback if no journal colour provided
    background: $dark--5;
}

@each $journal, $color in $journals {
    .#{$journal} {
        color: $color;
    }
    .fill-#{$journal} {
        background-color: $color;
    }
}
