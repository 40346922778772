.loginBar__username {
    @include media('<large') {
        i {
            padding-right: 0;
        }
    }
}

.header__top-menu {
    position: relative;

    .navigation-login-dropdown-container {
        right: 0;
        padding: toRem(10) 0;
        width: auto;
        margin: toRem(10) 0;
        box-shadow: 0 toRem(4) toRem(20) rgba($shadow-color, 0.2);
        border-radius: toRem(5);
        min-width: toRem(204);

        &:before {
            content: ' ';
            width: 0;
            height: 0;
            border: toRem(10) solid transparent;
            position: absolute;
            border-bottom-color: $white;
            top: toRem(-17);
            left: calc(50% + 0.625rem);
        }

        ul {
            list-style: none;
            padding: 0 toRem(10);
            display: block;
        }

        li {
            padding: toRem(5);
        }
        a {
            padding: toRem(5) toRem(8);
            margin-left: 0.8rem;
        }

        @include media('<desktop') {
            position: relative;
            top: 0;
            margin-top: 0;
            right: toRem(10);

            &:before {
                left: 50%;
            }
        }
    }
}
