.collection-header {
    padding-bottom: 1rem;
    border-bottom: toRem(1) solid $border-color;
    font-size: 0.875rem;
    margin-bottom: 1rem;

    p {
        line-height: 1.9;
    }
    img {
        margin-bottom: 1rem;
    }
    h2 {
        line-height: 1.14;
    }
}

.curators {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    width: 100%;

    @include media('<desktop') {
        margin-bottom: 2em;
    }

    &__single {
        display: flex;
        margin-bottom: 0.5rem;
        border-bottom: toRem(1) solid $border-color;
        padding-bottom: 0.5rem;
        font-size: 0.875rem;
        align-items: center;
    }
    &__name {
        font-weight: bold;
    }
    &__portrait {
        max-width: toRem(90);
        border-radius: 51%;
        margin-right: 1rem;
    }
}
