.book {
    display: flex;
    flex-flow: row wrap;
    font-family: $default-font-family;
    font-size: 0.875rem;

    h1,
    h2 {
        font-size: 1.8rem;
        line-height: 1.4;
        @include media('<tablet') {
            font-size: 1.25rem;
            margin-top: 0.5em;
        }
    }
    h4 {
        font-size: 1.125rem;
        font-weight: 700;
        margin-bottom: 1rem;
    }

    &__meta {
        display: flex;
        flex-flow: row wrap;
        font-size: 0.875rem;
        flex: 1 1 100%;
        margin-bottom: 1.5rem;
        @include media('<phone') {
            flex-flow: column;
        }
        &__item {
            & + .book__meta__item {
                @include media('>=phone') {
                    margin-left: 1em;
                    padding-left: 1em;
                    border-left: toRem(1) solid $border-color;
                }
            }
            text-transform: capitalize;
            a {
                text-transform: none;
            }
        }
    }

    &__item-desc {
        line-height: 1.4;
        color: $dark--5;
    }

    &__content {
        flex: 1;
    }
    &__cover + .book__content {
        margin-left: 1.5rem;
        @include media('<phone') {
            flex: 1 0 100%;
            margin-left: 0;
        }
    }
    &__doi {
        margin: 0.5rem 0 0 0;
        a {
            text-decoration: none;
            color: $dark--2;
            &:hover {
                text-decoration: underline;
            }
        }
    }
    &__footer {
        flex: 1 0 100%;
        margin-top: 1rem;
        padding: 1rem 0;
        border-top: toRem(1) solid $border-color;
        @include media('<tablet') {
            text-align: left;
        }
        a + a {
            margin-left: 0.75rem;
        }
        .btn {
            @include media('>tablet') {
                min-width: 16em;
            }
            i {
                margin-right: 0.5em;
                font-size: 1rem;
            }
        }
    }
    .loa-accordion,
    .loa {
        color: $dark--5;
        &:hover {
            color: $supplementary;
        }
    }
    .loa-accordion {
        display: flex;
        align-items: center;
        flex-flow: row wrap;
        [data-db-target-for] {
            i {
                display: none;
            }
            color: inherit;
            p {
                margin: 0.375em 0;
            }
        }
    }
    .author-name {
        padding: 0;
    }
    .accordion-tabbed__tab-mobile + span {
        margin-right: 0.3em;
    }

    .btn {
        text-decoration: none;
        &--PDF {
            background: $pdf;
        }
    }

    &-top {
        &:not(:only-child) {
            padding-bottom: 2rem;
            border-bottom: toRem(1) solid $border-color;
        }
        article a:not(.btn):not(.purchase-item) {
            color: $text--secondary;
            text-decoration: underline;

            &:focus,
            &:hover {
                color: $link-color;
            }
        }

        a.purchase-item:hover {
            text-decoration: underline;
            color: $supplementary;
        }
    }
}
main.content.book-page {
    padding-bottom: 0;
}

.book-page {
    @include media('>tablet') {
        min-height: 60vh;
    }
    .eCommercePurchaseAccessWidget {
        @include media('>tablet') {
            display: flex;
        }
        .loginOptions {
            .btn {
                background: $secondary;
                border: none;
            }
        }
        margin: 1rem 0 0 auto;
        .btn,
        button,
        input[type='submit'] {
            // @TODO - not good, better to move it a more generic scope, also it duplicated in the pill
            color: $lighter;
            background: $supplementary;
            text-transform: uppercase;
            padding: 0.875em 2em;
            border-radius: toRem(4);
            i {
                font-size: 1.2em;
                margin: 0 0.4em;
            }
            i:first-child {
                margin: 0 0.4em 0 0;
            }
            i:last-child {
                margin: 0 0 0 0.4em;
            }
            .icon-copy-o {
                font-size: 1.4em;
            }
        }
    }
    .purchase-options-container {
        display: flex;
        flex-flow: column;
    }
    .purchaseArea {
        margin-right: 2rem;
        flex: 1;
        flex-flow: column;
        display: flex;
        &--list {
            flex: 1;
            @include media('>=tablet') {
                border-top: none;
            }
        }
    }

    .loginOptions .accordion {
        justify-content: flex-start;
        .btn {
            margin-right: 1rem;
        }
    }
}

.named-content {
    font-style: italic;
}
