.panel {
    $panel: &;

    box-shadow: none;
    border: toRem(1) solid $dim;
    background-color: $card-bg;
    color: $theme-text-dark;

    @include media('<desktop') {
        margin-bottom: 2rem;
    }

    @include media('<tablet') {
        margin: 1rem 0;
    }

    &__header {
        border-bottom: toRem(1) solid $dim;
        padding: 1rem 1.5rem 1.375rem;
        background-color: $dark--3;
        @include media('<tablet') {
            padding: 1.125rem 0.75rem;
        }
    }

    &__title {
        @include font-size(24);
        margin: 0;
        padding: 0;
        color: $theme-text-darker;
        text-transform: capitalize;
        @include media('<tablet') {
            @include font-size(21);
        }
    }

    &__body {
        padding: 1rem 3rem 2.125rem;
        @include media('<tablet') {
            padding: 1rem;
        }
    }

    &__footer {
        background-color: $dark--3;
        padding: 1.625rem 3rem 1.5rem;
        border-top: toRem(1) solid $dim;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include media('<tablet') {
            padding: 1.5rem 1.625rem;
        }

        &__info {
            @include font-size(18);
            margin-right: 0.875rem;
            font-weight: 500;
        }
    }

    .btn--inverse {
        color: $theme-text-darker;
        border: toRem(1) solid $dim;

        &:hover {
            background-color: $dim;
            border: toRem(1) solid $dim;
        }
    }

    button,
    .btn,
    .btn--inverse,
    [type='submit'] {
        @include font-size(12);
        padding: 0.625rem 3rem;

        @include media('<tablet') {
            padding: 0.625rem 1rem;
        }
    }
}
