@use 'sass:math';

.footer {
    $footer: &;

    padding-top: 2.375rem;
    font-size: 0.75rem;
    color: $lighter;
    background: left center / auto auto no-repeat url('../../../images/polygons--rightward.png'),
        $darker right center / auto auto no-repeat url('../../../images/polygons--leftward.png');

    @include media('<desktop') {
        font-size: 1rem;
        padding-top: 0;
        background-position: left toRem(120), right toRem(120);
    }

    @include media('<tablet') {
        background: center bottom / 100% auto no-repeat url('../../../images/polygons--downwards-dark.png'),
            $darker center top / 100% auto no-repeat url('../../../images/polygons--upwards-dark.png');
    }

    /* @TODO Refactor code BELOW after tablet designs delivered and implemented */

    h6 {
        color: inherit;
        font-size: inherit;
        margin: 0;
        font-weight: 600;
        padding: 1rem 0;
        @include media('<desktop') {
            border-top: toRem(1) solid rgba($lighter, 0.2);
            padding: 1rem;
            margin-left: -#{$grid-gutter-width * 0.5};
            margin-right: -#{$grid-gutter-width * 0.5};
        }
    }

    a {
        &:hover {
            color: $footer-hover-color;
            text-decoration: underline;
        }

        &:focus {
            color: $footer-hover-color;
            box-shadow: 0 0 toRem(1) 0 rgba($lighter, 0.85) inset, 0 0 toRem(1) toRem(2) rgba($lighter, 0.5);
        }
    }
    &__wrapper {
        background: transparent;
    }

    &__top {
        display: flex;
        @include media('<desktop') {
            display: block;
        }
    }

    &__logo {
        max-width: 10rem;
        margin: 0 auto;
        padding: 2rem 0;
    }

    &__bottom {
        padding: 2rem 0 1.625rem;
        @include media('<desktop') {
            padding-top: 0;
        }
        .col-xs-12 {
            display: flex;
            justify-content: space-between;
            @include media('<desktop') {
                flex-flow: column;
            }
            &:after,
            &:before {
                content: none;
            }
        }
    }
    &__legal-links {
        display: flex;
        @include media('<desktop') {
            flex-flow: column;
            font-size: 0.75rem;
            font-weight: normal;
            color: rgba($lighter, 0.8);
            border-top: toRem(1) solid rgba($lighter, 0.2);
            border-bottom: toRem(1) solid rgba($lighter, 0.2);
            margin: 0 #{math.div($grid-gutter-width, -2)};
            padding: 0 1rem;
        }

        a {
            @include media('<desktop') {
                padding: 0.75rem 0;
            }
            & + a {
                @include media('>desktop') {
                    margin-left: 1.25rem;
                }
            }
        }

        p {
            color: inherit;
            font-size: inherit;
            margin: 0;
            font-weight: 600;
            padding: 1rem 0;
            @include media('<desktop') {
                border-top: toRem(1) solid rgba($lighter, 0.2);
                padding: 1rem;
                margin-left: -#{$grid-gutter-width * 0.5};
                margin-right: -#{$grid-gutter-width * 0.5};
            }
        }

        h6,
        p {
            margin-bottom: 0;
            padding-top: 0;
            padding-bottom: 0;
            border: none;
        }
    }

    &__copyright {
        @include media('<desktop') {
            padding-top: 1.875rem;
        }
        &__logo {
            max-height: 1em;
            margin: 0 0.5em;
        }
        span:first-child {
            opacity: 0.5;
        }
    }

    &__social-media {
        @include media('<desktop') {
            margin-bottom: 1.5rem;
        }
        a {
            border: toRem(1) solid rgba($lighter, 0.85);
            border-radius: 50%;
            font-size: 1.125rem;
            display: flex;
            align-items: center;
            justify-content: center;

            @include media('<desktop') {
                font-size: 1.325rem;
            }

            padding: 0.445em;
            box-shadow: 0 0 toRem(1) 0 rgba($lighter, 0.85) inset, 0 0 toRem(1) 0 rgba($lighter, 0.85);
        }
        i {
            font-size: 0.9em;
        }
        ul {
            display: flex;
            justify-content: space-between;
            @include media('<desktop') {
                justify-content: center;
            }
        }

        li {
            @include media('<desktop') {
                margin: 0 1.5vw;
            }
        }
    }
    &__accordion {
        @include media('<desktop') {
            display: none;
            background: $dark--2;
            padding: 1rem;
            margin: 0 #{math.div($grid-gutter-width, -2)};
        }
        li {
            padding: 0.313rem 0;
        }
    }

    &__accordion-title {
        @include media('<desktop') {
            font-size: inherit;
            display: flex;
            justify-content: space-between;
            margin: 0;
            padding: 1rem 0;
            &:after {
                font-family: icomoon !important;
                content: '\e90b';
            }
            .js--target-shown & {
                &:after {
                    content: '\e90c';
                }
            }
        }
    }

    /* FACETS styling */
    &--facets {
        background: $darker left center / auto auto no-repeat url('../../../images/polygons--rightward.png');
        @include media('<tablet') {
            background: $darker top center / auto auto no-repeat url('../../../images/polygons--downwards.png');
            #{$footer}__logo {
                text-align: center;
            }
        }
        .container {
            background: right bottom / toRem(440) auto no-repeat url('../../../images/facets-logo-opaque.svg');
            @include media('<tablet') {
                background-size: 100% auto;
            }
        }
        @include media('>tablet') {
            #{$footer}__logo {
                padding-top: 0;
                margin-left: 0;
                margin-top: 0;
            }
        }
    }
}

.sitemap {
    display: flex;
    height: 100%;

    @include media('<desktop') {
        display: block;
    }
    &__column {
        @include media('>desktop') {
            padding: 0 2rem;
            & + .sitemap__column {
                border-left: toRem(1) solid rgba($lighter, 0.2);
            }
        }

        @include media('<desktop') {
            border: none;
        }

        & > p {
            color: inherit;
            font-size: inherit;
            margin: 0;
            font-weight: 600;
            padding: 1rem 0;
            @include media('<desktop') {
                border-top: toRem(1) solid rgba($lighter, 0.2);
                padding: 1rem;
                margin-left: -#{$grid-gutter-width * 0.5};
                margin-right: -#{$grid-gutter-width * 0.5};
            }
        }
    }
}

.cl-footer {
    background: var(--journal-color);
    color: $lighter;
    padding: 0.325rem 0;
    .container {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        justify-content: space-between;
        &:after,
        &:before {
            content: none;
        }
    }
    font-size: 0.75rem;
    &--dark {
        color: $darker;
    }
    &__item {
        padding: 0.325rem;
    }
    &__cc {
        flex: 1;
    }
    img {
        display: block;
    }
    @include media('<tablet') {
        .container {
            justify-content: flex-start;
        }
        &__logo {
            flex: 1;
        }
        &__cc {
            text-align: center;
            border-top: toRem(1) solid rgba($lighter, 0.3);
            order: 3;
            flex: 1 0 100%;
        }
    }
}
