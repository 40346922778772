.co-branding {
    .jcf {
        &-file {
            border-radius: toRem(4);
        }

        &-upload-button {
            border-bottom-right-radius: toRem(4);
            border-top-right-radius: toRem(4);
        }
    }

    .btn {
        background-color: var(--secondary-color);
        color: $white;

        &:hover {
            text-decoration: none;
        }
    }
}
