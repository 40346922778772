.news {
    $this: &;
    position: relative;
    color: $lighter;
    background: $darker;

    &__container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        grid-template-areas: 'big small1' 'big small2';
        @include media('<tablet') {
            display: block;
        }
    }

    margin: 2rem 0 0;

    &__label {
        top: 0;
        left: 0;
        z-index: map-get($z-index, 'news-label');
        padding: 0.56em 0.5em 0.56em 1em;
        font-weight: bold;
        position: absolute;
        line-height: 1.6;
        font-size: 1.125rem;
        background: var(--journal-color);
        &:after {
            content: '';
            position: absolute;
            left: 100%;
            top: 0;
            border-left: 2.05em solid var(--journal-color);
            border-bottom: 2.66em solid transparent;
        }
    }

    &__item {
        &:nth-child(2) {
            grid-area: small1;
        }
        &:nth-child(3) {
            grid-area: small2;
        }
        border-right: toRem(1) solid rgba($border-color, 0.4);
        border-bottom: toRem(1) solid rgba($border-color, 0.4);
        padding: 2.375rem;
        position: relative;
        z-index: map-get($z-index, 'news-item');

        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            z-index: map-get($z-index, 'news-item-after');

            background: $darker url('../../../images/brand-polygon-symbol--solid-color.svg') calc(100% - 1.375rem)
                calc(100% - 1.375rem) / auto toRem(156) no-repeat;
        }
        @include media('>large') {
            padding: 2.97vw;
        }
        @include media('<tablet') {
            padding-left: 1rem;
            padding-right: 1rem;
        }

        &:first-child {
            grid-area: big;
            background: $darker url('../../../images/brand-polygon-symbol--solid-color.svg') calc(100% - 1.375rem)
                calc(100% - 1.375rem) / auto toRem(156) no-repeat;
            &:after {
                content: none;
            }
            padding-top: 4.536rem;
            @include media('>large') {
                padding-top: 5.67vw;
            }
            grid-row-start: 1;
            grid-row-end: 3;
            #{$this}__text {
                display: block;
            }
        }
    }

    &__title {
        line-clamp: 3;
    }

    &__date {
        font-size: 0.75rem;
        margin-bottom: 0.83em;
    }

    &__pretext {
        font-size: 0.75rem;
        font-weight: bold;
    }

    &__text {
        opacity: 0.8;
        font-size: 0.875rem;
        margin: 1.5rem 0;
        & + .btn {
            margin-top: 1rem;
        }
    }

    h3 {
        color: inherit;
    }
}
