/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on July 25, 2019 */


@font-face {
    font-family: 'Montserrat';
    src: url('./montserrat-mediumitalic.woff2') format('woff2'),
         url('./montserrat-mediumitalic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Montserrat';
    src: url('./montserrat-bold.woff2') format('woff2'),
    url('./montserrat-bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('./montserrat-semibold.woff2') format('woff2'),
    url('./montserrat-semibold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}

//@font-face {
//    font-family: 'Montserrat';
//    src: url('../fonts/montserrat/montserrat-medium.woff2') format('woff2'),
//    url('../fonts/montserrat/montserrat-medium.woff') format('woff');
//    font-weight: 500;
//    font-style: normal;
//}

@font-face {
    font-family: 'Montserrat';
    src: url('./montserrat-regular.woff2') format('woff2'),
    url('./montserrat-regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}
