.alert {
    $this: &;

    &__content {
        .profile__heading {
            @include font-size(14);
            color: $primary;
            text-transform: capitalize;
        }
    }

    &__receive {
        @include font-size(14);
    }

    &__freq {
        .left-side-label {
            select {
                width: 50%;
            }

            label {
                width: 100%;
            }
        }
    }

    &__format {
        .left-side-label {
            label {
                margin-right: 3.5rem;
            }
        }
    }
}
