.ad-banner {
    $this: &;

    &__wrapper {
        color: $lighter;
        display: inline-grid;
        grid-template-columns: auto auto;
        grid-template-rows: auto auto;
        padding: 1.875rem 3.185rem 0 2.875rem;
        background: var(--journal-color) url('../../../images/brand-polygon-symbol.svg') calc(100% - 1.375rem)
            calc(100% + 2.875rem) no-repeat;
        background-size: 19.275rem auto;
        overflow: hidden;
        position: relative;

        @include media('<desktop') {
            background-size: 18.675rem;
            background-position: calc(100% - 2.775rem) top;
            padding: 1rem 3.5rem 0 1.5rem;
        }
        @include media('<tablet') {
            background-size: 21.625rem;
            background-position: calc(100% + 0.625rem) calc(100% - 2rem);
            padding: 1.5rem 1rem 0;
            grid-template-columns: auto;
        }

        &--aside {
            background-color: $dark--2;
            background-position: calc(100% - 1rem) calc(100% - 0.625rem);
            background-size: auto 19.25rem;
            grid-template-columns: auto;
            padding: 2rem 2rem 0;
            height: 100%;
            max-height: 32.125rem;

            @include media('<tablet') {
                background-size: 21.625rem;
                background-position: calc(100% + 0.625rem) calc(100% - 2rem);
                padding: 2rem 1.5rem 0;
                max-height: 100%;
            }

            #{$this}__cover {
                grid-row: 3;
                justify-content: flex-end;
                align-items: flex-end;

                @include media('<tablet') {
                    justify-content: center;
                }

                &__image {
                    transform: translateX(3.625rem);
                    height: 11.5rem;
                    box-shadow: toRem(10) toRem(30) toRem(10) 0 rgba($shadow-color, 0.8);
                    @include media('<tablet') {
                        transform: translateX(2rem);
                    }

                    &:last-child {
                        transform: translate(0, 1.625rem);
                        @include media('<tablet') {
                            transform: translate(-2rem, 1.625rem);
                        }
                    }
                }
            }

            #{$this}__title {
                line-height: 2.375rem;
                @include media('<tablet') {
                    line-height: 1.75rem;
                }
            }

            #{$this}__content {
                padding-right: 2rem;
                @include media('<tablet') {
                    padding-right: 0;
                    .btn {
                        margin-bottom: 2.875rem;
                    }
                }
            }

            #{$this}__description {
                margin-bottom: 2rem;
            }
        }
    }

    &__content {
        grid-column: 1;
        grid-row: 2;
        padding-right: 5.625rem;
        @include media('<tablet') {
            width: 100%;
            padding: 0;
        }

        .btn {
            background-color: $lighter;
            padding: 0.563rem 3.735rem;
            color: var(--journal-color);
            margin-bottom: 2.125rem;

            &:hover,
            &:focus {
                background-color: darken($lighter, 20%);
            }

            @include media('<desktop') {
                margin-bottom: 2.625rem;
            }
            @include media('<tablet') {
                width: 100%;
                padding: 0.75rem 3.6rem;
                margin-bottom: 3.125rem;
            }
        }
    }

    &__title {
        grid-row: 1;
        color: inherit;
        font-weight: normal;
        margin: 0;
        @include media('<large') {
            grid-column: 1/3;
        }
        @include media('<tablet') {
            grid-column: 1;
            @include font-size(24);
        }
    }

    &__description {
        @include font-size(14);
        margin: 1rem 0 1.375rem;
        padding-right: 0;
        line-height: toRem(22);

        p {
            margin: 0;
        }

        @include media('<large') {
            margin-bottom: 1.875rem;
        }
        @include media('<tablet') {
            margin-bottom: 2rem;
        }
    }

    &__cover {
        display: flex;
        grid-row: 1/3;
        @include media('<large') {
            grid-row: 2/3;
            align-items: flex-end;
            margin-top: 1.875rem;
            margin-left: -2.813rem;
        }
        @include media('<tablet') {
            justify-content: center;
            grid-column: 1;
            grid-row: 3;
            margin: 0;
        }

        &__image {
            object-fit: cover;
            height: 13.25rem;
            z-index: map-get($z-index, 'ad-banner-img');
            box-shadow: toRem(20) toRem(40) toRem(10) rgba($darker, 0.8);
            @include media('<desktop') {
                height: 12.625rem;
                box-shadow: toRem(20) toRem(56) toRem(10) rgba($darker, 0.8);
            }
            @include media('<tablet') {
                height: 11.125rem;
                transform: translateX(1.5rem);
            }

            &:last-child {
                z-index: map-get($z-index, 'ad-banner-child');
                transform: translate(-4rem, 2.315rem);
                transform-origin: bottom;
                box-shadow: none;
                @include media('<desktop') {
                    transform: translate(-2.625rem, 3.375rem);
                }
                @include media('<tablet') {
                    transform: translate(-1.5rem, 1.625rem);
                }
            }
        }
    }
}
