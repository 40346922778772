.mobile-panel {
    &__header {
        background: $darker;
        color: $lighter;
        font-weight: bold;
        display: flex;
        border-bottom: toRem(4) solid $secondary;
        align-items: center;
        &__text {
            padding: 1rem;
        }
        &__close {
            padding: 1rem 1.2rem;
            border-right: toRem(1) solid $dark--2;
        }
    }
}
