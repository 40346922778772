/* ==== FACETS Styling ==== */

.fresh-pub {
    background: $lighter;
    box-shadow: 0 toRem(2) toRem(4) rgba($darker, 0.1);
    border: toRem(1) solid $dark--4;
    max-width: toRem(920);
    margin: 0 auto;

    @include media('<phone') {
        word-break: break-word;
    }

    &__item {
        $item: &;
        & + & {
            border-top: toRem(1) solid $border-color;
        }
        padding: 1.5rem;
        font-size: 0.75rem;

        display: grid;
        grid-template: auto auto auto auto / toRem(120) auto;
        grid-column-gap: 1.5rem;
        grid-template-areas: 'cover header' 'cover title' 'cover contribs' 'cover relations';

        &__content {
            flex: 1;
        }

        .loa {
            li {
                margin-right: 0.3em;
            }
        }

        & > a {
            display: block; // IE11 won't render grid correctly with default inline elements
        }

        &__header {
            grid-area: header;
            display: flex;
            align-items: center;
            margin-bottom: 0.75rem;

            & > div {
                @include media('<phone') {
                    margin-bottom: 0.25rem;
                }
                & + div {
                    @include media('>=phone') {
                        border-left: toRem(1) solid $border-color;
                        margin-left: 0.5em;
                        padding-left: 0.5em;
                    }
                }
            }

            @include media('<phone') {
                align-items: flex-start;
                flex-flow: column;
            }
        }

        &__image {
            grid-area: cover;
            //grid-row: 1 / 10;
            //grid-column: auto;
            width: 100%;
            position: relative;
            background: center / cover no-repeat;
            align-self: flex-start;
            border: toRem(1) solid $dark--7;
            @include media('<tablet') {
                margin: 0 0.625rem 0.625rem 0;
                min-width: 5rem;
            }
            @include media('<phone') {
                /* autoprefixer: off */
                grid-row: 1 / 3;
                & ~ #{$item}__relations,
                & ~ #{$item}__contribs {
                    grid-column: span 2;
                }
                /* autoprefixer: on */
            }
            &:after {
                content: '';
                display: block;
                position: relative;
                width: 100%;
                padding-top: 120%;
            }
            //& ~ div,
            //& ~ ul,
            //& ~ h3,
            //& ~ h4 {
            //    grid-column: auto;
            //}
        }

        &__meta {
            margin-top: 0.875rem;
            span + span:before {
                content: '•';
                font-size: 2.3em;
                margin: 0 0.2em;
                line-height: 0;
                vertical-align: sub;
            }
        }
        &__relations {
            grid-area: relations;
            margin-top: 0.8rem;
            i,
            a {
                vertical-align: middle;
            }
            i {
                font-size: 1.2em;
            }
            a {
                text-decoration: underline;
                & + a {
                    &:before {
                        content: '|';
                        opacity: 0.5;
                        margin: 0 0.6em;
                    }
                }
            }
        }
        &__title {
            h4 {
                color: inherit;
                margin-bottom: 0;
            }
            grid-area: title;
            color: inherit;
            margin-bottom: 0.6rem;
            /* autoprefixer: off */
            @include media('<tablet') {
                font-size: 0.875rem;
                & ~ div {
                    grid-column: 1 / span 2;
                }
            }
            /* autoprefixer: on */
        }
        &__type {
            color: $dark--5;
            i {
                font-size: 1.3em;
                vertical-align: text-top;
            }
        }
        &__contribs {
            grid-area: contribs;
            color: $link-color;
        }
        &__actions {
            color: $facets;
            font-weight: bold;
            text-decoration: none;
            text-transform: uppercase;
            margin-top: 1.125rem;
            a {
                margin-right: 0.5em;
            }
        }
        i {
            margin-right: 0.5em;
        }
        .fresh-pub__item__title i {
            margin-right: 0;
        }
    }

    &__footer {
        text-align: center;
        @include media('<tablet') {
            margin-bottom: 1rem;
        }
        &:not(:empty) {
            margin-top: 2rem;
        }
        .btn {
            padding-top: toRem(13);
            padding-bottom: toRem(13);
            border-color: $darker;
            color: $darker;
            width: 21.875rem;
            max-width: 100%;
            &:hover {
                background: $darker;
                color: $lighter;
            }
        }
    }
}
