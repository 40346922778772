.journal-logo-card {
    margin: 0;
    background: rgba($lighter, 0.3);
}

.card {
    &__title {
        .trending-research & {
            padding-top: 0.8rem;
        }
    }
}
