.clients-journals {
    display: flex;
    flex-flow: row wrap;
    font-size: 0.875rem;
    justify-content: space-between;
    &__title {
        margin-top: 3rem;
    }
    &__item {
        background: $lighter;
        border-radius: toRem(8);
        padding: 0.625rem 1rem;
        border: toRem(1) solid $dark--4;
        box-shadow: 0 toRem(2) toRem(4) rgba($darker, 0.1);
        margin-bottom: 1rem;
        flex: 1 0 50%;
        max-width: calc(50% - 0.875rem);
        @include media('<phone') {
            max-width: 100%;
            flex-basis: auto;
        }
        display: flex;
        align-items: center;

        &__cover {
            max-width: 3.3em;
            margin-right: 1rem;
        }
        &__title {
            flex: 1;
        }
        .icon-arrow_right {
            color: $border-color;
            font-size: 1.2em;
            margin-left: 0.5em;
        }
    }
}
