.advanced-search-tabs {
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;

    .tab {
        &__nav {
            padding: 0 0.813rem;

            a {
                padding: 1.188rem 0;
            }

            li {
                padding: 0 0.5rem;
                font-size: 1rem;
                line-height: 1.25;
                flex: 0 auto;
            }

            span {
                line-height: inherit;
            }
        }

        &__pane {
            padding-left: 1.5rem;
            padding-right: 1.5rem;
        }
    }
}

.journal-search,
.quick-search--in-header {
    .autoComplete {
        top: 30%;
        background-color: $white;
        width: 60%;
        padding-left: 1rem;
        padding-right: 1rem;

        @include media('<tablet') {
            top: 13%;
        }

        li {
            border: none;
        }

        .autoComplete__category {
            display: none;
        }

        .autoComplete__text {
            color: $theme-text-dark;
        }

        .single_highlight_class {
            color: $secondary;
            font-weight: 700;
        }

        .autoComplete__link--grid {
            flex-direction: column-reverse;
            padding-left: toRem(8);
            padding-right: toRem(8);

            &:hover {
                background: $quick-search-hover;
                font-weight: 400;
            }
        }

        .autoComplete__item:not(:first-child) {
            border-top: 0.063rem solid $dark--9;
        }
    }
}

.quick-search--in-header {
    .autoComplete {
        width: 100%;
        top: 100%;
    }
}

.advanced-search {
    &--filters {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        margin-left: -1.5rem;
        margin-right: -1.5rem;
    }

    [class*='-ctrl-field'] {
        color: $text--secondary;
        font-size: 1.3rem;
        margin-left: 1.125rem;

        @include media('<tablet') {
            margin-left: 0.5rem;
        }
    }

    label {
        color: $dark--2;
    }

    &__search-in__field,
    &__search-in__term {
        @include media('<tablet') {
            gap: unset;
        }
    }

    &-btn {
        @include media('>=tablet') {
            width: unset;
        }
    }

    .search__required {
        &__icon {
            position: static;
            margin-top: toRem(-10);

            @include media('>=tablet') {
                margin: toRem(-10) 0 0 toRem(-12);
            }
        }
    }

    &__search-in__area {
        color: $dark--2;
    }

    &__search-in__add,
    &__search-in__remove {
        margin-left: toRem(7);
    }

    &__search-in__term:has(span) {
        .advanced-search__search-in__add,
        .advanced-search__search-in__remove {
            margin-left: 0;
        }
    }

    .timeframe {
        &__static-range {
            @include media('>=tablet') {
                margin-bottom: 2rem;
            }

            &__select {
                min-width: toRem(145);
            }
        }
    }

    &__timeframe {
        margin-top: toRem(38);
        margin-bottom: toRem(6);

        @include media('>=tablet') {
            margin-bottom: toRem(30);
        }

        &__item {
            select[disabled] {
                color: $dark--2;
            }
        }
    }

    &__tips {
        @include media('<desktop') {
            margin-top: 2rem;
        }

        max-height: 100%;
        font-size: 0.875rem;
        color: $text--secondary;
        position: relative;
        padding-bottom: 4rem;

        h6 {
            margin-top: 1.8rem;
        }

        &__trigger {
            max-height: 4rem;
            background: $dark--3;
            text-align: center;
            padding: 1rem;
            font-size: 0.75rem;
            font-weight: bold;
            position: absolute;
            cursor: pointer;
            bottom: 0;
            left: 0;
            right: 0;

            &:before {
                content: '';
                position: absolute;
                width: 100%;
                height: 0;
                left: 0;
                padding-top: 20%;
                bottom: 100%;
                background: linear-gradient(0deg, $white 0%, rgba(255, 255, 255, 0) 100%);
            }

            .js--subdue-sidebar-height--shown & {
                &:before {
                    display: none;
                }
            }
        }

        .tips {
            h2 {
                font-size: 1.5rem;
                margin-top: 0;
                margin-bottom: 0.938rem;
                color: $black;
                font-weight: 700;
            }

            .h6-small {
                margin-bottom: 0.938rem;
                color: $dark--5;
            }
        }
    }

    .autoComplete {
        &__item {
            color: $dark--5;
            margin-bottom: 0 !important;
            margin-top: 0 !important;
        }

        .single_highlight_class {
            font-weight: bolder;
            color: $black;
        }
    }
}

.history-headers {
    font-size: 0.875rem;

    th {
        border-bottom-width: toRem(4);
    }
}

.citationSearchContainer {
    .autoComplete {
        display: none;
    }
}

.search-history {
    font-size: 0.75rem;

    td {
        padding: 1.5rem 1em 1.5rem 0;
    }
}

.saved-searches {
    .table__action {
        padding: 0;
    }

    td {
        padding: 1.875rem 0;

        &.action {
            a {
                padding-right: 0.2rem;

                @include media('<tablet') {
                    i {
                        font-size: 1.2em;
                    }
                }
            }

            + .action {
                a {
                    padding-left: 0.75rem;
                    padding-right: 0;
                    border-left: toRem(1) solid $border-color;
                    border-radius: 0;
                }
            }
        }
    }
}

.js--subdue-sidebar-height {
    overflow: hidden;
    transition: 0.35s ease max-height;
}

.js--release-sidebar {
    display: flex;
    align-items: center;
    justify-content: center;
    $this: &;

    &__close {
        display: none;
    }

    i {
        font-size: 2em;
    }

    .js--subdue-sidebar-height--shown & {
        #{$this}__close {
            display: inline-block;
        }

        #{$this}__open {
            display: none;
        }

        i {
            transform: scaleY(-1);
        }
    }
}

#advancedSearch {
    @include media('>=tablet') {
        margin-top: toRem(12);
    }
}
