$secondary: #b51218;

$body-bg: #f0f0f0;

$search-highlight: rgba(#f0f0f0, 0.3);

$dark--2: #333;

$dark--3: #fafafa;

$dark--4: #f0f0f0;

$dark--5: #666;

$dark--6: #bbb;

$dark--7: #9f9f9f;

$dark--8: #c3c3c3;

$dark--9: #d9d9d9;

$info-color: #004085;

$info-background: #cce5ff;

$info-border: #b8daff;

$orcid: #a6ce39;

$journal-color: #610519;

$discovery-style: #b40936;

$journals-list-background: #333437;

$primary-border-color: #d7d7d7;

$quick-search-hover: #007fff;

$quick-search-border: #303030;

$auxiliary: #397685;

$supplementary: #456dc5;

$theme-text-dark: #333;

$text-color: #707070;

$facebook: #3b5998;

$twitter: #1da1f2;

$linkedin: #007bb5;

$email: #707070;

$text--secondary: #707070;

$link-color: #456dc5;

$discovery: #3ebeb8;

$pdf: #eb5757;

$access: #66a22b;

$access--open: #f68212;

$access--free: #74b600;

$access--restricted: #ec2020;

$anc: #082334;

$apnm: #21123b;

$as: #071f36;

$bcb: #5e041a;

$cjb: #183c2c;

$cgj: #333437;

$cjas: #610519;

$cjc: #86517b;

$cjce: #373c4c;

$cjes: #051c36;

$cjfas: #1e2656;

$cjfr: #2a381c;

$cjm: #5e1822;

$cjp: #37142b;

$cjpp: #58221c;

$cjps: #344f24;

$cjss: #4f3817;

$cjz: #6b6128;

$er: #006454;

$facets: #b0132d;

$gen: #39111d;

$geomat: #827b6d;

$juvs: #a75225;

$tcsme: #7f7047;

// Client Journals:

$casi: #3d6fa5;

$casj: #3d6fa5;

$cjcmh: #204288;

$cjdpr: #780524;

$anr: #223d89;

$cnr: #223d89;

$ehr: #204288;

$lpsn: #1e1e52;

$sfj: #00274f;

$tfc: #4b3f26;

$dsa: #a75225;

$turquoise: #329893;

$do-link-color: #0003ff;

$icon-mail: #f8694c;

$white: #fff;

$owl-dot-color: blue;

$light-gray: #e5e5e5;

$quick-search-hover-bg: #003eff;

$slideshow-dots-color: #820000;
