.orderSummary {
    div {
        display: flex;
        justify-content: space-between;

        &:last-child {
            @include font-size(18);
        }
    }

    & > div:last-child {
        span {
            color: inherit;
        }
    }

    span {
        color: $dark;
        @include font-size(16);
        flex: 1;
        display: block;

        @include media('<tablet') {
            @include font-size(12);
        }

        & ~ .value {
            color: $primary;
            font-weight: 500;

            @include media('<tablet') {
                @include font-size(12);
            }
        }
    }

    .value {
        font-weight: bold;
        text-align: right;
        color: var(--secondary-color);
        @include font-size(16);
    }
}
