.facet {
    &__list {
        li {
            line-height: 1.2;
        }

        font-size: 0.875rem;
        font-weight: normal;

        &--applied {
            li {
                border-radius: toRem(4);
                font-weight: normal;

                i[class*='icon-'] {
                    padding-left: 1rem;
                    margin-left: 0.375rem;
                    line-height: 1.9;
                }
            }
        }
    }

    .show-more {
        text-transform: uppercase;
        font-size: 1rem;
        font-weight: 600;
    }

    &__subsection {
        font-size: 1.13rem;
        margin-top: 0;
        margin-bottom: 0.938rem;
        color: $black;
        font-weight: 700;
    }
}

.card,
.card--shadow {
    .facet:last-child {
        border-bottom: none;
        padding-bottom: 0;
        margin-bottom: 0;
    }
}
