.journal-info {
    background: $dark--3;
    border-bottom: toRem(1) solid $border-color;
    & + .container {
        margin-top: 2rem;
    }

    display: flex;
    justify-content: center;
    width: 100%;
    .current-issue {
        @include media('>desktop') {
            max-width: toRem(840);
        }
    }

    .dashboard {
        flex: 1;
    }

    @include media('<desktop') {
        flex-flow: column;
    }
    &__current {
        flex: 1;
        display: flex;
        justify-content: flex-end;
    }
}

.current-issue {
    $this: &;
    padding: 2rem;
    border-right: toRem(1) solid $border-color;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: auto 1fr;
    grid-template-areas: 'cover title' 'cover details';
    @include media('<tablet') {
        grid-template-areas: none;
    }

    @include media('<tablet') {
        padding: 1rem 1rem 2rem 1rem;
        text-align: center;
        grid-template-columns: auto;
    }

    &__details {
        grid-area: details;
        @include media('<tablet') {
            grid-area: auto;
        }
    }

    &__specifics {
        font-weight: bold;
        margin: 0 0 1.188rem 0;
        font-size: 1.125rem;
        span + span {
            &:before {
                content: '•';
                margin: 0 0.5em;
            }
        }
    }
    &__cover-large {
        &:hover {
            position: relative;
            &:after {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                background: rgba($darker, 0.3);
                right: 0;
                bottom: 0;
                z-index: map-get($z-index, 'cover-large');
            }
        }
    }
    &__cover {
        @include media('>=tablet') {
            //grid-row-start: 1;
            //grid-row-end: 3;
            padding: 0 2rem 0 0;
            grid-area: cover;
        }
        padding-bottom: 1.188rem;
        img {
            display: block;
            margin: auto;
        }
    }
    &__title {
        grid-area: title; // that's required for IE11 so not covered for mobile at all here
        font-size: 1.5rem;
        font-weight: bold;
        line-height: 1;
        margin: 0 0 1.188rem 0;
        @include media('<tablet') {
            grid-area: auto;
            text-align: left;
            grid-row-start: 1;
        }
    }
    hr {
        margin: 0.5rem 0;
        border-top: toRem(1) solid $border-color;
    }

    &__actions {
        margin: 1.188rem 0;

        @include media('>tablet') {
            white-space: nowrap;
        }

        a {
            & + a {
                margin-left: 0.5rem;
                @include media('<tablet') {
                    margin: 0.5rem 0 0;
                }
            }
            @include media('<tablet') {
                display: block;
            }
        }
    }
    &__description {
        margin-top: 0.75em;
        font-size: 0.875rem;

        @include media('<tablet') {
            text-align: left;
        }
    }

    &--sidebar {
        margin: -2rem 0 1rem;
        display: flex;
        flex-flow: column;
        text-align: center;
        padding: 1.5rem;
        position: relative;
        z-index: map-get($z-index, 'current-issue-sidebar');
        border: none;
        hr {
            margin-left: -1.5rem;
            margin-right: -1.5rem;
        }
        &:after {
            content: '';
            position: absolute;
            z-index: map-get($z-index, 'current-issue-sidebar-after');
            bottom: 0;
            top: 32%;
            left: 0;
            right: 0;
            background: $lighter;
            box-shadow: 0 toRem(2) toRem(2) rgba($darker, 0.15);
        }

        #{$this}__cover {
            grid-area: auto;
            padding: 0;
            margin-bottom: 1rem;
        }

        #{$this}__actions {
            display: flex;
            flex-flow: column;
            align-items: stretch;
            a + a {
                margin: 0.5rem 0 0 0;
            }
        }
        #{$this}__journal-actions {
            display: flex;
            .btn {
                color: $theme-text-dark;
                flex: 1;
                padding: 0.625rem;
                border-color: $border-color;
                &:hover {
                    color: $lighter;
                }
                & + .btn {
                    margin-left: 0.5rem;
                }
            }
        }
        @include media('<desktop') {
            background: $lighter;
            box-shadow: 0 toRem(2) toRem(2) rgba($darker, 0.15);
            margin-top: 0;
            &:after {
                content: none;
            }
        }

        @include media('<tablet') {
            #{$this}__journal-actions {
                flex-flow: column;
                .btn + .btn {
                    margin: 0.5rem 0 0;
                }
            }
        }

        @include media('<desktop', '>phone') {
            grid-template: auto auto / 1fr 1.5fr;
            hr {
                grid-column: 1 / 3;
            }
            #{$this}__cover {
                grid-row-end: 4;
                margin-right: 1.5rem;
            }
            #{$this}__logo {
                margin-bottom: 0;
            }
            #{$this}__specifics {
                align-self: end;
            }
        }
    }
    &__logo {
        margin: 0 auto 1.5rem;
        max-width: 80%;
    }
}

.dashboard {
    &__title {
        font-size: 1.25rem;
        color: $lighter;
        background: var(--journal-color) url('../../../images/gray-blaze.png') center toRem(-7) / auto auto repeat-x;
        line-height: 1.28;

        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1.375rem;
    }

    &__alerts {
        padding: 0.15em;
        font-size: 1.3em;
        line-height: 1;
        border: toRem(1) solid rgba($lighter, 0.85);
        border-radius: 50%;
        box-shadow: 0 0 toRem(1) 0 rgba($lighter, 0.85) inset, 0 0 toRem(1) 0 rgba($lighter, 0.85);

        &:hover {
            background: rgba($lighter, 0.3);
        }
    }
    &__data {
        padding: 1.5rem;
        @include media('>desktop') {
            max-width: toRem(520);
            min-width: toRem(390);
        }

        @include media('<tablet') {
            padding: 1rem;
        }
    }

    &__numbers-scroll {
        position: relative;
        @include media('<tablet') {
            margin: -1rem -1rem 0;
        }
    }
    &__numbers {
        display: flex;
        flex-flow: row wrap;
        @include media('<tablet') {
            flex-flow: row nowrap;
            overflow: auto;
            border-bottom: toRem(1) solid $border-color;
            margin-bottom: 1.625rem;
        }

        li {
            width: 50%;
            align-items: center;
            justify-content: flex-end;
            display: flex;
            color: $dark;

            margin: 0.5rem 0;
            span {
                font-size: 0.875rem;
                line-height: 1.3;
                flex: 1;

                &:first-child {
                    color: $darker;
                    text-align: right;
                    font-size: 1.563rem;
                    font-weight: bold;
                    margin-right: 0.5em;
                }
            }
            @include media('<tablet') {
                padding: 0 0.7rem;

                flex-flow: column;
                justify-content: flex-start;
                text-align: center;
                margin: 0.5rem 0;
                span {
                    &:first-child {
                        flex: 0;
                        margin-right: 0;
                    }
                }
            }
        }
    }
    &__interactions {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        text-align: center;
        @include media('<tablet') {
            flex-flow: column;
        }
        a {
            flex: 1;
            & + a {
                margin-top: 0.5rem;
            }
            @include media('>tablet') {
                & + a {
                    margin-right: 0.5rem;
                }
                &:first-child {
                    min-width: 100%;
                }
                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
}
