.search {
    .pagination {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &__header {
        font-size: 1.313rem;
        display: flex;
        align-items: center;
        flex-flow: row wrap;
        color: $dark--5;

        [data-db-target-for] {
            color: inherit;
        }
    }

    &__follow {
        display: flex;

        @include media('<phone') {
            flex: 1;
            justify-content: flex-end;
            margin-top: 0.5rem;
        }

        &__item {
            padding: 0 0.5em;
            border-left: toRem(1) solid $border-color;
        }

        a {
            vertical-align: middle;
        }

        .icon-heart-o {
            color: $secondary;
        }

        .icon-rss_feed {
            position: relative;
            top: -0.1em;
            font-size: 0.8em;
        }
    }

    &__accordion-ctrl {
        padding: 0;
        font-size: inherit;
        i {
            margin-left: 0.5em;
        }
    }

    &__sort {
        position: relative;
    }
    &__required__icon {
        position: absolute;
        padding-left: toRem(2);
        color: $secondary;
    }
    &__stats {
        @include media('<tablet') {
            font-size: 1.125rem;
        }
    }
    &__tools {
        font-size: 0.75rem;
        flex: 1 1 100%;
        display: flex;
        align-items: center;
        color: $secondary;
        flex-flow: row wrap;
        margin: 1.5rem 0;

        @include media('<phone') {
            margin: 0.8rem 0;
        }
        .result {
            flex: 1;
        }
        .dropBlock [data-db-target-for] ~ .dropBlock__holder {
            min-width: 100%;
            width: auto;
            right: 0;
            li a {
                white-space: nowrap;
            }
        }
    }

    &__refine {
        i {
            display: inline-block;
        }
        &.js--open {
            i {
                transform: rotate(180deg);
            }
        }
    }

    &__nav {
        /* template on ux3 level needs to be adjusted to set the proper class */
        display: flex;
        background: $dark--4;
        border-top: toRem(1) solid $border-color;
        border-bottom: toRem(1) solid $border-color;
        font-size: 0.875rem;
        font-weight: bold;
        color: $text--secondary;
        text-transform: uppercase;
        position: relative;
        .js--indicate-inner-scroll {
            // intentionally applied on a trigger class to make that css work only in case the scroll indication plugin is used
            display: flex;
            overflow: auto;
        }
        &__item {
            padding: 1rem 0.2rem;
            margin: 0 1rem;
            &:hover,
            &:focus {
                background: rgba($darker, 0.08);
            }
            @include media('<tablet') {
                padding: 1rem 1.5rem;
                margin: 0;
            }
            &.active {
                color: $secondary;
                border-bottom: toRem(4) solid $secondary;
                padding-bottom: calc(1rem - 0.25rem);
            }
        }
        @include media('<tablet') {
            margin: 0 -#{$grid-gutter-width * 0.5};
            padding: 0 #{$grid-gutter-width * 0.5};
        }
    }
}

.per-page {
    color: $theme-text-dark;
    margin-right: 1rem;

    .js--selected {
        font-weight: 600;
        color: $secondary;
    }
    text-transform: uppercase;
    li {
        margin-left: 0.35rem;
    }
    ul {
        display: inline-block;
    }
}

.advanced-search {
    &__tabs {
        display: none;
        min-width: 100%;
    }
}
.advancedSearch__tabs {
    .tab__nav li {
        font-size: 1rem;
    }
}

/*
Why do we have .search, .result, .search__result ? Consider cleanup
*/
.result {
    &__suffix,
    &__current,
    &__count {
        font-weight: 600;
        color: $darker;
    }
    & > span {
        margin-right: 0.5em;
    }

    &__ctrl__filters {
        flex: 1;
        font-size: 0.875rem;
        i {
            margin-right: 0.5rem;
        }
        border-radius: toRem(4);
        background: $darker;
        padding: 1rem;
        margin-top: 1.5rem;
        min-width: 100%;
        color: $lighter;
    }
}

.pagination {
    margin-top: 2.625rem;
    margin-bottom: 1.25rem;
    font-size: 0.75rem;

    @include media('<desktop') {
        margin-bottom: 2.125rem;
    }

    justify-content: center;
    display: flex;
    align-items: center;

    @include media('<tablet') {
        margin-bottom: 1.125rem;
    }

    &__btn {
        color: $text-color;
        margin: 0 toRem(8);
    }

    .disabled {
        color: $search-arrow-disabled;
    }

    &__list {
        a {
            color: $text-color;
            font-weight: 500;
            padding: 0 1rem;

            &.active {
                color: $theme-text-light;
                background: $search-pager-active-color;
                @include brand('background-color');
                border-radius: toRem(4);
                padding: toRem(3) toRem(7);
                min-width: 2em;
                text-align: center;
            }

            &:hover,
            &:focus {
                color: $hover-darker-color;
            }
        }

        li {
            &:first-child {
                .active {
                    margin-left: 1rem;
                }
            }
        }
    }
}

#filter {
    @include media('<tablet') {
        position: fixed;
        height: 100%;
        overflow: hidden;
        z-index: map-get($z-index, 'filter');
        left: 0;
        top: 0;
        transform: translateX(-100%);
        transition: transform 0.3s ease, width 0s linear 0.3s;

        &.js--shown {
            width: 100%;
            transform: translateX(0);
            transition: 0.3s ease transform;
            overflow: auto;
        }
    }
}

.single_highlight_class {
    background-color: $primary-border-color;
    display: inline-block;
}

#saveSearchDialog {
    .modal__header h2 {
        text-align: left;
        margin: toRem(10) 0;
        font-weight: 500;
    }

    fieldset {
        padding: 0;
        border: none;
        margin: 0;
    }
}
