.facets-subjects {
    $this: &;
    background: $lighter;
    display: flex;
    flex-flow: row wrap;
    text-align: center;
    border-right: toRem(1) solid $dark--4;
    border-bottom: toRem(1) solid $dark--4;
    font-size: 0.875rem;
    margin-top: -6.75rem;
    margin-bottom: 6.125rem;

    &--free {
        #{$this}__group {
            min-height: 0;
        }
        #{$this}__title {
            margin-bottom: 0.5em;
            display: inline-flex;
            align-items: center;
            img + span {
                margin-left: 0.5em;
            }
            @include media('<phone') {
                flex-flow: column;
                img + span {
                    margin-left: 0;
                    margin-top: 0.5em;
                }
            }
        }
    }

    &__group {
        padding: 1.5rem 1.25rem;
        flex: 1;
        min-width: 33.3%;
        border-left: toRem(1) solid $dark--4;
        border-top: toRem(1) solid $dark--4;
        min-height: 23.125rem;
        @include media('<desktop') {
            min-width: 100%;
            min-height: 0;
        }
    }
    li {
        margin: 0.5rem;
        color: $dark--5;
    }
    &__list {
        a {
            color: $link-color;
            &:hover {
                text-decoration: underline;
            }
        }
    }
    &__cta-banner {
        flex: 3;
        min-height: 0;
        padding: 0;
    }
    &__title {
        font-size: 1.25rem;
        font-weight: normal;
        margin-bottom: 3.25rem;
    }
}
