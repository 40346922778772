.dropzone {
    min-height: toRem(73);
}
.header {
    $header: &;
    position: relative;

    @include media('>=desktop') {
        position: relative;
        z-index: map-get($z-index, 'header');
    }

    .main-nav {
        @include media('>=tablet') {
            display: block;
        }
    }
    &--journal {
        background: $header-bg;
        z-index: map-get($z-index, 'header-journal');

        .main-nav {
            flex: 1 0 auto;
        }

        #{$header}__mobile {
            @include media('<desktop') {
                padding-right: 1rem;
                background: $header-bg;
                border-bottom: toRem(1) solid rgba($dark, 0.5);
                //&__buttons {
                //    padding-right: 1rem;
                //}
            }
        }

        @include media('>=desktop') {
            margin-bottom: var(--header-height);
        }

        @include media('<desktop') {
            z-index: map-get($z-index, 'header-mobile');
        }
    }

    &__row {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        @include media('<desktop') {
            flex-flow: column nowrap;
        }
    }

    &__top-menu {
        display: flex;
        flex-flow: row nowrap;
        font-weight: bold;
        align-items: center;
        font-size: 0.688rem;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        @include media('<desktop') {
            flex-flow: column nowrap;
            order: 1;
        }
        a {
            padding: 0.5rem 0.8rem;
            font-size: inherit;
        }
        .search-trigger {
            @include media('>=desktop') {
                font-size: 1.5em;
            }
        }

        #{$header}--journal & {
            justify-content: space-between;
            &__generic-collapsed {
                display: flex;
            }
        }
    }

    &__bottom-menu {
        font-weight: bold;
        letter-spacing: 0.1em;
        @include media('>desktop') {
            margin-top: 0.75rem;
        }
        @include media('<desktop') {
            padding-bottom: 1.5rem;
        }

        .top-banner--journal-page & {
            border-bottom: toRem(1) solid rgba($lighter, 0.2);
        }

        #{$header}--journal & {
            margin-top: 0;
            display: flex;
            justify-content: space-between;
            @include media('>=desktop') {
                position: absolute;
                width: 100%;
                right: 0;
                top: 100%;
                height: 100%;
            }
            @include media('<desktop') {
                flex: 1 0 100%;
                order: 1;
            }
        }
        a {
            font-size: 0.875rem;
            padding: 0.75rem 1rem;
        }
        .menubar {
            justify-content: flex-end;
            height: 100%;
        }
        .main-nav {
            display: block;
            a {
                height: auto;
                @include media('>desktop') {
                    display: flex;
                    align-items: center;
                }
            }
            #{$header}--non-journal {
                @include media('>=desktop') {
                    padding-right: 2.3rem;
                }
            }
        }

        #{$header}__journal-title {
            font-weight: normal;
            display: none;
            align-items: center;
            @include media('>=desktop') {
                padding: 0 1rem;
                display: flex;
            }
            a {
                line-height: 1.3;
                display: inline-block;
                @include media('<large') {
                    font-size: 0.9vw;
                }
                padding-left: 0;
            }
        }

        .dropdown {
            $dropdown: &;
            &__toggle {
                position: relative;
                .icon-triangle-bottom {
                    @include media('>=desktop') {
                        position: absolute;
                        top: 50%;
                        transform: translate(-50%, calc(-50% + 0.55em));
                        left: 50%;
                    }
                }
            }
            @include media('>=desktop') {
                &:hover,
                &:focus {
                    & > .dropdown__toggle {
                        .icon-triangle-bottom {
                            transform: scaleY(-1) translateX(-50%);
                        }
                    }
                }
            }
        }
    }

    [data-db-target-for] {
        color: inherit;
        font-weight: inherit;
    }

    .menubar {
        display: flex;
        flex-flow: row nowrap;
        @include media('<desktop') {
            flex-flow: column nowrap;
            .menu-parent,
            .menu-item {
                display: block;
            }
            .menu-parent {
                &::before {
                    background: unset;
                }
            }
        }
        .openList.dropdown {
            .dropdown__menu {
                display: block;
            }
            i {
                transform: rotate(180deg);
            }
        }
    }

    &--non-journal {
        padding: 1rem;
        .dropdown {
            &.hover,
            &:hover,
            &:focus {
                color: $light;
            }
        }

        #{$header}__col.menu {
            display: flex;
            flex-flow: row wrap;
            justify-content: flex-end;

            @include media('<desktop') {
                flex-flow: column nowrap;
                justify-content: center;
            }
            .main-nav {
                display: block;
            }
        }

        #{$header}__bottom-menu {
            flex: 1 0 100%;
        }

        @include media('<desktop') {
            #{$header}__bottom-menu {
                order: 1;
            }
        }
    }
    &--fixed {
        position: sticky;
        top: 0;
    }

    &__mobile {
        @include media('<desktop') {
            position: relative;
            z-index: map-get($z-index, 'header-nonJournal-mobile');
            display: flex;
            justify-content: space-between;
            font-size: 1.2em;

            &__buttons {
                display: flex;
                align-items: center;
                a {
                    border-radius: 51%;
                    display: inline-block;
                    position: relative;
                    line-height: 1;
                    .top-banner &:focus {
                        box-shadow: none;
                        &:after {
                            opacity: 0.15;
                            transform: translate(-50%, -50%) scale(1);
                        }
                    }
                    i {
                        padding: 0.5rem;
                    }
                    .icon-close {
                        display: none;
                    }
                    &.js--target-shown {
                        i {
                            display: none;
                        }
                        .icon-close {
                            font-size: 0.9em;
                            display: block;
                        }
                        &:after {
                            opacity: 0.5;
                            transform: translate(-50%, -50%) scale(1);
                        }
                    }
                    &:after {
                        content: '';
                        left: 50%;
                        top: 50%;
                        position: absolute;
                        z-index: map-get($z-index, 'header-mobile-btn');
                        transform-origin: 50% 50%;
                        background: $lighter;
                        opacity: 0;
                        border-radius: 80%;
                        width: 120%;
                        padding-top: 120%;
                        transform: translate(-50%, -50%) scale(0.2);
                        transition: transform 0.2s ease, opacity 0.2s;
                    }

                    & + a {
                        margin-left: 1rem;
                    }
                }
            }
        }
    }
    &__logo {
        display: block;
        img {
            display: block;
        }
        @include media('<tablet') {
            max-width: 45%;
        }
        #{$header}--journal & {
            padding: 0.1rem 0.4rem 0;
            max-width: none;
            display: flex;
            align-items: center;
            @include media('<desktop') {
                font-size: 0.75em;
            }
        }
    }

    .generic-menu,
    .main-nav {
        // not to disrupt flex in header menus
        @include media('>=desktop') {
            width: auto;
        }
    }

    .dropdown {
        @include media('<desktop') {
            &.hover,
            &:hover,
            &:focus {
                & > .dropdown__menu {
                    display: none;
                }
                a {
                    color: $white;
                }
            }
            &.menu-parent__opened {
                & > .dropdown__menu {
                    display: block;
                }
            }
        }
        &__menu {
            @include media('>=desktop') {
                right: 0;
                left: auto;
            }
            @include media('<desktop') {
                position: relative;
                float: none;
                background: unset;
                text-align: center;
                border-top: toRem(1) solid $dark;
                top: auto;

                font-size: 0.875rem;
                font-weight: normal;
                line-height: 2.5;
                a {
                    text-transform: capitalize;
                    color: inherit;
                }
            }
        }

        @include media('<desktop') {
            &__toggle {
                justify-content: center;
            }
        }
    }

    &__journal-title {
        &--mobile {
            @include media('>=desktop') {
                display: none;
            }
            @include media('<desktop') {
                z-index: map-get($z-index, 'journal-title');
                padding: 1rem;
                border-bottom: toRem(1) solid rgba($lighter, 0.2);
                font-size: 0.75rem;
            }
        }
    }

    /* ==== FACETS styling ==== */
    &--facets-generic {
        background: transparent;
        #{$header}__bottom-menu {
            border-bottom: toRem(1) solid rgba($lighter, 0.2);
        }
        #{$header}__logo {
            padding: 0.75rem 1.25rem;
        }
        #{$header}__mobile {
            @include media('<desktop') {
                background: transparent;
            }
        }
    }
}

.menu {
    .header--journal & {
        display: flex;
        @include media('<desktop') {
            flex-flow: column;
        }
        justify-content: flex-end;
    }
    @include media('<desktop') {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        max-height: 0;
        overflow: hidden;
        text-align: center;
        background: $darker url('../../../images/polygons-symmetric.svg') no-repeat center -20%;
        transition: max-height 0.4s ease, padding 0.4s ease;
        z-index: map-get($z-index, 'header-journal-menu');

        &.js--shown {
            padding-top: calc(1rem + var(--header-height));
            padding-bottom: 1.5rem;
            max-height: 100vh;
            overflow: auto;
        }
    }
}

.header-mobile-menu-wrapper {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end;
    @include media('<=tablet') {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        width: 100%;
        overflow: auto;
        max-height: toRem(700);
    }
    @include media('>tablet', '<desktop') {
        display: flex;
        justify-content: center;
        flex-flow: row wrap;
        width: 100%;
        overflow: auto;
        max-height: toRem(700);
    }
}

.search-overlay {
    background: $darker;
    padding: 1.25rem 0 1.125rem;
    @include media('<desktop') {
        padding-top: calc(1.5rem + var(--header-height));
    }
    position: absolute;
    opacity: 1;
    top: 0;
    right: 0;
    width: 100%;
    z-index: map-get($z-index, 'search-overlay');

    max-height: toRem(1000); // needed for animation purposes
    transition: transform 0.35s ease;
    overflow: visible;
    transform: translateY(0);
    color: $lighter;

    &.js--hidden {
        max-height: 0;
        padding-top: 0;
        padding-bottom: 0;
        overflow: hidden;
        transform: translateY(-100%);
        visibility: hidden;
        transition: transform 0.3s ease, max-height 0s ease 0.3s, padding 0s ease 0.3s, visibility 0s ease 0.3s;
    }

    h5 {
        color: inherit;
    }
    &__close {
        text-align: right;
        margin-bottom: 5.625rem;
        margin-right: 1rem;
        &__btn {
            display: inline-block;
        }
        i {
            margin-left: 0.625em;
        }
        @include media('<desktop') {
            display: none;
        }
    }
    .ui-widget.ui-autocomplete {
        box-shadow: none;
        background: transparent;
        display: flex;
        flex-flow: column wrap;
        z-index: map-get($z-index, 'ui-autocomplete');
        padding: 0;
        color: $lighter;
        a {
            &:hover,
            &.ui-state-focus {
                background: rgba($lighter, 0.3);
            }
        }
        .ui-menu-item {
            & + .ui-menu-item {
                border-top: toRem(1) solid rgba($lighter, 0.2);
            }
        }
        .suggestionType {
            color: $text--secondary;
        }
        .single_highlight_class {
            color: inherit;
            font-weight: bold;
        }
    }

    &__suggestions {
        font-size: 0.75rem;
        background: transparent;
        padding: 0;
        margin: 3rem 0 0 0;
        z-index: map-get($z-index, 'ui-autocomplete-suggestions');

        @include media('<desktop') {
            position: relative;
            margin: 3rem -#{$grid-gutter-width * 0.5} 0;

            &:after {
                content: '';
                position: absolute;
                right: 0;
                top: 0;
                bottom: 0;
                width: 15%;
                background: linear-gradient(
                    270deg,
                    rgba($darker, 0.95),
                    rgba($darker, 0.4) 55.22%,
                    rgba($darker, 0) 100%
                );
            }
        }

        &__list {
            display: flex;
            flex-flow: row wrap;
            text-transform: uppercase;
            padding-bottom: toRem(15);

            @include media('<desktop') {
                li:last-child {
                    padding-right: 15vw;
                }
            }

            @include media('<desktop') {
                flex-flow: row nowrap;
                overflow: auto;
                max-width: 100%;
                padding-left: $grid-gutter-width * 0.5;
            }
        }
        a {
            border: toRem(1) solid $lighter;
            display: block;
            color: $lighter;
        }
        li {
            margin-right: 1rem;
            margin-bottom: 0.5rem;
            @include media('<desktop') {
                white-space: nowrap;
                margin-bottom: 0;
            }
        }

        &__title {
            margin-bottom: 1rem;
            @include media('<desktop') {
                display: none;
                //padding-left: $grid-gutter-width / 2;
            }
        }
    }

    .icon-arrow_d_n {
        &:before {
            content: '\e928';
        }
    }

    &__suggestions,
    &__autocomplete {
        @include media('>desktop') {
            margin-left: 20%;
        }
    }
}

.main-nav {
    @include media('<desktop') {
        text-align: center;
    }
    a {
        font-weight: inherit;
    }
}

.loginBar {
    display: flex;
    @include media('<desktop') {
        flex-flow: column nowrap;
        margin: 0.5rem 0;
    }
    a {
        border-radius: 1rem;
        padding: 0.45em 1.5em;
        margin-left: 0.8rem;
        @include media('<desktop') {
            & + a {
                margin-left: 0;
                margin-top: 1rem;
            }
            i {
                margin-right: 0;
            }
            margin: 0;
            padding: 0.5rem;
            min-width: toRem(280);
            width: 100%;
            max-width: toRem(320);
        }
        &:hover {
            background: $secondary;
            border-color: $secondary;
        }
    }
    .dropBlock__holder {
        left: 50%;
        transform: translateX(-50%);
        a {
            &:hover {
                color: $lighter;
            }
        }
    }
    &__register {
        border: toRem(1) solid $lighter;
        box-shadow: 0 0 toRem(1) 0 rgba($lighter, 0.85) inset, 0 0 toRem(1) 0 rgba($lighter, 0.85);
        @include media('<desktop') {
            box-shadow: none;
        }
    }
    &__username {
        span {
            padding-top: 0;
        }
        @include media('<desktop') {
            border: none;
            justify-content: center;
            span:not(.sign-in) {
                max-width: 100%;
                display: inline-block;
                margin-left: 0.5em;
            }
        }
    }
    &__login {
        background: $lighter;
        color: $darker;
        padding-top: 0.8em;
        &:focus {
            color: $darker;
        }
    }
}

.shopping-cart {
    a {
        display: block;
    }
    .cartLabel {
        padding: 0;
    }
}

.journal-menu {
    $journal-menu: &;
    position: relative;
    z-index: map-get($z-index, 'journal-menu');

    .dropdown {
        @include media('<desktop') {
            &:hover,
            &:focus {
                & > .dropdown__menu {
                    display: none;
                }
            }
            &.menu-parent__opened {
                & > .sub-menu__opened {
                    display: block;
                }
            }
        }
        &__menu {
            right: auto;
            left: 0;
            background: $dark--2;
            .dropdown__menu {
                top: 100%;
            }
            a {
                color: inherit;
                &:hover {
                    color: $lighter;
                    background: $dark--5;
                }
            }
            @include media('<desktop') {
                width: 100vw;
                position: absolute;
            }
        }
    }

    &__button {
        & > .dropdown__toggle {
            height: auto;

            display: block;
            position: relative;
            padding: 0;
            &:after {
                content: '';
                position: absolute;
                width: toRem(6);
                height: toRem(6);
                border-bottom: toRem(6) solid #fff;
                border-left: toRem(6) solid transparent;
                display: block;
                right: toRem(6);
                bottom: toRem(6);
            }
            img {
                display: block;
                padding: 0.895rem;
                max-height: toRem(75);
                @include media('<tablet') {
                    padding: 0.695rem;
                }
            }
            &:hover {
                img {
                    background: $dark--2;
                }
            }
        }
    }

    @include media('<tablet') {
        .main-nav a {
            font-size: 1rem;
        }
    }
}

.dropdown {
    &__toggle {
        .icon-triangle-bottom {
            font-size: 1.8em;
            transition: 0.2s linear transform;
            @include media('<desktop') {
                margin-left: toRem(15);
            }
        }
        &__standard {
            background: $secondary;
        }
        @include media('<desktop') {
            span {
                margin-left: toRem(40);
            }
        }
    }
    &:hover,
    &:focus {
        & > &__toggle {
            .icon-triangle-bottom {
                transform: scaleY(-1);
                /* stylelint-disable*/
                filter: FlipH;
                /* stylelint-enable*/
            }
        }
    }
}
/*stylelint-disable*/
:root {
    --header-height: 59px; // this is standard value to support the look before js will kick in
}
/*stylelint-enable*/
.institution {
    display: flex;
    align-items: center;
    justify-content: center;
    @include media('<desktop') {
        order: 3;
        margin-top: 1rem;
        padding-bottom: 3rem;
    }
}

.institution-info-wrapper {
    @include media('>=desktop') {
        border-right: toRem(1) solid rgba($border-color, 0.3);
        flex-flow: column;
        margin-right: 0.25rem;
        padding-right: 1rem;
    }
    @include media('<desktop') {
        span {
            margin: 0 0.15em;
        }
    }
    display: flex;
    line-height: 1.33;
    font-size: 0.75rem;
    color: $dark--9;
}
