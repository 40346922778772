@import './colors-variables';
$border-radius: toRem(4) !default;
$main-slider-card-slide-title-font-weight: normal;

// Advanced search
$advanced-search-input-group-mb-md: toRem(25);
$advanced-search-left-section-size: toRem(136);
$advanced-search-searchIn-inputs-width-lg: 70%;
$advanced-search-searchIn-inputs-width-md: 70%;
$advanced-search-time-frame-fields-gap: toRem(38);
$advanced-search-timeframe-to-label-m-sm: 0 toRem(12) 0 toRem(-34);
$advanced-search-filter-date-border: toRem(1) solid $dark--9;
$advanced-search-input-icon-ml: unset;
$advanced-search-in-select-width: 50%;
$advanced-search-month-range-w: toRem(145);
$advanced-search-year-range-w: toRem(100);
$advanced-search-input-icon-color: $text-color;
$autocomplete-hover-color: $dark--5;
$advanced-search-label-min-width: toRem(136);
$advanced-search-time-frame-radio-mr: toRem(10);

//z-index
$z-index: (
    'lock-screen': 10,
    'lightbox-trigger-icon': 2,
    'lightbox-trigger-before': 1,
    'indicate-inner-scroll': 1,
    'journal-banner': 1,
    'journal-banner-subtitle': 1,
    'journal-banner-icon': 0,
    'ad-banner-img': 2,
    'ad-banner-child': 1,
    'header': 11,
    'header-journal': 2,
    'header-mobile': 16,
    'header-nonJournal-mobile': 20,
    'header-mobile-btn': -1,
    'journal-title': 1,
    'header-journal-menu': 15,
    'search-overlay': 15,
    'ui-autocomplete': 0,
    'ui-autocomplete-suggestions': 0,
    'journal-menu': 1,
    'cover-large': 2,
    'current-issue-sidebar': 1,
    'current-issue-sidebar-after': -1,
    'news-label': 3,
    'news-item': 1,
    'news-item-after': -1,
    'sections-navbar': 10,
    'top-banner-leaderboard': 20,
    'facets-info': 1,
    'journal-page': 120,
    'bkg-carousel': 0,
    'page-title': 1,
    'current-journal': 1,
    'go-down': 1,
    'main-slider': 1,
    'discovery-cover': 0,
    'item-content': 1,
    'fv': 2,
    'breadcrumb-main': 10,
    'main-article': 10,
    'commerce-header': 1,
    'cmn-toggle': 1,
    'checkoutList-header': 1,
    'lightbox': 5,
    'slideshowNav': 3,
    'infoPanel': 52,
    'toolbar': 2,
    'toolbar-after': -1,
    'holster': -1,
    'tab-header': 2,
    'owl-nav': 5,
    'image-navigator': 50,
    'lock-screen-sticky': 1,
    'acc-inline-links': 2,
    'accordion': 0,
    'pill-menu-tag': 1,
    'content-navigation': -1,
    'content-navigation-after': -1,
    'content-navigation-hover': 5,
    'loi-tab': -1,
    'loi-btn': 1,
    'filter': 200,
    'journal-slide': -1,
    'slideShow-arrows': 1,
    'toc-title': 1,
    'toc-title-after': -1,
    'abstract-decoration': -1,
);
