.facets-landing-page {
    background: $dark--3;
    p {
        margin: 0;
        font-size: 0.875rem;
    }
    h3 {
        color: inherit;
        font-weight: normal;
    }
    .fresh-pub__item__title {
        h3 {
            color: inherit;
            margin-bottom: 0;
            font-size: 1.25rem;
            font-weight: 600;
            margin-top: 0;
        }
    }
}

.latest-news {
    background: $dark--4;
    font-size: 0.75rem;
    padding: 0 0 5.75rem;
    &__item {
        padding: 1rem 1rem 2rem;
        background: $lighter;
        box-shadow: 0 toRem(2) toRem(2) rgba($darker, 0.07);
        border: toRem(1) solid $dark--4;
        .latest-news__title {
            color: inherit;
            font-size: 1.25rem;
            font-weight: 600;
            margin-top: 0;
            margin-bottom: 0.938rem;
        }
    }
    &__link {
        color: $facets;
        text-transform: uppercase;
        font-weight: 600;
        margin-top: 1.125rem;
    }
    &__date {
        margin-bottom: 1.125rem;
        color: $dark--5;
        i {
            font-size: 1rem;
            margin-right: 0.5em;
            display: inline-block;
            border-bottom: toRem(2) solid $facets;
            padding-bottom: 0.5em;
        }
    }
    &__desc {
        line-height: 1.57;
    }
}

.discovery {
    padding-bottom: 3rem;
    background: $discovery url('../../../images/discovery-rombs.png');
    .fj__title {
        color: $lighter;
        margin-bottom: 1.4em;
        &:first-letter {
            border-color: $lighter;
        }
    }
    &--style-2 {
        background: $discovery-style;
        @include media('<desktop') {
            .owl-carousel .owl-nav {
                display: none;
            }
        }
    }
    &__item {
        padding: 8.5rem 0.25rem 0;
        margin: 0 $grid-gutter-width * 0.5;
        position: relative;
        font-size: 0.75rem;
        &__cover {
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            position: absolute;
            z-index: map-get($z-index, 'discovery-cover');
            width: 10rem;
            height: 10rem;
            border-radius: 52%;
            background-size: cover;
            background-position: center;
        }
        &__title {
            line-height: 1.33;
        }
        &__content {
            position: relative;
            z-index: map-get($z-index, 'item-content');
            background: $lighter;
            box-shadow: toRem(4) toRem(4) 0 toRem(4) rgba($turquoise, 0.5);
            border: toRem(1) solid $dark--4;

            padding: 1rem;
            .discovery__item__title {
                line-height: 1.33;
                font-size: 1.125rem;
                margin-top: 0;
                margin-bottom: 0.938rem;
                color: $darker;
                font-weight: 700;
            }
        }
        &__date {
            color: $dark--5;
            margin-bottom: 0.5rem;
        }
        &__link {
            color: $facets;
            text-transform: uppercase;
            font-weight: 600;
            margin-top: 1.125rem;
        }
    }
    .owl-carousel {
        padding: 0;
    }
    .owl-dots {
        margin-top: 0.5rem;
    }
    .owl-stage {
        padding-bottom: 1rem;
    }
    .owl-nav {
        .owl-next,
        .owl-prev {
            margin-top: 0;
            top: 50%;
            transform: translateY(-50%);
            font-weight: normal;
            i {
                font-size: 2rem;
                color: $lighter;
            }
            @include media('<desktop') {
                display: none;
            }
        }
        .owl-next {
            right: auto;
            left: calc(100% + 2rem);
        }
        .owl-prev {
            left: auto;
            right: calc(100% + 2rem);
        }
    }
}

.fj-why {
    padding-bottom: 2rem;
    &__item {
        margin-bottom: 1.5rem;
        padding: 1.5rem;
        box-shadow: 0 toRem(2) toRem(4) rgba($darker, 0.1);
        background: $lighter;
        border: toRem(1) solid $dark--3;

        border-right-width: toRem(8);
        border-bottom-width: toRem(8);
        font-size: 0.875rem;
        .fj-why__title {
            color: $facets;
            line-height: 1.14;
            font-size: 1.25rem;
            font-weight: 600;
            margin-top: 0;
        }
    }
    &__title {
        color: $facets;
        line-height: 1.14;
        &:after {
            content: '';
            display: block;
            width: 0.938rem;
            margin: 1.125rem 0 0.625rem;
            border-bottom: toRem(2) solid $facets;
        }
    }
    &__icon {
        text-align: right;
        color: $dark--4;
        font-size: 3rem;
        line-height: 1;
    }
    &__desc {
        line-height: 1.57;
    }
}

.fj-partners {
    border-top: toRem(1) solid $border-color;
    background: $lighter;
    @include media('<tablet') {
        .container {
            padding: 0;
        }
    }
    .owl-dots {
        margin-top: 1.25rem;
        .owl-dot {
            span {
                background: $border-color;
            }
            &.active span {
                background: transparent;
                border: toRem(1) solid $dark--5;
            }
        }
    }
    .owl-nav {
        .owl-next,
        .owl-prev {
            margin-top: 0;
            top: 50%;
            transform: translate(50%, -50%);
            font-weight: normal;
            i {
                font-size: 2rem;
                color: $dark--5;
            }
        }
        .owl-next {
            right: calc((100vw - 100%) / -4);
            left: auto;
            transform: translate(50%, -50%);
        }
        .owl-prev {
            left: calc((100vw - 100%) / -4);
            right: auto;
            transform: translate(-50%, -50%);
        }
        @include media('>=jumbo') {
            .owl-next {
                left: calc(100% + 2rem);
                right: auto;
            }
            .owl-prev {
                right: calc(100% + 2rem);
                left: auto;
            }
        }
        @include media('<tablet') {
            display: none;
        }
    }
    .owl-carousel {
        padding: 0;
    }
    .owl-stage {
        display: flex;
        align-items: center;
    }
    .owl-item {
        flex: 1 0 auto;
        img {
            margin: 0 auto;
        }
    }
}

.fj-join {
    background: url('../../../images/subscribe-left.png') left top / auto 100% no-repeat,
        $facets url('../../../images/subscribe-right.png') right top / auto 100% no-repeat;
    color: $lighter;
    text-align: center;
    padding-bottom: 2.625rem;
    @include media('<tablet') {
        padding-left: $grid-gutter-width * 0.5;
        padding-right: $grid-gutter-width * 0.5;
    }
    .fj__title {
        color: inherit;
        &:first-letter {
            border-color: $lighter;
        }
    }
    .btn {
        color: $facets;
        background-color: $lighter;
        min-width: 16rem;
        padding-top: toRem(14);
        padding-bottom: toRem(14);

        &:hover {
            background: $facets;
            color: $lighter;
            border: toRem(1) solid $lighter;
        }

        &--inverse {
            background: transparent;
            border-color: $lighter;
            color: $lighter;
            &:hover {
                background: $lighter;
                color: $facets;
            }
        }
        & + .btn {
            margin-left: 1.875rem;
            @include media('<phone') {
                margin: 1rem 0 0 0;
            }
        }
    }
    p {
        margin-top: 2.5rem;
        a {
            text-decoration: underline;
        }
    }
    &__buttons {
        margin-top: 2rem;
    }
}

.fj-subscribe {
    padding: 1.875rem 0;
    color: $lighter;
    background: $dark--2 right center / auto no-repeat url('../../../images/polygons--gray-left.png');
    input[type='email'] {
        padding-top: toRem(13);
        color: $darker;
        padding-bottom: toRem(13);
    }
    @include media('<tablet') {
        text-align: center;
        background: $dark--2 center bottom / 100% auto no-repeat url('../../../images/polygons--gray-bottom.png');
    }
}

.dont-miss {
    padding-top: 0.625rem;
    &__info {
        margin-top: 1rem;
        display: flex;
        align-items: center;

        @include media('<tablet') {
            flex-flow: column;
            text-align: left;
        }

        label {
            display: flex;
            font-size: 0.75rem;
            line-height: 1.6;
            opacity: 0.8;
            margin: 0 0.5rem;
        }
    }
    .btn {
        flex: 1 0 auto;
        min-width: 9rem;
        @include media('<desktop') {
            margin-top: 0.5rem;
        }
    }
    &__input {
        position: relative;
        display: block;
        i {
            position: absolute;
            color: $dark--5;
            font-size: 0.8em;
            left: 1em;
            top: 50%;
            transform: translateY(-50%);
        }
        input {
            padding-left: 2rem;
            &::-webkit-input-placeholder {
                /* Chrome/Opera/Safari */
                color: $dark--5;
                font-style: italic;
            }
            &::-moz-placeholder {
                /* Firefox 19+ */
                color: $dark--5;
                font-style: italic;
            }
            &:-ms-input-placeholder {
                /* IE 10+ */
                color: $dark--5;
                font-style: italic;
            }
            &:-moz-placeholder {
                /* Firefox 18- */
                color: $dark--5;
                font-style: italic;
            }
            &:focus {
                &::-webkit-input-placeholder {
                    /* Chrome/Opera/Safari */
                    color: rgba($dark--5, 0.5);
                }
                &::-moz-placeholder {
                    /* Firefox 19+ */
                    color: rgba($dark--5, 0.5);
                }
                &:-ms-input-placeholder {
                    /* IE 10+ */
                    color: rgba($dark--5, 0.5);
                }
                &:-moz-placeholder {
                    /* Firefox 18- */
                    color: rgba($dark--5, 0.5);
                }
            }
        }
    }
    input[type='checkbox'] {
        margin: toRem(5) 0.5rem 0 0;
        height: 0;
        &:before {
            width: 0.75rem;
            height: 0.75rem;
            min-width: 0.75rem;
            min-height: 0.75rem;
            border-color: $lighter;
            font-size: 0.6rem;
        }
    }
}

.just-published {
    border-bottom: toRem(1) solid $border-color;
    padding-bottom: 3.625rem;
}
