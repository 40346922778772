.loi {
    color: $darker;
    .tab {
        &__nav {
            color: $dark;
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            font-size: 1rem;
            font-weight: bold;
            margin: 0 auto;
            li {
                white-space: nowrap;
                margin: 0;

                @include border-underline-reveal('&.active', 6px);
                transform: perspective(0) translateZ(0);
                &:before {
                    background: $tab-nav-active-border-color;
                    @include brand('background');
                    height: 0;
                }

                &.active {
                    a {
                        color: $tab-nav-active-color;
                        @include brand('color');
                    }
                    &:before {
                        height: toRem(6);
                    }
                }

                overflow: visible;
            }

            a {
                padding: 1.2rem 1.4rem;
                border: none;
                @include media('<tablet') {
                    padding-left: 0.8rem;
                    padding-right: 0.8rem;
                }
            }
        }
        &__pane {
            padding-top: 0;
            &.loading {
                width: auto;
                height: auto;
                background: transparent;
                border: none;
                border-right-color: transparent;
                border-radius: 0;
                transform: none;
                animation: none;

                min-height: 50vh;
                position: relative;
                &:after {
                    content: '';
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    width: 10rem;
                    height: 10rem;
                    background: center / contain no-repeat url('../../../images/spinner.gif');
                    transform: translate(-50%, -50%);
                }
            }
        }
    }

    &__banner {
        display: block;
        @include media('<desktop') {
            margin: 0 -#{$grid-gutter-width};
        }
        @include media('<tablet') {
            margin: 0 -#{$grid-gutter-width * 0.5};
        }
    }

    .swipe__wrapper {
        &:after,
        &:before {
            content: none;
        }
    }

    .issue-items {
        padding-top: 2.5rem;
    }

    .status {
        position: absolute;
        color: $lighter;
        left: -0.2em;
        top: 60%;
        padding: 0.2em 0.6em;
        font-size: 0.875rem;
        font-weight: 600;
        &.current {
            background: $secondary;
        }
        &.inprogress {
            background: $darker;
        }
    }

    .page-range {
        font-size: 0.75rem;
        color: $dark;
        display: block;
        font-weight: normal;
        margin-top: 0.25rem;
    }

    &__item {
        position: relative;
        margin-bottom: 2.2rem;
        &__cover {
            flex: 1;
            margin-bottom: 1rem;
            img {
                display: block;
                width: 100%;
            }
        }
        &__title {
            font-weight: bold;
            span + span:before {
                content: '•';
                display: inline-block;
                margin: 0 0.25em;
            }
        }
    }
    &__navigation {
        border-top: toRem(1) solid $loi-wrapper-border;
        font-weight: bold;
        &:not(.hidden) {
            display: flex;
        }
        justify-content: space-between;
        &__item {
            padding: 1rem;
            color: var(--journal-color);

            &.disabled {
                display: none;
            }
            &--next {
                padding-right: 0;
                margin-left: auto;
                i {
                    margin-left: 0.5em;
                }
            }
            &--prev {
                padding-left: 0;
                margin-right: auto;
                i {
                    margin-right: 0.5em;
                }
            }
        }

        i {
            font-size: 0.8em;
        }
        span,
        i,
        i:before {
            vertical-align: middle;
        }
    }

    &__predecessors {
        font-size: 0.75rem;
        margin-top: 0.5rem;
    }
}
.list-of-issues-detailed {
    padding-bottom: 4rem;

    .row__equalize-children-height {
        @include media('<tablet') {
            justify-content: center;
        }
    }
}

.loi-list {
    &__wrapper {
        border-bottom: toRem(1) solid $tab-nav-border-color;
        .loi-tab-1 > & {
            position: relative;
            overflow: visible;
            border-bottom: none;

            @include media('>desktop') {
                &:after {
                    display: block;
                    content: '';
                    position: absolute;
                    width: 200vw;
                    z-index: map-get($z-index, 'loi-tab');
                    transform: translateX(-50%);
                    border-bottom: toRem(1) solid $tab-nav-border-color;
                    top: 0;
                    bottom: 0;
                    height: auto;
                    right: auto;
                    left: 0;
                    background: $lighter;
                }
            }

            @include media('<desktop') {
                background: $lighter;
                border-bottom: toRem(1) solid $tab-nav-border-color;
            }

            .loi__btn--next,
            .loi__btn--prev {
                background: $lighter;
            }
        }
    }
}

.loi__btn--next,
.loi__btn--prev {
    position: absolute;
    top: 0;
    bottom: 0;
    padding: 0 0.375rem;
    display: flex;
    align-items: center;
    background: $loi-decades-arrow-bg;
    z-index: map-get($z-index, 'loi-btn');
    &.inactive {
        opacity: 0.2;
    }
}

.loi__btn--next {
    right: 0;
}
.loi__btn--prev {
    left: 0;
}

.special-issues {
    font-size: 0.875rem;
    .loi__item {
        @include media('<tablet') {
            flex: 1 0 50%;
        }
    }
}
