.teaser {
    $teaser: &;

    &--covers {
        #{$teaser}__item {
            display: flex;
        }
    }

    &--sm {
        #{$teaser}__item {
            &__title {
                font-size: 0.875rem;
            }
            &__info {
                margin-bottom: 0.375rem;
            }
        }
    }

    &__item {
        .loa {
            font-size: 0.75rem;
        }

        @include media('<tablet') {
            & + .teaser__item {
                border-top: toRem(1) solid $border-color;
                margin-top: 1rem;
                padding-top: 1.5rem;
            }
        }

        &__cover {
            max-width: 4.25rem;
            align-self: flex-start;
            margin-right: 1rem;
            @include media('<phone') {
                display: none;
            }
        }

        &__info {
            color: $dark--5;
        }

        &__title {
            @include media('<tablet') {
                font-size: 0.875rem;
            }

            .multi-search & {
                .smallcaps {
                    font-variant: small-caps;
                }
            }
        }
        &__journal-label {
            display: inline-block;
            padding: 0.37em 0.6em 0.375em;
            border-radius: toRem(4);
            color: $lighter;
            line-height: 1;
            margin-right: toRem(7);
            margin-bottom: 0.25rem;
            &:after {
                display: none;
                content: none;
            }
            & + span {
                &:before {
                    content: '|';
                    font-weight: 400;
                    margin: 0 toRem(7) 0 0;
                }
            }
        }
    }

    font-size: 0.75rem;
    .loa {
        margin-top: 0.5rem;
        white-space: pre-wrap;
    }
    .creative-work__abstract {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
}

.showbox__item__content {
    .teaser__item {
        margin-bottom: 1rem;
    }
}
