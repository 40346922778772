.toc {
    padding-top: 2.125rem;
    display: flex;
    @include media('<desktop') {
        padding-top: 0;
    }
    &__special-title {
        margin: 2rem 0 1rem;
    }
    &__title {
        font-size: 1.5rem;
        @include media('<desktop') {
            font-size: 1.125rem;
        }
        padding: 0.35em 0.25em 0.35em 1em;
        position: relative;
        color: $lighter;
        z-index: map-get($z-index, 'toc-title');
        font-weight: 600;

        &:after {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            z-index: map-get($z-index, 'toc-title-after');
            bottom: 0;
            width: calc(100% + 12.5rem);

            @include media('<desktop') {
                width: 150%;
            }

            @include media('<tablet') {
                width: calc(100% + #{$grid-gutter-width * 0.5});
            }

            background: var(--journal-color) linear-gradient(to right, rgba(0, 0, 0, 0) 69%, rgba(0, 0, 0, 0.5) 100%)
                right top / auto 100% no-repeat;
            color: $lighter;
            border-bottom-left-radius: 1.25em;
            border-top-left-radius: 1.25em;
        }
    }
    &__section {
        padding-top: 2.625rem;
        @include media('<tablet') {
            padding-top: 1.625rem;
            h3 + .issue-item {
                padding-top: 1rem;
            }
        }

        .to-section {
            font-size: toRem(24);
            margin-top: 0;
            margin-bottom: toRem(15);
            color: $black;
            font-weight: 700;
        }
    }

    &__side-navigation {
        max-width: 0;
    }
}
