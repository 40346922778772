@font-face {
    font-family: 'Noto Serif';
    src: url('./notoserif-bold-webfont.woff2') format('woff2'),
         url('./notoserif-bold-webfont.woff') format('woff');
    font-weight: bold;
    font-style: normal;

}

//@font-face {
//    font-family: 'Noto Serif';
//    src: url('../fonts/notoserif/notoserif-bolditalic-webfont.woff2') format('woff2'),
//         url('../fonts/notoserif/notoserif-bolditalic-webfont.woff') format('woff');
//    font-weight: bold;
//    font-style: italic;
//
//}

@font-face {
    font-family: 'Noto Serif';
    src: url('./notoserif-regular-webfont.woff2') format('woff2'),
         url('./notoserif-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'Noto Serif';
    src: url('./notoserif-light-webfont.woff2') format('woff2'),
    url('./notoserif-light-webfont.woff') format('woff');
    font-weight: 300;
    font-style: normal;

}

//@font-face {
//    font-family: 'Noto Serif';
//    src: url('../fonts/notoserif/notoserif-regularitalic-webfont.woff2') format('woff2'),
//         url('../fonts/notoserif/notoserif-regularitalic-webfont.woff') format('woff');
//    font-weight: normal;
//    font-style: italic;
//
//}
