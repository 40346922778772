.learn-more {
    font-size: 0.875rem;
    text-align: center;
    padding: 2.75rem 0 3.625rem;
    background: 95% center / auto auto no-repeat url('../../../images/brand-polygon-symbol.svg'),
        $dark--2 left top / auto 100% no-repeat url('../../../images/blackrock-mountain.jpg');
    color: $lighter;
    line-height: 1.57;
    margin-top: 2.4rem;

    &__title {
        font-size: 2rem;
        font-weight: 400;
        color: inherit;
    }

    &__text {
        margin: 1.8rem 0;
    }

    .container {
        max-width: toRem(640);
    }

    .btn--inverse {
        border-color: $lighter;
        color: inherit;
    }
}

.announcements {
    background: url('/pb-assets/images/announcements-background.jpg') no-repeat center;
    background-size: cover;
    padding: 3.75rem 0;
    .slider {
        &--with-bg {
            @include media('>tablet') {
                padding: 0 2.5rem;
            }
            &,
            &:before {
                background: transparent;
            }
        }
    }
    .container + & {
        margin-top: 1.625rem;
    }
    & + .container {
        margin-top: 2.625rem;
    }
}

.journals-slider {
    margin-top: 2.625rem;
    @include media('<tablet') {
        margin-top: 1.5rem;
    }
}

.landing-page {
    h3.title--slim {
        font-size: toRem(28);
        @include media('<=tablet') {
            font-size: toRem(21);
        }
    }
}
