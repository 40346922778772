.checkout {
    &NewUser {
        margin-top: 0.5rem;
        @include font-size(12);
        display: inline-block;

        @include media('<desktop') {
            margin-top: 0;
        }

        @include media('<tablet') {
            margin-top: 1.25rem;
        }

        a.newUser {
            color: inherit;
            margin: 0 0.125rem;
        }
    }

    &Method {
        margin-right: 0.75rem;
    }

    &List {
        .btn {
            line-height: 1;
        }

        .header {
            @include font-size(18);
            color: $theme-text-dark;
            line-height: 1;
            margin-bottom: 0.625rem;
        }
    }
}

.billingPayment {
    form {
        display: flex;
        flex-direction: column;
    }
    .actions {
        margin-top: 1.625rem;
    }
}

.checkoutProcessRightCol {
    .offerDescription {
        font-weight: normal;
        color: $dark--5;
        display: inline-block;
        margin: 0.9em 0 0.65em;
    }
    input[disabled] {
        opacity: 1;
        &,
        &:hover {
            background: $dark--8;
        }
    }
    .articleTitlePrice {
        .article-title {
            margin-right: 0.3em;
        }
    }
    .totalPrice .boldText {
        margin-left: 0;
        display: inline-block;
    }
    .promoCodeBlock {
        text-align: left;
    }
    .buyItemFooter {
        text-align: right;
        .intialPrice {
            margin-top: 0;
        }
        .totalPrice {
            margin: 0;
        }
    }
}
