.confirmation-widget {
    .purchase-text {
        margin-bottom: 2.5rem;
        @include media('>=tablet') {
            padding-top: 1.875rem;
            padding-bottom: 2.125rem;
        }
    }

    .purchase-table {
        border-bottom: toRem(5) solid $border-color;
        th {
            font-size: 1rem;
        }
    }

    .price {
        @include media('>=desktop') {
            min-width: toRem(130);
        }
    }
    .order-summary .row {
        margin-bottom: 0;
    }
    .address {
        margin-top: 2rem;
    }
}

.purchase-text {
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.92;

    &__check {
        color: $success;
        font-size: 4.4rem;
        display: block;
        margin-bottom: 1.375rem;
    }
    .order-no {
        display: block;
        margin: 0.75rem 0;
    }
}
