.content-navigation {
    margin-top: 1rem;
    margin-bottom: 0;
    a {
        flex: 1;
    }
    &__prev {
        text-align: left;
    }
    &__next {
        text-align: right;
    }
    &__hint {
        display: none;
    }
}
