.box,
.subscribe-teaser {
    padding: 1.25rem;
    border: toRem(1) solid $border-color;
    position: relative;
    background: $lighter;
    box-shadow: 0 toRem(2) toRem(4) rgba($darker, 0.1);
    .icon-subscriber {
        position: absolute;
        top: 0;
        right: 1rem;
        font-size: 3.7rem;
        color: var(--journal-color);
        @include media('<large') {
            top: 0.25em;
            right: 0.25em;
            font-size: 2.5rem;
        }
    }
    &__title {
        .journal-branding & {
            color: var(--journal-color);
        }
        color: $secondary;
        font-size: 1.125rem;
        font-weight: bold;
        line-height: 1.33;
        margin-bottom: 1em;
        text-transform: uppercase;
    }
    .btn {
        width: 100%;
        & + .btn {
            margin-top: 0.688rem;
        }
    }
    &__text {
        font-size: 0.875rem;
    }
    &__email {
        position: relative;
        margin-bottom: 0.8rem;
        input {
            background: $dark--3;
            font-style: italic;
            padding: toRem(13) toRem(20) toRem(13) 2.3rem;
            width: 100%;
        }
        i {
            position: absolute;
            left: 0.7rem;
            top: 50%;
            transform: translateY(-50%);
        }
    }
}

.subscribe-teaser {
    @include media('<desktop') {
        margin-top: 2rem;
    }
    @include media('<tablet') {
        padding: 0.875rem;
        .icon-subscriber {
            font-size: 4.376rem;
            transform: translateY(-40%);
            right: 0.5rem;
        }
    }
}

.box-o {
    // for pb usage
    background: $lighter;
    box-shadow: 0 toRem(2) toRem(2) rgba($darker, 0.15);
    border-radius: toRem(4);
}
.box-o,
.box {
    a {
        &:hover {
            text-decoration: underline;
        }
    }
}
