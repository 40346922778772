@font-face {
    font-family: 'Roboto';
    src: url('./roboto-light.woff2') format('woff2'),
         url('./roboto-light.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}




@font-face {
    font-family: 'Roboto';
    src: url('./roboto-black.woff2') format('woff2'),
         url('./roboto-black.woff') format('woff');
    font-weight: 800;
    font-style: normal;
}


@font-face {
    font-family: 'Roboto';
    src: url('./roboto-bold.woff2') format('woff2'),
    url('./roboto-bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}