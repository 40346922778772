.usageReports {
    .checkbox--primary {
        span {
            align-items: flex-start;
        }
    }
}

#dateRange {
    select {
        padding-left: 2rem;
        padding-right: 2rem;
    }
}
