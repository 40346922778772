@use 'sass:math';

.owl-carousel {
    .owl-item {
        // overriding photo theme weird position: static; declaration
        position: relative;
    }

    img.center {
        margin: 0 auto;
    }
}

.journals-slider {
    $cover-slide-spacing: 1.25rem;
    position: relative;

    @include media('>tablet') {
        margin: 0 math.div($grid-gutter-width, -2);
    }

    .owl-carousel {
        &:not(.owl-loaded) {
            display: flex;
        }
        padding: 0;

        @include media('>desktop') {
            .slide-item {
                margin-bottom: 3.25rem;
            }
        }
    }
    .cover-image {
        margin: 0;
        padding: 0 $cover-slide-spacing;
        border: none;
        box-shadow: none;
        background: none;
    }
    img {
        box-shadow: none;
    }
    .journal-slide {
        position: relative;
        padding: 0;
        margin: 0 ($cover-slide-spacing * 2);
        &:after {
            content: '';
            bottom: 0;
            left: 0;
            z-index: map-get($z-index, 'journal-slide');
            position: absolute;
            width: 100%;
            height: 0;
            padding-top: 115.2%;
            box-shadow: 0 toRem(2) toRem(2) rgba($darker, 0.15);
            background: top right / cover no-repeat url('../../../images/cover-background.svg');
        }
    }

    .journal-meta {
        @include media('<tablet') {
            padding-bottom: 1.25rem;
        }
    }
}

.slider {
    &--with-bg {
        background: $darker;
        &:before {
            content: none;
            background: transparent;
        }
        &__title {
            font-weight: 700;
        }
    }
    &--steps {
        padding: 0;
        background: top right / auto no-repeat url('../../../images/steps-slider.png') $darker;
        footer {
            border-top: toRem(1) solid rgba($lighter, 0.2);
        }
        .owl-nav {
            margin-right: 2rem;
            position: relative;
        }
    }
}

.showbox {
    $showbox: &;
    &__carousel {
        & > #{$showbox}__item {
            &:not(:first-child) {
                display: none;
            }
        }
    }

    // an intricate way to prevent all slides display one under another when slider is shown and the owl carousel takes about 1s to calculate width ->>>
    .owl-item {
        display: none;
        &.cloned {
            &,
            & ~ .owl-item {
                display: block;
            }
        }
    }
    // <<<-
}

.slider--circular-arrows {
    .owl-carousel .owl-nav {
        .owl-prev,
        .owl-next {
            box-shadow: 0 0 toRem(1) 0 rgba($theme-text-lighter, 0.85) inset,
                0 0 toRem(1) 0 rgba($theme-text-lighter, 0.85);
        }
    }
}

.journals-hp-slider {
    border-top: toRem(1) solid $border-color;
    padding-top: 2rem;

    .owl-carousel .owl-dots .owl-dot {
        span {
            background: $dark--5;
        }
    }

    .responsive-slider {
        /* @TODO use a proper way to positions gradient pseudos */
        position: relative;
        margin: 2.25rem -1.813rem 0;

        @include media('<tablet') {
            margin-left: -#{$grid-gutter-width * 0.5};
            margin-right: -#{$grid-gutter-width * 0.5};
        }
    }

    .slide-item {
        @include media('<tablet') {
            min-width: 17em;
        }
    }

    .cover-image {
        padding: 0 1.813rem;
        @include media('<tablet') {
            padding: 0 1rem;
        }
        img {
            width: 100%;
        }
        &__details {
            margin-top: 0.875rem;
            font-weight: bold;
            color: $darker;
            font-size: 1.125rem;
        }
    }
    .owl-dots {
        margin-top: 2rem;
        @include media('<tablet') {
            margin-top: 0.25rem;
        }
    }
    .owl-carousel {
        padding: 0;
    }
    .owl-next {
        right: auto;
        left: 100%;
    }
    .owl-prev {
        left: auto;
        right: 100%;
    }
    .owl-nav {
        @include media('<tablet') {
            display: none;
        }
    }

    .owl-carousel .owl-dots .owl-dot.active span,
    .owl-carousel .owl-dots .owl-dot:hover span {
        background: transparent;
        border: toRem(1) solid $dark--2;
        transform: scale(1.2);
    }
    .slideShow--arrows {
        @include media('<tablet') {
            overflow: auto;
            &:after {
                content: '';
                top: 0;
                bottom: 0;
                right: 0;
                position: absolute;
                z-index: map-get($z-index, 'slideShow-arrows');
                width: 0;
                pointer-events: none;
                background: linear-gradient(269.68deg, $lighter -9.68%, rgba($lighter, 0) 98.8%);
                transition: 0.2s ease width;
            }
            &.js--has-scroll-right {
                &:after {
                    width: 20%;
                }
            }
        }

        .owl-next,
        .owl-prev {
            margin-top: 0;
            transform: translateY(-50%);
            color: $darker;
            i {
                font-size: 2rem;
                font-weight: normal;
            }
        }
    }
    .hvr-img-overlay-fade {
        display: block;
    }
}

.top-banner .main-slider {
    flex: 1;
    @include media('>=desktop') {
        align-items: center;
    }
    .slideShow {
        &__external-nav {
            .owl-prev,
            .owl-next {
                font-size: 1.125rem;
                bottom: 0;
                top: 0;
                position: absolute;
                background-color: transparent !important; // overriding idiotical important on photo theme level
            }
        }
    }
}

.resources__slider {
    .owl-item {
        margin-bottom: 1.875rem;
    }
}
