.profile-menu {
    padding: 0;
    box-shadow: 0 toRem(2) toRem(4) rgba($shadow-color, 0.1);

    .menu-title {
        @include font-size(18);
    }

    li {
        display: block;
        margin-bottom: 0;

        a {
            padding: 0.875rem 0;
        }

        &:last-child {
            padding-bottom: 0.625rem;

            &:not(.active) {
                a {
                    border-bottom: none;
                }
            }
        }
    }

    &--dropBlock {
        box-shadow: 0 0 toRem(20) rgba($primary, 0.13);
        border-bottom: toRem(2) solid theme-color('secondary');
    }

    .my-account-menu {
        padding-bottom: 0;
    }

    .institution-menu {
        background-color: $dark--3;
    }
}
