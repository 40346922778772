/*
    Having only what is used from animate.css
   - https://daneden.github.io/animate.css.
   There are a couple of customisations to match our needs.
*/

/* stylelint-disable*/
@-webkit-keyframes fadeInDownSlowFade {
    from {
        opacity: 0;
        transform: translate3d(0, -100%, 0);
    }

    30% {
        opacity: 0;
    }

    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

@keyframes fadeInDownSlowFade {
    from {
        opacity: 0;
        transform: translate3d(0, -100%, 0);
    }

    30% {
        opacity: 0;
    }

    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

.fadeInDownSlowFade {
    animation-name: fadeInDownSlowFade;
    @include media('<tablet') {
        animation-name: slideInLeft;
    }
}

@-webkit-keyframes slideInLeft {
    from {
        transform: translate3d(-100%, 0, 0);
        visibility: visible;
    }

    to {
        transform: translate3d(0, 0, 0);
    }
}

@keyframes slideInLeft {
    from {
        transform: translate3d(-100%, 0, 0);
        visibility: visible;
    }

    to {
        transform: translate3d(0, 0, 0);
    }
}

.slideInLeft {
    animation-name: slideInLeft;
}


@-webkit-keyframes fadeOutDownSlowFade {
    from {
        opacity: 1;
    }

    40% {
        opacity: 0;
    }

    to {
        opacity: 0;
        transform: translate3d(0, 100%, 0);
    }
}

@keyframes fadeOutDownSlowFade {
    from {
        opacity: 1;
    }

    40% {
        opacity: 0;
    }

    to {
        opacity: 0;
        transform: translate3d(0, 100%, 0);
    }
}

.fadeOutDownSlowFade {
    animation-name: fadeOutDownSlowFade;
    @include media('<tablet') {
        animation-name: slideOutRight;
    }
}


@-webkit-keyframes slideOutRight {
    from {
        transform: translate3d(0, 0, 0);
    }

    to {
        visibility: hidden;
        transform: translate3d(100%, 0, 0);
    }
}

@keyframes slideOutRight {
    from {
        transform: translate3d(0, 0, 0);
    }

    to {
        visibility: hidden;
        transform: translate3d(100%, 0, 0);
    }
}

.slideOutRight {
    animation-name: slideOutRight;
}
/* stylelint-enable*/
