/* stylelint-disable unit-disallowed-list*/
.commerce {
    &-header {
        &.header {
            @include font-size(18);
            color: $theme-text-dark;
            line-height: 1;
            z-index: map-get($z-index, 'commerce-header');
        }
    }
}

.promoCodeForm {
    .applyDiscount {
        margin-left: 0;
        width: toRem(68);
    }

    .clearableInput {
        padding-left: toRem(12);
        padding-right: 0;
        width: calc(100% - 4.25rem);
    }

    .discount-code-label {
        margin: 0.5rem 0 0.9rem;
    }
}

.cart-expand {
    .text {
        @include font-size(12);
        color: var(--secondary-color);
    }
}

.creditCarPayment {
    margin-bottom: 2rem;
    @include media('>=tablet') {
        [data-notification='creditcardDate'] {
            margin-bottom: 0;
        }
    }

    [data-notification='secNumber'] {
        max-width: toRem(100);
    }
}

.acceptTermsConditions {
    a:not(.btn) {
        color: $link-color;
    }

    & + .checkoutMethod {
        margin-right: 1.375rem;
    }

    [type='checkbox'] {
        margin-bottom: 0.375rem;
    }

    .input-group {
        align-items: center;

        label {
            margin-bottom: 0;
        }
    }
}

.checkoutProcessRightCol {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    @include media('<desktop') {
        padding-left: 1rem;
        padding-right: 1rem;

        .card {
            margin-top: 0;
        }
    }

    .cart-expand {
        display: flex;
        align-items: center;
        border-left: toRem(1) solid $primary;
        padding: 0 0 0 0.625rem;
        margin-left: 0.625rem;
    }
}

.orderSummary .boldText {
    text-align: right;
}

.cmn-toggle + label {
    position: relative;
    padding-left: toRem(35);
    cursor: pointer;
    display: inline-block;
    height: toRem(25);
    line-height: toRem(25);
    @include font-size(16);
    -webkit-user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -ms-user-select: none;

    &:before,
    &:after {
        content: '';
        left: 0;
        position: absolute;
        z-index: map-get($z-index, 'cmn-toggle');
    }
}

.checkoutList {
    .header {
        z-index: map-get($z-index, 'checkoutList-header');
    }
    .login-tabs-footer .actions {
        margin-top: 0;
        padding-top: 0;
    }
}

.login-popup,
.login-form {
    .login-tabs-footer {
        margin-top: 0;
        padding: toRem(10) 0;
    }
}

.articleTitlePrice {
    font-weight: bold;

    .boldText {
        margin: 0;
    }
}

.checkoutProcessRightCol .tabPanelFooter {
    border-bottom: toRem(1) solid $border-color;
    padding-bottom: 1rem;

    a {
        &:hover {
            text-decoration: underline;
        }

        & + a {
            margin-left: 0.4em;

            &:before {
                content: ' | ';
            }
        }
    }
}

.articleAuthorDate {
    margin: 0.5rem 0;
    color: $text--secondary;
}

.discount-code-label {
    margin-bottom: 0.6rem;
    display: block;
}

.billingPayment .switch {
    margin-top: 1rem;
}

.cartLabel .shopping-cart {
    background: $secondary;
    white-space: nowrap;
    line-height: 1.85;

    &:not(.hidden) {
        display: inline-block;
    }

    position: static;
    margin-left: 0;
    padding: 0;
    width: 1.8em;
    height: 1.8em;
    font-size: inherit;
}

[data-notification='secNumber'] input {
    width: 100%;
    display: block;
}

.cc-number {
    &:before {
        left: toRem(7);
        width: toRem(35);
        height: toRem(23);
    }

    &.visa:before {
        background-position: 0 -27px;
    }

    &.mastercard:before {
        background-position: 0 -56px;
    }

    &.jcb:before {
        background-position: 0 -83px;
    }

    &.discover:before {
        background-position: 0 -113px;
    }

    &.maestro:before {
        background-position: 0 -223px;
    }

    &.dinner:before {
        background-position: 0 -167px;
    }

    &.amex:before {
        background-position: 0 -139px;
    }
}

.eCommerceCheckoutSavedForLaterItemsWidget {
    .add-article-to-cart {
        margin-bottom: 0;
    }
    .cart-expand {
        border: none;
        padding: 0;
        margin-left: 0;
    }
}

.eCommerceCheckoutBuyingItemsWidget,
.eCommerceCheckoutSavedForLaterItemsWidget,
.eCommerceCheckoutRecentlyViewedItemsWidget,
.eCommerceCheckoutRecommendedItemsWidget {
    .title {
        width: auto;
        display: inline;
        font-size: inherit;
    }

    .articleTitlePrice .boldText {
        flex: auto;
    }

    .purchaseArea {
        border: none;
        border-radius: toRem(6);
        box-shadow: 0 toRem(2) toRem(10) rgba($darker, 0.13);
        top: calc(100% + 0.5rem);
        padding: 0;

        .add-article-to-cart {
            padding: 1rem;
        }
    }

    .add-article-to-cart {
        border-radius: 0;

        .checkoutProcessRightCol &:hover {
            text-decoration: none;
            background: rgba($supplementary, 0.15);
        }

        border: none;

        &__title {
            padding-bottom: 0.5rem;
            margin-bottom: 0.5rem;
            min-width: 100%;
            border-bottom: toRem(1) solid $border-color;
        }

        &__price-group {
            display: flex;
            justify-content: space-between;
            min-width: 100%;
            align-items: center;
        }

        .purchase-item {
            display: flex;
        }

        .btn {
            flex: 1 0;
            margin-right: 0.5rem;
            white-space: nowrap;
            background: $supplementary;

            i {
                font-size: 1.2em;
                margin: 0 0.4em 0 0;
            }
        }

        &__price {
            border: none;
            padding: 0;
            text-align: right;
            font-size: 1.25rem;
            flex: 1 1 100%;
        }
    }
}

.eCommercePurchaseAccessWidget {
    .purchaseArea,
    .purchaseArea--list {
        padding: 1.25rem 0;
    }

    .article-title {
        flex: 1;
        flex-flow: column;
        display: flex;
        align-items: stretch;
    }

    .addedMessage {
        .article-title-content {
            display: flex;
            align-items: center;
            border-bottom: toRem(1) solid $border-color;
            margin-bottom: 1rem;
            padding-bottom: 1rem;
        }
        .icon-check_circle {
            font-size: 1.5em;
            margin-right: 0.4em;
        }
        .text {
            font-size: 1rem;
            font-weight: bold;
            text-transform: uppercase;
        }
        .show-cart-link {
            align-self: flex-end;
            .text {
                font-size: inherit;
            }
            background-color: $access;
            &:hover {
                background-color: darken($access, 0.5);
            }
        }
    }

    .infoMsgBox,
    .errorMsgBox,
    .itemAddedMsgBox {
        text-align: center;
        border-width: toRem(3);
    }

    .add-article-to-cart,
    .addedMessage {
        padding: 1rem !important; // overriding ux3 core code that "works fine"
        background: $dark--3;
        border-radius: toRem(4);
        border: toRem(1) solid $border-color;
        margin-bottom: 1.5rem;
    }
    .add-article-to-cart {
        &__title {
            text-transform: uppercase;
            float: none;
            width: auto;
            @include media('<tablet') {
                line-height: 1.4;
            }
        }

        &__info,
        &__meta {
            color: $dark--5;
            font-size: 0.75rem;
            font-weight: normal;
            margin-top: 0.5rem;
            p {
                margin: 0.4em 0;
            }
        }
        &__info {
            display: block;
            margin: 0.75em 0 1.5em;
        }
        &__offer_details {
            font-size: 0.75rem;
        }
        &__price-group {
            border-top: toRem(1) solid $border-color;
            padding-top: 1rem;
            margin-top: 1rem;
            display: flex;
            align-items: center;
            @include media('<tablet') {
                flex-flow: column;
                align-items: flex-start;
            }
        }
        &__tariff {
            margin-top: 0.875rem;
            font-size: 0.75rem;
        }
        &__price {
            border: none;
            padding: 0;
            text-align: left;
            font-size: 1.25rem;
            @include media('<tablet') {
                width: auto;
                margin-bottom: 0.5rem;
            }
        }
        &__price-label {
            font-size: 0.875rem;
            font-weight: normal;
        }

        .add-to-cart-msg.btn {
            order: 2;
            margin-left: 0.5rem;
            width: auto !important;
            white-space: nowrap;
            @include media('<tablet') {
                margin: 0;
            }
        }
    }

    .save-for-later-link {
        margin-left: 1em;
    }

    .saved-go-cart,
    .save-for-later-link {
        text-decoration: underline;
        &:hover {
            color: $secondary;
        }
        i {
            font-size: 1.2em;
            margin-right: 0.3em;
            vertical-align: text-bottom;
        }
    }

    .login-form {
        .submit.button {
            padding: 0.68em 2em;
        }
    }

    .loginOptions {
        .accordion {
            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;
            align-items: flex-start;
            &__content {
                order: 3;
                flex: 1 1 100%;
            }
        }
    }

    .purchase-options-container {
        padding: 0;
    }
}
#contents {
    [name='brainTree-form'] {
        .defButton {
            background-color: $secondary;
        }
    }
}
/* stylelint-enable unit-disallowed-list*/
