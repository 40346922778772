.profile {
    &-main-container {
        margin-top: 2.5rem;
        @include media('<tablet') {
            margin-top: 0.875rem;
        }
    }

    &-page {
        .container {
            @include media('<desktop') {
                width: 100%;
            }
        }
        overflow: visible;
    }

    &-header {
        @include font-size(24);
        line-height: 2rem;
        color: $primary;
        text-transform: capitalize;
        margin-bottom: 1.5rem;
        @include media('<tablet') {
            display: block;
            @include font-size(21);
        }
    }

    &Main {
        $this: &;
        padding-bottom: 2rem;
        @include media('<tablet') {
            padding-bottom: 1rem;
        }
        //======BEGIN GENERAL ======//
        %dark--text {
            color: $dark--5;
        }
        .g-recaptcha {
            margin-bottom: 1rem;
        }

        h5 {
            @extend %dark--text;
            @include font-size(16);
            @include media('<tablet') {
                @include font-size(14);
            }
        }

        select {
            background-color: $lighter;
            @include media('>=tablet', '<desktop') {
                width: 50%;
            }

            @include media('<tablet') {
                width: 100%;
                @include font-size(12);
            }
        }

        .input-group {
            margin-bottom: 1rem;

            input {
                width: 75%;

                &::placeholder {
                    @extend %dark--text;
                    @include font-size(12);
                    font-family: inherit;
                }

                @include media('<desktop') {
                    width: 100%;
                }
            }
        }

        .radio--secondary {
            input {
                width: auto;
            }
        }

        .jcf-select {
            width: 50%;

            &-text {
                @extend %dark--text;
            }
        }

        .icon-circle-plus {
            @extend %dark--text;
        }

        .link {
            color: $link-color;
            text-decoration: underline;
        }

        //======BEGIN PERSONAL DETAILS======//

        .reproducible {
            @include media('<desktop') {
                input {
                    width: 75%;
                }
            }
            @include media('<tablet') {
                input {
                    width: 100%;
                    margin-bottom: 1.75rem;
                }
                .actions {
                    width: auto;
                    border-left: toRem(1) solid $dim;
                }
            }
        }

        .email {
            .link {
                @extend %dark--text;
                text-decoration: none;
            }
        }

        input {
            &[name*='zipCode'] {
                width: 50%;
                @include media('<desktop') {
                    width: 75%;
                }
                @include media('<tablet') {
                    width: 100%;
                }
            }

            &[name*='title'] {
                @include media('<tablet') {
                    width: 60%;
                }
            }

            &:checked {
                & ~ span {
                    &:after {
                        top: 0.313rem;
                    }
                }
            }
        }

        .addresses {
            margin-top: 2.375rem;
        }

        .phones-wrappers {
            margin-bottom: 2.5rem;
            @include media('<tablet') {
                input {
                    width: 75%;
                    margin-bottom: 0;
                }
            }
        }

        .professional-information {
            padding-bottom: 0.5rem;
        }

        .marketable {
            border-top: toRem(1) solid $dim;
            border-bottom: toRem(1) solid $dim;
            padding: 1rem 0 0;
            margin-bottom: 1rem;

            p {
                padding: 0;
                margin-top: 0;
                margin-bottom: 1rem;
            }
        }

        .btn--inverse {
            transition: all 0.15s ease-in-out;
            color: $theme-text-dark;
            border: toRem(1) solid $dim;
            padding: 0.5rem 1.5rem;
            @include font-size(12);
            text-transform: uppercase;
            margin-right: 1rem;
            line-height: 1;

            &:hover {
                background-color: rgba($dim, 0.5);
            }

            @include media('<tablet') {
                width: 100%;
                margin: auto;
                padding: 0.75rem 1.5rem;
            }
            &#disableButton {
                margin-top: toRem(14);
            }
        }

        .button,
        .btn {
            @include media('<tablet') {
                width: 100%;
                margin-bottom: 1.5rem;
                padding: 0.75rem 1.5rem;
            }
        }

        .delete-account-container {
            margin-bottom: 0;
            @include media('<tablet') {
                display: block;
                width: 100%;
            }
        }

        .actions {
            &__wrapper {
                margin-top: 1rem;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                @include media('<tablet') {
                    flex-wrap: wrap-reverse;
                }
            }
        }

        .remove-ctrl-field {
            vertical-align: middle;
        }

        //===BEGIN ACCESS ENTITLEMENTS===
        .subscriptions-form {
            //display: flex;
            //align-items: center;

            //@include media('<tablet') {
            //    flex-wrap: wrap;
            //    justify-content: flex-end;
            //}

            //.input-group {
            //    margin-bottom: 0;
            //
            //    @include media('<tablet') {
            //        margin-bottom: 1rem;
            //    }
            //
            //}

            .btn {
                @include font-size(17);
                padding: 0.5rem 0.875rem;
                color: $dark--5;
                width: auto;
            }
        }

        .account {
            &__sort {
                select {
                    min-width: 14em;
                }
            }
        }

        //.account {
        //    &__sort {
        //        margin-left: 0.5rem;
        //        white-space: nowrap;
        //        color: $dark--5;
        //        min-width: auto;
        //
        //        @include media('<tablet') {
        //            .input-group {
        //                margin-bottom: 0;
        //            }
        //        }
        //
        //        i {
        //            @include font-size(12)
        //        }
        //
        //        label {
        //            color: inherit;
        //            width: auto;
        //            margin: 0 0.5rem;
        //        }
        //
        //        select {
        //            margin: auto;
        //            background-color: transparent;
        //            border: none;
        //            color: var(--secondary-color);
        //            padding: 0 0.125rem;
        //            height: auto;
        //            width: auto;
        //        }
        //    }
        //
        //    &-tab__nav {
        //        li {
        //            flex: 1;
        //        }
        //    }
        //}

        .expandable {
            th {
                &:first-child {
                    //width: 50%; @TODO test on prod
                }
            }
        }

        //===BEGIN ACCESS TOKEN===
        #accessTokenForm {
            .input-group {
                margin-top: 2rem;
            }

            .button {
                margin-left: 0.5rem;
                padding: 0.5rem 2.5rem;
            }
        }

        //===BEGIN FAVORITES===
        .favoriteShortlist {
            select {
                background-color: transparent;
                border: none;
                border-radius: 0;
            }
        }

        //===BEGIN SAVED SEARCHES===
        .saved-searches {
            .table-responsive {
                td {
                    &.action {
                        @include media('<tablet') {
                            padding-top: 1.75rem;
                        }
                    }
                }
            }

            .action {
                i {
                    @include media('<tablet') {
                        @include font-size(22);
                    }
                }
            }
        }

        //===BEGIN HOLDINGS===
        .sendTo {
            .label-txt {
                &:after {
                    top: 0.26rem;
                }
            }
        }
        .tab {
            &__content {
                .tab__pane {
                    .quick-search {
                        &__button {
                            color: $dark--5;
                            font-size: 1.063rem;
                            padding: 0.5rem 0.875rem;

                            &::before {
                                @include media('<phone') {
                                    position: absolute;
                                    right: toRem(15);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.formbutton {
    margin: 1rem 0;
}

.institutionUrlAdmin {
    input[type='submit'] {
        margin-top: 1rem;
    }
}
.alerts .tab .dropBlock__link {
    padding: 1em 1.5em;
    i {
        margin-top: 0;
    }
}

.pairing-management-modal {
    input {
        margin-bottom: toRem(20);
    }
}

.author-services {
    &__item {
        margin-bottom: 3rem;
    }
    h5 {
        margin: 1.5em 0 0.75em;
    }
    &__metrice {
        .metrics-value {
            margin-left: 0.3em;
        }
    }
    .list-items {
        li a {
            margin: 0 0.3em;
        }
    }
}

.link_resolver_form .uploadImageButton {
    border-bottom: none;
}
