.quick-search {
    $quick-search: &;

    &--citation {
        background-color: transparent;
        padding: 0;
        margin: 0;
        input {
            margin-bottom: toRem(15);
            width: 20%;
            margin-right: toRem(15);
            @include media('<tablet') {
                width: 100%;
                margin-right: 0;
            }
        }
        .input-group {
            border-bottom: toRem(1) solid $border-color;
            margin-bottom: toRem(15);
            padding-bottom: toRem(15);
        }
        .journal-search {
            input {
                @include media('>=tablet') {
                    width: 60%;
                }
            }
        }
        fieldset {
            margin: 0;
            padding: 0;
            border: none;
        }
    }

    &__search-items {
        flex: 1;
    }

    .dropBlock__link {
        i {
            top: 50%;
            transform: translateY(-50%);
            margin-top: 0;
        }
    }

    .search-overlay & {
        &__searchbox {
            display: flex;
            flex-flow: row nowrap;
            @include media('<desktop') {
                flex-flow: column nowrap;
            }
        }
        &__form {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            width: 100%;
            @include media('>=desktop') {
                border-bottom: toRem(1) solid $lighter;
                padding-bottom: 0.875rem;
            }
            @include media('<desktop') {
                flex-flow: column nowrap;
                align-items: stretch;
            }
            input {
                background: transparent;
                border: none;
                appearance: none;
                border-radius: 0;
                &[type='search'] {
                    .dropBlock + .tab__content & {
                        padding-left: toRem(21);
                    }
                    padding-left: 0;
                    font-size: 1.188rem;
                    font-style: italic;
                    @include media('<tablet') {
                        border-bottom: toRem(1) solid $lighter;
                    }
                }

                &:focus,
                &:active {
                    &::-webkit-input-placeholder {
                        /* Chrome/Opera/Safari */
                        color: rgba($input-placeholder, 0.3);
                        letter-spacing: toRem(1);
                    }
                    &::-moz-placeholder {
                        /* Firefox 19+ */
                        color: rgba($input-placeholder, 0.3);
                        letter-spacing: toRem(1);
                    }
                    &:-ms-input-placeholder {
                        /* IE 10+ */
                        color: rgba($input-placeholder, 0.3);
                        letter-spacing: toRem(1);
                    }
                    &:-moz-placeholder {
                        /* Firefox 18- */
                        color: rgba($input-placeholder, 0.3);
                        letter-spacing: toRem(1);
                    }
                }
            }
        }
        .tab {
            &__pane {
                padding-top: 0;
            }
            &__nav {
                @include media('<desktop') {
                    font-size: 0.875rem;
                }
                .active a {
                    border-bottom: none;
                }
            }
            .dropBlock {
                border-bottom: toRem(1) solid $lighter;
                margin-right: 5%;
                width: 15%;
                align-self: flex-start;
                @include media('<desktop') {
                    max-width: 80%;
                    min-width: toRem(200);
                    margin-right: 0;
                    width: 70%;
                    .tab__nav a {
                        padding: 1rem;
                    }
                }

                ul {
                    padding: 0;
                    min-width: 100%;
                    border-radius: toRem(4);
                }
                li {
                    display: block;
                    margin-bottom: 0;
                }
                & > a {
                    color: $lighter;
                    background: transparent;
                    font-size: 1.188rem;
                    padding-bottom: 1.175rem;
                }
                i {
                    color: inherit;
                    position: static;
                }
            }
        }

        &__input {
            flex: 1;
            margin: 0;
            @include media('<desktop') {
                margin: 1rem 0;
            }
        }
        &__button {
            @include media('<tablet') {
                width: 100%;
            }

            .icon-search {
                margin-right: 0.7em;
                transform: scale(1.2);
                vertical-align: bottom;
                font-size: 1.2em;
                display: inline-block;
            }
        }
        &__links {
            padding-top: 0.813rem;
            font-size: 0.75rem;
            text-transform: uppercase;
            @include media('<desktop') {
                display: flex;
                justify-content: space-between;
                font-weight: bold;
                padding-top: 2rem;
            }
            a {
                @include media('>=desktop') {
                    & + a {
                        margin-left: 0.8rem;
                        padding-left: 0.8rem;
                        border-left: toRem(1) solid $lighter;
                    }
                }
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    &--in-header {
        flex: 1 0 56%;
        font-size: 1.188rem;
        color: $dark--2;

        @include media('<desktop') {
            min-width: 100%;
            margin-bottom: 2.5rem;
        }
        #{$quick-search}__searchbox {
            display: flex;
        }

        #{$quick-search}__form {
            display: flex;
        }

        .tab {
            .dropBlock__link {
                border-bottom-left-radius: toRem(4);
                border-top-left-radius: toRem(4);
                padding: toRem(8) toRem(4) toRem(8) toRem(12);
                height: 100%;
                width: toRem(126);
                justify-content: space-between;
                svg {
                    transform: scale(0.85);
                }
            }
            &__pane {
                padding-top: 0;
            }
            &__content {
                flex: 1;
            }
            &__nav {
                padding: 0;
                min-width: 100%;
                li {
                    display: block;
                    margin-bottom: 0;
                }
            }
        }

        .input-group {
            margin-bottom: 0;
            flex: 1;
        }

        input {
            font-size: inherit;
            height: 100%;
            padding: toRem(18);
            border-radius: 0;
            border-bottom-left-radius: toRem(4);
            border-top-left-radius: toRem(4);
        }

        .dropBlock + .tab__content {
            input {
                appearance: none;
                border-bottom-left-radius: 0;
                border-top-left-radius: 0;
            }
        }

        #{$quick-search}__button {
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
            font-size: inherit;
            background: $supplementary;
        }

        .autoComplete {
            &__category {
                display: none;
            }
            &__link--grid {
                display: block;
                font-size: toRem(16);
                &:hover {
                    background-color: $quick-search-hover;
                    color: $dark--2;
                }
                .single_highlight_class {
                    color: $secondary;
                    font-weight: bold;
                }
            }
        }
    }
    .autoComplete {
        background: $black;
        top: 210%;
        &__item {
            margin-top: 0 !important;
            margin-bottom: 0 !important;
            color: $white;
            font-size: 1rem;
            border-bottom: toRem(1) solid $quick-search-border;
        }
        &__link--grid {
            display: flex;
            justify-content: space-between;
            padding: 1rem 0.5rem;
            &:has(.autoComplete__category) {
                flex-direction: row-reverse;
            }
            &:hover {
                background: rgba($lighter, 0.3);
                color: $white;
            }
        }
    }
}

.tab {
    .dropBlock__link {
        &.js--open {
            transition: transform 0.05s ease;
            .icon-arrow_d_n {
                transform: scaleY(-1);
            }
        }
    }
}

.ui-widget.ui-autocomplete {
    border: none;
    list-style: none;
    padding: 0 1rem;
    margin: 0;
    border-radius: 0;
    box-shadow: 0 toRem(2) toRem(2) rgba($darker, 0.15);

    .ui-menu-item {
        & + .ui-menu-item {
            border-top: toRem(1) solid $border-color;
        }
    }

    a {
        padding: 1rem 0.5rem;
        color: inherit;
        display: block;
        &:hover,
        &.ui-state-focus {
            border-radius: 0;
            border: none;
        }
    }
    .single_highlight_class {
        color: $secondary;
        font-weight: bold;
    }
}
