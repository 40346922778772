.loi {
    .tab {
        &__nav {
            display: flex;
        }
    }
}

.tab {
    $this: &;

    &--panel {
        .panel {
            &__header {
                padding: 1rem 1.125rem 0;
                @include media('<tablet') {
                    padding: 1rem 0.625rem;
                }

                li {
                    margin-bottom: -0.063rem;
                    border-radius: 0.625rem 0.625rem 0 0;
                    @include font-size(16);
                    font-weight: bold;
                    color: $dark--5;
                    text-align: center;
                    @include media('<tablet') {
                        @include font-size(14);
                        line-height: 1rem;
                        margin-bottom: -1.063rem;
                    }

                    &.active {
                        border: toRem(1) solid $dim;
                        border-bottom: 0;
                        background-color: $white;
                        color: var(--secondary-color);
                    }
                }

                a {
                    border-bottom: 0;
                    padding: 1.125rem 1.875rem;
                    text-transform: uppercase;
                    @include media('<tablet') {
                        padding: 1rem;
                    }

                    &:hover {
                        color: inherit;
                    }
                }

                span {
                    line-height: inherit;
                }
            }

            #{$this}__nav {
                display: flex;
            }
        }
    }
}
