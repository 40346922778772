input[type='email'],
input[type='password'],
input[type='tel'],
input[type='text'],
input[type='search'],
textarea,
.ms-ctn,
select {
    border-radius: toRem(4);
    padding: toRem(9) toRem(21);
    color: inherit;
}

.ms-ctn {
    padding: toRem(4) toRem(12);
    input {
        padding: 0;
    }
    border-radius: toRem(4);
}

.label-txt {
    align-items: center;
}

.left-side-label {
    justify-content: flex-start;
}

::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: $dark--5;
    letter-spacing: normal;
}
::-moz-placeholder {
    /* Firefox 19+ */
    color: $dark--5;
    letter-spacing: normal;
}
:-ms-input-placeholder {
    /* IE 10+ */
    color: $dark--5;
    letter-spacing: normal;
}
:-moz-placeholder {
    /* Firefox 18- */
    color: $dark--5;
    letter-spacing: normal;
}

.radio--primary {
    input:checked ~ span:after {
        height: toRem(8);
        width: toRem(8);
        top: toRem(4);
        left: toRem(3);
    }
}

.pass-strength-popup {
    &.js__pswd_info {
        li {
            margin-bottom: toRem(5);
            display: block;
        }
    }
}

.pass-strength-popup.too-short {
    .strength {
        float: left;
    }
}
