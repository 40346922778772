@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?y40r78');
  src:  url('fonts/icomoon.eot?y40r78#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?y40r78') format('truetype'),
    url('fonts/icomoon.woff?y40r78') format('woff'),
    url('fonts/icomoon.svg?y40r78#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-bluesky-circle:before {
  content: "\e97b";
}
.icon-bluesky-square:before {
  content: "\e97d";
}
.icon-bluesky:before {
  content: "\e95b";
}

.icon-twitter-square1:before {
  content: "\e954";
}
.icon-twitter:before {
  content: "\e955";
}
.icon-x-social:before {
  content: "\e955";
}
.icon-old-twitter-circle:before {
  content: "\e956";
}
.icon-twitter-circle-outline .path1:before {
  content: "\e957";
  color: rgb(0, 0, 0);
}
.icon-twitter-circle-outline .path2:before {
  content: "\e958";
  margin-left: -1em;
  color: rgb(29, 29, 27);
}
.icon-twitter-circle:before {
  content: "\e959";
}
.icon-x-circle:before {
  content: "\e959";
}
.icon-twitter-square:before {
  content: "\e95a";
}
.icon-x-square:before {
  content: "\e95a";
}
.icon-medium:before {
  content: "\e952";
}
.icon-bubble:before {
  content: "\e94d";
}
.icon-mortarboard:before {
  content: "\e94e";
}
.icon-start-o-o:before {
  content: "\e94f";
}
.icon-crowd:before {
  content: "\e950";
}
.icon-logo:before {
  content: "\e951";
}
.icon-editor-choice:before {
  content: "\e949";
}
.icon-calendar-marked:before {
  content: "\e94a";
}
.icon-star-o:before {
  content: "\e947";
}
.icon-favorite-added .path1:before {
  content: "\e905";
  color: rgb(0, 0, 0);
}
.icon-favorite-added .path2:before {
  content: "\e953";
  margin-left: -1em;
  color: rgb(102, 162, 43);
}
.icon-add-favorite:before {
  content: "\e91c";
}
.icon-heart-o:before {
  content: "\e91e";
}
.icon-card:before {
  content: "\e921";
}
.icon-plus-o:before {
  content: "\e923";
}
.icon-copy-o:before {
  content: "\e924";
}
.icon-bin-o:before {
  content: "\e926";
}
.icon-Download:before {
  content: "\e927";
}
.icon-triangle-bottom:before {
  content: "\e928";
}
.icon-triangle-up:before {
  content: "\e929";
}
.icon-arrow-long-left:before {
  content: "\e92b";
}
.icon-arrow-long-right:before {
  content: "\e92c";
}
.icon-arrow-medium-right:before {
  content: "\e92d";
}
.icon-facebook:before {
  content: "\e92e";
}
.icon-facebook-2:before {
  content: "\e92f";
}
.icon-folder-plus:before {
  content: "\e930";
}
.icon-info:before {
  content: "\e931";
}
.icon-institution:before {
  content: "\e932";
}
.icon-menu-squares:before {
  content: "\e933";
}
.icon-metrics:before {
  content: "\e934";
}
.icon-mouse:before {
  content: "\e935";
}
.icon-phone:before {
  content: "\e936";
}
.icon-play-filled:before {
  content: "\e937";
}
.icon-play-o:before {
  content: "\e938";
}
.icon-like:before {
  content: "\e939";
}
.icon-related:before {
  content: "\e93a";
}
.icon-subscriber:before {
  content: "\e93c";
}
.icon-tables:before {
  content: "\e93d";
}
.icon-textfile:before {
  content: "\e93e";
}
.icon-checked-o:before {
  content: "\e93f";
}
.icon-checked:before {
  content: "\e940";
}
.icon-tick-o:before {
  content: "\e941";
}
.icon-tick:before {
  content: "\e942";
}
.icon-eye:before {
  content: "\e943";
}
.icon-youtube:before {
  content: "\e944";
}
.icon-zoom-in:before {
  content: "\e945";
}
.icon-zoom-out:before {
  content: "\e946";
}
.icon-reddit:before {
  content: "\e918";
}
.icon-connection:before {
  content: "\e916";
}
.icon-google-plus:before {
  content: "\e90e";
}
.icon-is-favorite .path1:before {
  content: "\e94b";
  color: rgb(255, 255, 255);
}
.icon-is-favorite .path2:before {
  content: "\e94c";
  margin-left: -1em;
  color: rgb(102, 162, 43);
}
.icon-eye-blocked:before {
  content: "\e91d";
}
.icon-digital-object:before {
  content: "\e91b";
}
.icon-delete:before {
  content: "\e91a";
}
.icon-supplemental:before {
  content: "\e915";
}
.icon-reprints:before {
  content: "\e913";
}
.icon-orcid:before {
  content: "\e636";
}
.icon-references:before {
  content: "\e948";
}
.icon-zoom:before {
  content: "\e92a";
}
.icon-wechat:before {
  content: "\e914";
}
.icon-arrow_u_p:before {
  content: "\e911";
}
.icon-arrow_d_n:before {
  content: "\e912";
}
.icon-grid:before {
  content: "\e910";
}
.icon-allfigures:before {
  content: "\e910";
}
.icon-minus:before {
  content: "\e90c";
}
.icon-plus:before {
  content: "\e90b";
}
.icon-check:before {
  content: "\e5ca";
}
.icon-SearchPlus:before {
  content: "\e906";
}
.icon-PDF:before {
  content: "\e900";
}
.icon-open_access:before {
  content: "\e901";
}
.icon-PDFplus:before {
  content: "\e902";
}
.icon-build:before {
  content: "\e869";
}
.icon-my_location:before {
  content: "\e55c";
}
.icon-star:before {
  content: "\e838";
}
.icon-shopping_cart:before {
  content: "\e8cc";
}
.icon-x_btnclose:before {
  content: "\e889";
}
.icon-photo:before {
  content: "\e410";
}
.icon-notifications:before {
  content: "\e7f4";
}
.icon-description:before {
  content: "\e873";
}
.icon-share:before {
  content: "\e80d";
}
.icon-volume_up:before {
  content: "\e050";
}
.icon-volume-up:before {
  content: "\e050";
}
.icon-linkedin:before {
  content: "\e903";
}
.icon-twitter1:before {
  content: "\e904";
}
.icon-public:before {
  content: "\e80b";
}
.icon-pause:before {
  content: "\e034";
}
.icon-add_box:before {
  content: "\e146";
}
.icon-mail:before {
  content: "\e159";
}
.icon-lock:before {
  content: "\e897";
}
.icon-lock_open:before {
  content: "\e898";
}
.icon-timeline:before {
  content: "\e922";
}
.icon-account_circle:before {
  content: "\e853";
}
.icon-turned_in:before {
  content: "\e8e6";
}
.icon-check_circle:before {
  content: "\e86c";
}
.icon-close:before {
  content: "\e5cd";
}
.icon-close_thin:before {
  content: "\e5cd";
}
.icon-mode_edit:before {
  content: "\e254";
}
.icon-face:before {
  content: "\e87c";
}
.icon-format_quote:before {
  content: "\e244";
}
.icon-fullscreen:before {
  content: "\e5d0";
}
.icon-fullscreen_exit:before {
  content: "\e5d1";
}
.icon-help:before {
  content: "\e887";
}
.icon-import_export:before {
  content: "\e0c3";
}
.icon-info1:before {
  content: "\e88e";
}
.icon-Icon_Information:before {
  content: "\e88e";
}
.icon-arrow_down:before {
  content: "\e313";
}
.icon-section_arrow_d:before {
  content: "\e313";
}
.icon-arrow_left:before {
  content: "\e314";
}
.icon-section_arrow_l:before {
  content: "\e314";
}
.icon-arrow_l:before {
  content: "\e314";
}
.icon-arrow_right:before {
  content: "\e315";
}
.icon-section_arrow_rt:before {
  content: "\e315";
}
.icon-arrow_r:before {
  content: "\e315";
}
.icon-arrow_up:before {
  content: "\e316";
}
.icon-section_arrow_u:before {
  content: "\e316";
}
.icon-arrow_forward:before {
  content: "\e5c8";
}
.icon-open_in_new:before {
  content: "\e89e";
}
.icon-list:before {
  content: "\e896";
}
.icon-more_vert:before {
  content: "\e5d4";
}
.icon-person_outline:before {
  content: "\e7ff";
}
.icon-person:before {
  content: "\e7fd";
}
.icon-reorder:before {
  content: "\e8fe";
}
.icon-rss_feed:before {
  content: "\e0e5";
}
.icon-search:before {
  content: "\e8b6";
}
.icon-settings:before {
  content: "\e8b8";
}
.icon-dehaze:before {
  content: "\e3c7";
}
.icon-drag_handle:before {
  content: "\e25d";
}
.icon-filter_list:before {
  content: "\e152";
}
.icon-sort:before {
  content: "\e164";
}
.icon-subject:before {
  content: "\e8d2";
}
.icon-subdirectory_arrow_left:before {
  content: "\e5d9";
}
.icon-skip_next:before {
  content: "\e044";
}
.icon-step-forward:before {
  content: "\e044";
}
.icon-skip_previous:before {
  content: "\e045";
}
.icon-step-backward:before {
  content: "\e045";
}
.icon-dot:before {
  content: "\e061";
}
.icon-play-triangle-right:before {
  content: "\e909";
}
.icon-triangle-right:before {
  content: "\e907";
}
.icon-circle-plus:before {
  content: "\e93b";
}
.icon-volume_off:before {
  content: "\e90a";
}
.icon-volume-off:before {
  content: "\e90a";
}
.icon-instagram:before {
  content: "\e908";
}
.icon-download:before {
  content: "\e90d";
}
.icon-pencil:before {
  content: "\e90f";
}
.icon-alert-bell:before {
  content: "\e91f";
}
.icon-epub:before {
  content: "\e920";
}
.icon-copyright:before {
  content: "\e925";
}
.icon-id-badge:before {
  content: "\e917";
}
.icon-doublearrow:before {
  content: "\e919";
}
