.link_resolver_form {
    .baseUrl {
        input {
            border-radius: toRem(4);
        }
    }

    .jcf {
        &-file {
            width: auto;
            display: flex;
            align-items: center;
            color: $dark--5;
            @include media('<tablet') {
                flex-wrap: wrap;
            }

            &:hover {
                .jcf-upload-button {
                    background-color: rgba($link-color, 0.9);
                }
            }
        }

        &-upload-button {
            padding: 0.5rem 1.5rem;
            border-radius: toRem(4);
            background-color: $link-color;
            position: static;
            @include media('<tablet') {
                padding: 0.75rem 3.125rem;
            }
        }

        &-fake-input {
            display: block;
            width: auto;
            margin-right: 1rem;
            padding-right: 1rem;
            @include media('<tablet') {
                width: 100%;
                border-right: none;
                margin-bottom: 0.75rem;
            }
        }

        &-button-content {
            padding: 0;
        }
    }

    .uploadImageButton {
        border-bottom: toRem(1) solid $dim;
    }

    .btn[type='submit'] {
        margin: 0.875rem 0;
    }
}
