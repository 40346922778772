.articleTitlePrice {
    display: flex;

    .item-price {
        color: $dark--5;
    }

    .boldText {
        flex: 1;
        max-width: 100%;
    }
}

.totalPrice {
    .boldText {
        margin-left: 0.5rem;
    }
}

.buyItemFooter {
    .intialPrice {
        color: $dark;
    }
}

.promoOffers {
    margin-bottom: 1.125rem;
}
