.icon {
    &--square {
        background-color: $dark--5;
        color: $theme-text-lighter;
        border-radius: toRem(2);
        padding: 0.063rem;

        &.icon-facebook {
            padding: toRem(1) 0 toRem(1) toRem(3);
        }
    }
}
