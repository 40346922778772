.raa {
    .flex {
        display: flex;
        flex-wrap: wrap;
    }
}

.registration-form {
    .actions {
        .button {
            display: inline-block;
        }
        a {
            &.cancel {
                @include font-size(12);
            }
        }
    }

    .password-eye-icon {
        bottom: toRem(7);
        top: auto;
    }

    .g-recaptcha {
        margin-top: 1.125rem;
    }

    label {
        color: $theme-text-dark;
    }
}

.login {
    &-form {
        &-container {
            min-height: 100%;

            .tab__pane {
                padding-top: 0;
            }
        }

        .input-group {
            margin-bottom: 1.5rem;
        }

        .reset-password {
            margin-top: 1.5rem;
        }

        .remember {
            @include font-size(12);
            margin-bottom: 1.125rem;
        }

        .submit-section {
            flex-direction: column;
            align-items: flex-start;

            .required-info {
                padding: 1rem 0 0;
                color: $theme-text-dark;
            }
        }

        .submit.button {
            border-radius: $border-radius;
        }

        .actions {
            .link {
                color: $link-color;
                text-decoration: underline;
            }
        }
    }

    &-tabs-footer {
        .panel__body & {
            border-top: toRem(1) solid $dim;
            background-color: $dark--3;
            padding: 1.435rem 3rem;
            margin: 1.5rem -3rem -2.125rem;
            @include media('<tablet') {
                padding: 1rem 1.5rem 0;
                margin: 1rem -1rem 0;
            }

            .actions {
                display: flex;
                justify-content: space-between;
                align-items: center;

                span {
                    margin-right: 0.875rem;
                    font-weight: 600;
                    @include font-size(16);

                    @include media('<tablet') {
                        @include font-size(14);
                    }
                }
            }

            .styled-link {
                padding: 0.625rem 3rem;
                color: $btn-inverse-color;
                border: toRem(1) solid $btn-inverse-color;
                border-radius: $border-radius;
                text-decoration: none;

                &:hover {
                    background-color: $btn-inverse-hover-bg;
                    color: $btn-color;
                    border: toRem(1) solid $dim;
                }

                @include media('<tablet') {
                    padding: 0.625rem 1rem;
                }
            }
        }
    }
}

.input-group {
    .pass-strength-popup {
        ul {
            display: block;
            margin-top: toRem(15);
        }
    }
}

.checkbox--primary {
    span {
        &:before {
            border-color: $dark--5;
        }
    }
}

.institutions {
    font-size: 0.75em;
    &__header,
    .page__header {
        @include font-size(12);
        color: $theme-text-dark;
    }

    ul {
        margin: 1rem 0;
    }

    .tab {
        &__nav {
            a {
                border: 0;
            }
        }

        &__pane {
            a {
                @include font-size(12);
                color: $theme-text-dark;
            }
        }

        .dropBlock {
            .shibboleth & > a {
                border-radius: $border-radius;
            }

            i {
                @include font-size(24);
                color: $primary;
            }

            & > a {
                @include font-size(12);
                &:active,
                &:focus {
                    background-color: $dark--3;
                }
                color: $dark--5;
            }
        }
    }

    .dropBlock {
        i {
            position: static;
            margin: 0;
        }

        li {
            margin-bottom: 1.875rem;

            &:last-child {
                margin-bottom: 0;
            }
        }

        a {
            padding: 0;
            @include font-size(12);
            color: $theme-text-dark;
            font-weight: 500;
        }

        & > a {
            padding: 0.5rem 0.875rem;
            justify-content: space-between;
        }

        &__link {
            &.js--open {
                box-shadow: 0 0 toRem(2) toRem(1) $dim;
            }
        }
    }

    .dropblock {
        &--tab {
            box-shadow: none;
            border: toRem(1) solid $dim;
            border-top: 0;
            margin: 0;
            border-radius: 0 0 $border-radius $border-radius;

            &.js--open {
                box-shadow: 0 0 toRem(2) toRem(1) $dim;
                padding: 1.375rem 1rem;
            }
        }
    }
}

.shibboleth {
    .federation {
        display: block;
    }
    .tab__content {
        a {
            text-decoration: underline;
        }
    }
}

.g-recaptcha {
    margin: 1rem 0;
}

.login-popup {
    .submit-section {
        flex-flow: row;
        padding-bottom: 1rem;
    }
}

.login-form,
.request-reset-password-drawer,
.change-password-drawer {
    .submit.button {
        border-radius: toRem(4);
    }
    .cancel {
        display: inline-block;
    }
}
