body {
    //overflow: auto;
    background: $body-bg;

    &:after {
        content: '';
        opacity: 0;
        transition: opacity 0.5s, height 0s;
        transition-delay: 0s, 0.5s;
        height: 0;
        background: $darker;
        top: 0;
        left: 0;
        position: fixed;
        width: 100vw;
    }

    &.lock-screen {
        &.iOS {
            @include media('<tablet') {
                max-height: 100%;
                max-width: 100%;
                width: 100%;
                position: fixed;
            }
        }

        &:after {
            height: 100vh;
            opacity: 0.7;
            transition-delay: 0s, 0s;
            z-index: map-get($z-index, 'lock-screen');
        }
    }
}

.container {
    //@include media('>xlarge') {
    //    max-width: toRem(1240);
    //    width: 100%;
    //}
    @include media('>jumbo') {
        max-width: toRem(1360);
    }

    &--full {
        width: auto;
    }

    &--fullback {
        // full color background
        width: auto;
    }

    &--fluid-md {
        @include media('<desktop') {
            width: auto;
            max-width: $container-md;
        }
    }

    &--md {
        width: auto;
        max-width: $container-md;
    }
}

.read-less,
.read-more {
    font-weight: bold;
    color: $dark--5;
    font-size: 0.625rem;
    text-decoration: underline;

    li > & {
        margin-left: 1rem;
        margin-right: 1rem;
    }

    &:focus {
        outline: rgba($supplementary, 0.5) solid toRem(2);
    }
}

.read-less {
    margin-left: toRem(4);
}

.content {
    &--light {
        background: $dark--3;
    }

    &--dark {
        background: $dark--4;
    }

    &--lighter {
        background: $lighter;

        + .content--lighter {
            border-top: toRem(1) solid $border-color;
        }
    }

    // commenting again, not sure what was the reason
    //@include media('<desktop') { // not the best idea
    //    max-width: 100%;
    //    overflow: hidden;
    //}
}

main {
    &.content {
        padding-bottom: 3rem;
    }

    &.article-page,
    &.journal-home,
    &.landing-page {
        padding-bottom: 0;
    }

    min-height: 60vh;
}

.col {
    &--idf {
        @include media('>desktop') {
            padding-left: $grid-gutter-width * 0.5 + 25; // the 25 is given by graphic prototypes there is no coherence with the grid any general guidlines
            padding-right: $grid-gutter-width * 0.5 + 25;
        }
    }

    &--idf-m {
        @include media('>desktop') {
            padding-left: 1.5rem; // another designs little whim
            padding-right: 1.5rem;
        }
    }

    &--ads {
        @include media('<desktop') {
            display: flex;
            flex-flow: row wrap;
            align-items: flex-start;
            justify-content: space-between;
        }

        @include media('<tablet') {
            justify-content: center;
        }
    }
}

.title {
    &--slim {
        font-weight: normal;
    }

    strong {
        font-weight: 600;
    }
}

h2 {
    &.title--responsive {
        @include media('<tablet') {
            font-size: 1.313rem;
        }
    }
}

.sidebar-ad {
    img {
        display: block;
        margin: auto;
    }
}

.lighter {
    background: $lighter;
}

.journal-home {
    background: $lighter;
    padding-bottom: 0;
}

h4 {
    font-size: 1.25rem;
    color: inherit;
    font-weight: 600;
}

.section {
    &--shaded {
        padding: 2rem 0;
        background: $body-bg;
    }
}

%card-shadow {
    box-shadow: 0 toRem(2) toRem(4) rgba($darker, 0.1);
    padding: 1.5rem;
    background-color: $card-bg;
}

.table {
    .expandable & {
        &__control__button i {
            @include media('<tablet') {
                font-size: 2em;
            }
        }
    }
}

.table-responsive {
    tr.expanded td {
        &.action {
            @include media('<tablet') {
                padding-top: 1em;
                padding-bottom: 1em;
            }
        }
    }
}

.tab {
    $tab: &;

    .swipe-with-arrows & {
        .tab__nav {
            &:after,
            &:before {
                content: none;
            }

            display: flex;
            align-items: center;

            li {
                flex: 0 auto;
            }
        }

        &--res {
            .tab__nav {
                justify-content: space-between;

                .arrows-slide {
                    border: none;
                }
            }
        }
    }
}

h6 {
    color: $dark--5;
}

/* FACETS */

.fj {
    // FACETS JOURNALS. Cant use just 'facets' since it's used for the sidebar filters on UX3 level
    &__title {
        color: inherit;
        padding-top: 1em;

        &:first-letter {
            padding-bottom: 0.238em;
            border-bottom: toRem(4) solid $facets;
        }

        font-size: 2.875rem;
        text-align: center;
        font-weight: normal;
        margin-bottom: 1em;

        @include media('<tablet') {
            font-size: 1.75rem;

            &:first-letter {
                padding-bottom: 0;
            }
        }
    }
}

.owl-dot {
    &,
    .owl-carousel .owl-dots & {
        background: transparent;
        padding: 0;
        margin: 0;

        span {
            border-radius: 51%;
            background: $lighter;
            padding: 0;
            margin: toRem(8);
            width: toRem(8);
            height: toRem(8);
            transition: all 0.3s ease;

            //background: transparent;
            //min-height: 100%;
            //min-width: 100%;
            //display: block;
            //margin: 0;
        }

        &.active span {
            background: transparent;
            border: toRem(1) solid $lighter;
            width: toRem(12);
            height: toRem(12);
            margin: toRem(6) toRem(5);

            //@include media ('<tablet') {
            //    margin: toRem(5) toRem(1);
            //}
        }
    }
}

.slideshow-red-btn {
    .owl-carousel .owl-dot {
        span {
            background: $slideshow-dots-color;
        }
        &.active span {
            background: transparent;
            border: toRem(1) solid $slideshow-dots-color;
        }
    }
}

.lightbox-trigger {
    position: relative;
    cursor: pointer;
    overflow: hidden;
    border: toRem(1) solid rgba($darker, 0);
    transition: 0.2s ease border;

    $this: &;

    &:hover {
        border: toRem(1) solid $darker;

        &:before {
            background-color: $dark--2;
        }

        #{$this}__icon {
            color: $lighter;
        }
    }

    &__icon {
        position: absolute;
        font-size: 1.5rem;
        z-index: map-get($z-index, 'lightbox-trigger-icon');
        color: $dark--2;
        right: 0.5rem;
        bottom: 0.5rem;
    }

    &:before {
        content: '';
        position: absolute;
        right: 0;
        bottom: 0;
        transform: translate(50%, 50%);
        background-color: rgba($dark--4, 0.9);
        transition: 0.2s ease background-color;
        height: 6rem;
        width: 6rem;
        border-radius: 51%;
        z-index: map-get($z-index, 'lightbox-trigger-before');
    }
}

.csp {
    &__blaze {
        height: 1.45em;
    }

    &__text {
        padding-top: 0.58em;
        margin-left: 0.1em;
        line-height: 1.03;
        letter-spacing: 0.001em;
    }
}

.in-lightbox {
    &:last-of-type {
        background-color: rgba($darker, 0.65);
    }
}

.loa {
    color: $dark--5;
    line-height: 1.7;
    white-space: pre-wrap;

    a:hover {
        text-decoration: underline;
    }

    .read-less {
        margin-left: 0.4em;
    }
}

[data-db-target-for] i:last-child {
    margin-left: 0.5em;
}

.js--indicate-inner-scroll {
    &:after,
    &:before {
        top: 0;
        width: 0;
        bottom: 0;
        z-index: map-get($z-index, 'indicate-inner-scroll');
        opacity: 0;
        content: '';
        position: absolute;
        pointer-events: none;
        transition: 0.2s linear width, 0.2s linear opacity;
    }

    &:after {
        background: linear-gradient(268.96deg, $light-gray 1.65%, rgba(229, 229, 229, 0) 98.8%);
        right: 0;
    }

    &:before {
        background: linear-gradient(268.96deg, rgba(229, 229, 229, 0) 1.65%, $light-gray 98.8%);
        left: 0;
    }

    &.js--has-scroll-right {
        &:after {
            width: 20%;
            opacity: 1;
        }
    }

    &.js--has-scroll-left {
        &:before {
            width: 20%;
            opacity: 1;
        }
    }
}

.brand-font {
    font-family: 'Roboto', sans-serif;
    line-height: 1.03;
    letter-spacing: 0.005em;

    strong {
        line-height: 0;
    }
}

.custom-content {
    display: flex; // only needed for the sidebar nav menu
    font-size: 0.875rem;

    > div {
        max-width: 100%;
    }

    a {
        text-decoration: underline;
        color: $supplementary;

        &:not(.accordion) {
            &:hover {
                color: $secondary;
            }

            &:focus {
                outline: toRem(2) solid rgba($supplementary, 0.5);
            }
        }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin: 0.85em 0;
        color: inherit;
    }

    h6 {
        margin: 1em 0;
    }

    li {
        margin: 0.5em 0;
    }

    table {
        th {
            font-weight: bold;
        }

        th,
        tr:nth-child(odd) {
            background: $dark--4;
        }

        th,
        td {
            border: toRem(1) solid $border-color;
        }
    }

    .lightbox-trigger {
        max-width: 45%;
        margin: 1em auto;
        display: block;

        @include media('<phone') {
            max-width: 100%;
        }

        img {
            width: 100%;
            max-width: toRem(480);
            max-height: toRem(480);
            display: block;
        }
    }

    .dropBlock {
        width: 100%;
        max-width: toRem(376);
        position: relative;

        .btn {
            width: 100%;
        }

        li a {
            padding: 0;
            display: block;
        }

        &__holder {
            &.rlist {
                max-height: toRem(500);
                overflow-y: auto;
            }
        }
    }

    header {
        border-bottom: toRem(1) solid $border-color;
        padding-bottom: 1.5rem;
        margin-bottom: 1.5rem;
    }

    & .sections-navbar a {
        text-decoration: inherit;
        color: inherit;

        &.active {
            color: $supplementary;
        }
    }

    &__guidelines-nav {
        max-width: 0;
    }

    .accordion-item {
        border-top: toRem(1) solid $dark--9;

        &__content {
            font-size: 0.917rem;
            padding-bottom: 1rem;
            padding-left: 0.5rem;
            transition: line-height 0.2s ease-out, color 0.2s ease-out;
        }

        &__content-inner {
            padding: 0.5rem 0 0.5rem 1rem;
            color: $text--secondary;
            border-left: toRem(1) solid $dark--9;
        }

        &__trigger {
            align-items: center;
            display: flex;
            font-size: 1.125rem;
            font-weight: 700;
            margin: 0.85em 0;
            text-decoration: none;

            span {
                text-decoration: underline;
            }

            &.js--target-shown {
                [class*='icon-'] {
                    transform: rotate(180deg);
                }
            }

            [class*='icon-'] {
                font-size: 1rem;
                margin-left: toRem(8);
            }
        }
    }
}

.maxWid100 {
    max-width: 100%;
}

.in-lightbox .in-lightbox-content {
    background: rgba(255, 255, 255, 0.15);
}

.journal-banner {
    padding: 1rem 3.5rem 1rem 1rem;
    color: $lighter;
    position: relative;

    &__title {
        font-size: 1.125rem;
        z-index: map-get($z-index, 'journal-banner');
        position: relative;
    }

    &__subtitle {
        font-size: 0.75rem;
        opacity: 0.75;
        z-index: map-get($z-index, 'journal-banner-subtitle');
        position: relative;
    }

    &__icon {
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        z-index: map-get($z-index, 'journal-banner-icon');
    }
}

.share,
.dashboard__media {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
    align-items: center;
    color: $darker;
    margin-top: 1.125rem;

    li {
        display: block;
    }

    .separator {
        width: 0;
        border-right: toRem(1) solid $border-color;
        align-self: stretch;
    }

    &--center {
        justify-content: center;

        li {
            margin: 0 0.25em;
        }
    }

    &__label {
        @include media('<desktop') {
            min-width: 100%;
            margin: 1em 0;
            text-align: center;
        }
    }

    a {
        border: toRem(1) solid rgba($darker, 0.15);
        border-radius: 50%;
        font-size: 1.125rem;
        display: flex;
        align-items: center;
        justify-content: center;

        @include media('<desktop') {
            font-size: 1.325rem;
        }

        &:hover,
        &:focus {
            background: rgba($darker, 0.1);
        }

        padding: 0.445em;
        box-shadow: 0 0 toRem(1) 0 rgba($darker, 0.15) inset, 0 0 toRem(1) 0 rgba($darker, 0.15);
    }

    i {
        font-size: 0.9em;
    }

    .icon-alert-bell {
        transform: scale(1.2);
    }
}

.tfa-form-container a {
    text-decoration: underline;
    color: $supplementary;
}

.icon-editor-choice {
    color: $supplementary;
}

.meta-panel__editor-award,
.editor-choice {
    color: $dark--5;

    i {
        vertical-align: middle;
        font-size: 1.4em;
        margin-right: 0.35em;
    }
}

.icon-favorite-added .path1,
.icon-favorite-added .path2 {
    margin: 0 !important;
    padding: 0 !important;
}

.link {
    &--blue {
        color: $supplementary;

        &:hover {
            color: $supplementary;
            text-decoration: underline;
        }
    }

    &--red {
        color: $secondary;

        &:hover {
            color: $secondary;
            text-decoration: underline;
        }
    }
}

.divider {
    &--l {
        border-left: toRem(1) solid $border-color;
    }

    &--r {
        border-right: toRem(1) solid $border-color;
    }

    &--b {
        border-bottom: toRem(1) solid $border-color;
    }

    &--t {
        border-top: toRem(1) solid $border-color;
    }
}

.collapsible {
    background: $lighter;

    &__header {
        display: flex;
        justify-content: space-between;
        font-weight: bold;
        text-transform: uppercase;
        color: $secondary;
        align-items: center;
        border-bottom: toRem(3) solid $secondary;

        .icon-arrow_down {
            color: $dark--2;
            transition: transform 0.1s ease;
        }

        &.js--target-shown {
            .icon-arrow_down {
                transform: scaleY(-1);
            }
        }
    }

    &__body {
        padding: 1rem;
    }
}

.tag {
    background: $secondary;
    padding: 0.5em;
    border-radius: toRem(4);
    text-transform: uppercase;
    display: inline-block;
    font-weight: bold;
    line-height: 1.14;

    &--light {
        background: $dark--4;
    }

    &--sm {
        font-size: 0.7em;
    }
}

.frame {
    border: toRem(1) solid;
    background: $lighter;

    img:only-child {
        display: block;
        margin: 0 auto;
    }

    &--light {
        border-color: $dark--4;
    }

    &--o {
        border-radius: toRem(4);
    }
}

.rlist {
    &--flex {
        display: flex;
        flex-flow: row wrap;

        li {
            min-width: 0;
        }
    }
}

.row--flex {
    display: flex;
    flex-flow: row wrap;
}

[data-style='small-caps'] {
    font-variant: small-caps;
}

.pb-rich-text {
    a {
        text-decoration: underline;
        color: $supplementary;

        &:hover {
            color: $secondary;
        }

        &:focus {
            outline: toRem(2) solid rgba($supplementary, 0.5);
        }
    }

    table {
        max-width: 100%;
    }

    h3 {
        margin-top: 0;
        margin-bottom: 0.938rem;
        font-size: 1.25rem;
        color: inherit;
        font-weight: 600;
    }
}

iframe {
    max-width: 100%;
}
