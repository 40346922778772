.saved-searches {
    .icon-search {
        margin-right: 0.75rem;
    }

    tr {
        td:not(.hidden):first-child,
        [data-thead] {
            &:before {
                font-weight: 600;
                @include font-size(14);
                line-height: 1.188rem;
                color: $darker;
                margin-bottom: toRem(7);
                width: 100%;
            }
        }
    }
}
