.journal-list {
    &__panel {
        padding: 2.188rem 0;
        text-align: center;
        font-size: 1.125rem;
        .btn {
            margin-left: 1rem;
            background: $btn-bg;
            min-width: 16em;
            @include media('<desktop') {
                margin: 1.5rem 0 0;
            }
            &:focus,
            &:active {
                color: inherit;
            }

            &.js--target-shown {
                span {
                    display: none;
                }
                span.hidden {
                    display: inline;
                }
            }
        }
        .container {
            @include media('<tablet') {
                display: flex;
                text-align: left;
                flex-flow: column;
                justify-content: flex-start;
            }
        }
    }
    &__items {
        display: none;
        position: relative;
        color: $lighter;
        background: $journals-list-background;

        &:after {
            content: '';
            position: absolute;
            left: 50%;
            top: 0;
            width: 3rem;
            height: 3rem;
            background: $lighter;

            transform: translate(-50%, -50%) rotate(45deg) skew(2deg, 2deg);
        }
    }

    &__title {
        @include media('<tablet') {
            font-size: 1.25rem;
            margin-bottom: 0.25em;
        }
    }
    ul {
        @include media('>tablet') {
            column-count: 2;
            column-gap: 10%;
        }
        padding: 1.875rem 0;
    }
    li {
        padding: 0.75rem 0;
        display: flex;
        align-items: flex-start;
    }

    &__icon {
        margin-right: 0.75rem;
    }
    a {
        &:hover {
            text-decoration: underline;
        }
    }
}

.expansion-indicator {
    position: relative;
    width: 1.167em;
    height: 1.167em;
    margin-right: 0.5rem;

    &__y,
    &__x {
        position: absolute;
        background: $lighter;
        top: 50%;
        left: 0;
        right: 0;
        height: toRem(2);
        transition: 0.2s ease transform;
    }
    &__y {
        transform: translateY(-50%) rotate(90deg);
        .js--target-shown & {
            transform: translateY(-50%) rotate(45deg) scaleX(1.2);
        }
    }
    &__x {
        transform: translateY(-50%) rotate(0deg);
        .js--target-shown & {
            transform: translateY(-50%) rotate(135deg) scaleX(1.2);
        }
    }
}
