.breadcrumbs {
    font-size: 0.75rem;
    color: $dark--5;
    padding: 1rem 0 0.5rem 1.5rem;

    @include media('<desktop') {
        display: none;
    }

    a {
        display: inline-block;
        vertical-align: top;
        &:focus {
            text-decoration: none;
        }
        &:hover,
        &:focus {
            span {
                text-decoration: underline;
            }
        }
        & + a {
            &:before {
                margin: 0 0.5em;
                content: ' / ';
            }
        }
    }
    &__current {
        font-weight: bold;
        color: $theme-text-dark;
        max-width: 20em;
        overflow: hidden;
        white-space: nowrap;
        display: inline-block;
        pointer-events: none;
        text-overflow: ellipsis;
    }

    main &:first-child {
        margin-top: -2rem;
        position: relative;
        z-index: map-get($z-index, 'breadcrumb-main');
    }

    main.article-page &:first-child {
        margin-top: -3rem;
        margin-bottom: 1.625rem;
        position: relative;
        z-index: map-get($z-index, 'main-article');
    }
}
