.signup-banner {
    font-size: 0.75rem;
    background: $dark--2;
    color: $lighter;

    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 1.5rem;
    margin: 2rem 0;
    &--csp {
        .btn {
            background: $lighter;
            color: $darker;
            &:hover,
            &:focus {
                background: $btn-color;
            }
        }
    }
    &__title {
        font-size: 1.375rem;
        line-height: 1;
        margin-bottom: 0.25rem;
    }
    .btn {
        padding: 0.5rem 3.375rem;
        margin-left: 0.5rem;
        @include media('<tablet') {
            margin: 1rem 0 0;
            flex: 1 0 100%;
        }
    }
    &--sidebar {
        padding: 1.5rem;
        margin-top: 0;
        .btn {
            margin: 1rem 0 0;
            flex: 1;
        }
    }
    strong {
        font-weight: 600;
    }
    &--facets {
        background: $lighter;
        border: toRem(1) solid $border-color;
        color: $darker;
    }
    .collection-header & {
        margin-bottom: 1rem;
    }
}
